import React, { useEffect, useRef, useState } from "react";
import HeaderData from "../Header/HeaderData";
import DashboardFormWrapper from "../Wrapper/DashboardFormWrapper";
import InputWrapper from "../Wrapper/InputWrapper";
import InputSmall from "../Input/InputSmall";
import { FiCamera } from "react-icons/fi";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { updateClientProfile } from "../../Schema";
import ButtonLoading from "../Buttons/ButtonLoading";
import {
  getDashboardData,
  updateUserSettings,
  uploadPhoto,
} from "../../Redux/Actions";
import defaultProfile from "../../Assets/default-profile.png";
import { useParams } from "react-router-dom";
import Loader from "../Loading/Loader";
import ChangeUserPasswordModal from "../Modal/ChangeUserPasswordModal";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from "react-modal";
import cropProfileImage from "../../Utils/cropProfileImage";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 200
const ClientInfo = () => {
  let Once = true;
  const params = useParams();
  const imgRef = useRef(null);
  const PrevCanvasRef = useRef(null);
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { clients, isNotInvoked } = useSelector((state) => state.dashboard);
  const [passwordModal, setPasswordModal] = useState(false);
  const dispatch = useDispatch();
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null); // Cropped image result
  const [isModalOpen, setIsModalOpen] = useState(false); // Control modal
  const [error, setError] = useState('');
  const [crop, setCrop] = useState({});


  // console.log('clients=--', clients)

  const [initialState, setInitialState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    photo: "",
    clientInfo: {
      coachId: "",
      dob: "",
      occupation: "",
      phone: "",
      address: "",
    },
    role: "Client",
  });

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: initialState,
    validationSchema: updateClientProfile,
    onSubmit: (values) => {
      // if (profile) {
      //   setBtnLoading(true);
      //   uploadPhoto({ photo: profile })
      if (output) {
        setLoading(true);
        const fileExtension = output.split(';')[0].split('/')[1]; // Extract file extension from base64 data
        const imageFile = dataURLtoFile(output, `profile.${fileExtension}`); // Use the correct file extension
        uploadPhoto({ photo: imageFile })
          .then((res) => {
            values.photo = res.data.photos[0];
            setTimeout(() => {
              dispatch(updateUserSettings(values, params?.userId)).then(() => {
                setBtnLoading(false);
              });
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setBtnLoading(false);
          });
      } else {
        setBtnLoading(true);
        dispatch(updateUserSettings(values, params?.userId)).then(() => {
          setBtnLoading(false);
        });
      }
    },
  });

  function handleProfileChange(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setProfile(selectedFile);
    }
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract the file type from the base64 URL
    const bstr = atob(arr[1]);
    let n = bstr?.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime }); // Use extracted mime type
  };

  function handleProfileChange(e) {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
      imageElement.addEventListener('load', (e) => {
        if (error) setError('');
        const { naturalWidth, naturalHeight } = e.currentTarget;
        // if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
        //   setError('Image is too small, please upload a larger image');
        //   setSrc('');
        //   return;
        // }
      })
      setSrc(imageUrl);
      setIsModalOpen(true);
    });
    reader.readAsDataURL(selectedFile);

  }

  const onImageLoaded = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop)
  }

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  // Format the max date to be compatible with HTML date input format (YYYY-MM-DD)
  const maxDateString = maxDate.toISOString().split("T")[0];

  useEffect(() => {
    if (clients?.length) {
      setLoading(true);
      let filteredUser = clients.filter((user) => user._id === params?.userId);
      const userData = filteredUser[0];
      setValues({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        photo: userData?.photo,
        clientInfo: {
          coachId: userData?.clientInfo?.coachId,
          dob: userData?.clientInfo?.dob || "",
          occupation: userData?.clientInfo?.occupation || "",
          phone: userData?.clientInfo?.phone || "",
          address: userData?.clientInfo?.address || "",
        },
        role: "Client",
      });
      setLoading(false);
    }
  }, [clients]);

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isNotInvoked) {
        setLoading(true);
        dispatch(getDashboardData());
      }
    }
  }, []);

  return (
    <>
      <HeaderData text="Customer Profile" customClassess={"!my-3"} />
      {loading ? (
        <Loader />
      ) : (
        <DashboardFormWrapper customClasses="bg-white">
          <div className="flex justify-center items-center  p-3">
            <label htmlFor="profile" className="cursor-pointer relative py-3">
              <p className="block mb-1 pl-[2px] font-medium text-[14px] capitalize text-center">
                Profile Picture
              </p>
              <div className=" w-32 h-32 rounded-full border">
                {/* {profile ? (
                  <img
                    className="w-full h-full"
                    src={URL.createObjectURL(profile)}
                    alt="profile "
                  />
                ) : (
                  <img
                    className="w-full h-full"
                    src={
                      values?.photo
                        ? process.env.REACT_APP_IMAGE_URL + values?.photo
                        : defaultProfile
                    }
                    alt="profile "
                  />
                )} */}
                {output ? (
                  <img
                    className="w-full h-full object-cover"
                    src={output}
                    alt="profile"
                  />
                ) : (
                  <img
                    className="w-full h-full object-cover"
                    src={
                      values?.photo?.startsWith("https://") ? values?.photo : values?.photo !== ""
                        ? process.env.REACT_APP_IMAGE_URL.concat(values?.photo)
                        : defaultProfile
                    }
                    alt="profile"
                  />
                )}
              </div>
              <input
                onChange={handleProfileChange}
                type="file"
                id="profile"
                hidden
                accept="image/*"
              />
              <div className="w-14 h-14 text-2xl flex justify-center items-center rounded-full absolute top-[68%] right-[0%] bg-[#2F80ED] text-white">
                <FiCamera />
              </div>
            </label>
          </div>
          <InputWrapper customClasses="!items-stretch">
            <InputSmall
              id="firstName"
              name="firstName"
              type="text"
              label="First Name"
              placeholder="John"
              value={values.firstName}
              error={errors.firstName}
              touch={touched.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputSmall
              id="lastName"
              name="lastName"
              type="text"
              label="Last Name"
              placeholder="Doe"
              value={values.lastName}
              error={errors.lastName}
              touch={touched.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <div className={`flex-grow basis-52`}>
              <label
                htmlFor="dob"
                className="block mb-1 pl-[2px] text-[16px] capitalize text-[#333333]"
              >
                Date of birth
              </label>
              <input
                type="date"
                name="clientInfo.dob"
                id="dob"
                max={maxDateString}
                value={values.clientInfo.dob}
                className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-md focus:border-gray-800 block w-full px-2.5 py-2 "
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.clientInfo &&
                touched.clientInfo.dob &&
                errors.clientInfo &&
                errors.clientInfo.dob ? (
                <p className="text-[14px] text-red-600">
                  {errors.clientInfo.dob}
                </p>
              ) : null}
            </div>
            <InputSmall
              id="occupation"
              name="clientInfo.occupation"
              type="text"
              label="Occupation"
              placeholder="i-e Engineer"
              value={values.clientInfo.occupation}
              error={errors.clientInfo && errors.clientInfo.occupation}
              touch={touched.clientInfo && touched.clientInfo.occupation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <InputSmall
              id="phone"
              name="clientInfo.phone"
              type="text"
              label="Phone"
              value={values.clientInfo.phone}
              error={errors.clientInfo && errors.clientInfo.phone}
              touch={touched.clientInfo && touched.clientInfo.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputSmall
              id="address"
              name="clientInfo.address"
              type="text"
              label="Address"
              placeholder="Enter address"
              value={values.clientInfo.address}
              error={errors.clientInfo && errors.clientInfo.address}
              touch={touched.clientInfo && touched.clientInfo.address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>

          <InputWrapper>
            <InputSmall
              id="email"
              name="email"
              type="email"
              label="Email"
              // placeholder="johndoe@gmail.com"
              value={values?.email}
              error={errors.email}
              touch={touched.email}
              onChange={handleChange}
              onBlur={handleBlur}
              disable={false}
            />
          </InputWrapper>

          <div className="mt-4 w-full pt-4 pb-6 flex justify-center items-center gap-3">
            {btnLoading ? (
              <div className="w-1/2 mx-auto">
                <ButtonLoading />
              </div>
            ) : (
              <>
                <ButtonSmall text="Update" onClick={handleSubmit} />
                <ButtonSmall
                  text="Update Password"
                  onClick={() => {
                    setPasswordModal(true);
                  }}
                />
              </>
            )}
          </div>
        </DashboardFormWrapper>
      )}
      {passwordModal && (
        <ChangeUserPasswordModal
          open={passwordModal}
          handleModal={() => {
            setPasswordModal(false);
          }}
          userId={params?.userId}
        />
      )}

      {/* Crop Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className={`w-[80%] sm:w-[70%] md:w-[50%] mx-auto rounded-md mt-20 bg-white shadow-lg flex flex-col justify-center items-center py-5`}
      >
        <h2 className="text-lg font-bold">Crop Image</h2>
        <div className="py-3">
          <button
            className="mr-4 bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => {
              cropProfileImage(
                imgRef.current,
                PrevCanvasRef.current,
                convertToPixelCrop(
                  crop,
                  imgRef.current.width,
                  imgRef.current.height
                )
              );
              const dataUrl = PrevCanvasRef.current.toDataURL();
              setOutput(dataUrl);
              setIsModalOpen(false)
            }}
          >
            Crop and Save
          </button>
          <button
            className="bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
        </div>
        {
          error && <p className="text-red-600">{error}</p>
        }
        {src && (
          <ReactCrop
            src={src}
            onImageLoaded={setImage}
            crop={crop}
            onChange={
              (pixelCrop, percentCrop) => setCrop(percentCrop)
            }
            // circularCrop={true}
            keepSelection={true}
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={src}
              alt="imageUpload"
              className=""
              onLoad={onImageLoaded}
            />
          </ReactCrop>
        )}

        {crop && (
          <canvas
            ref={PrevCanvasRef}
            className="mt-4"
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 150,
              height: 150,
            }}
          />
        )}

      </Modal >

    </>
  );
};

export default ClientInfo;
