import React from "react";

const MediaContainer = ({ text, children }) => {
  return (
    <div className="w-full">
      <h6 className="satoshi-700 pb-[4px] pt-4">{text}</h6>
      {children}
    </div>
  );
};

export default MediaContainer;
