import React, { useEffect, useRef, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import DashboardFormWrapper from "../../components/Wrapper/DashboardFormWrapper";
import InputWrapper from "../../components/Wrapper/InputWrapper";
import InputSmall from "../../components/Input/InputSmall";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { FiCamera } from "react-icons/fi";
import {
  getDashboardData,
  updateSetting,
  updateUserSettings,
  uploadPhoto,
} from "../../Redux/Actions";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import { useFormik } from "formik";
import { updateStaff } from "../../Schema";
import InputRequired from "../../components/Input/InputRequired";
// import { compareObject } from "../../Utils/CompareObject";
// import { successToast } from "../../Utils/Toast";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loading/Loader";
import ChangeUserPasswordModal from "../Modal/ChangeUserPasswordModal";
import ReactCrop, { centerCrop, convertToPercentCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from "react-modal";
import defaultProfile from "../../Assets/default-profile.png";
import cropProfileImage from "../../Utils/cropProfileImage";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 200
const StaffInfo = () => {
  let Once = true;
  const imgRef = useRef(null);
  const PrevCanvasRef = useRef(null);
  const params = useParams();
  const [loading, setLoading] = useState();
  const [btnloading, setButtonLoading] = useState(false);
  const [profile, setProfile] = useState("");
  const { staff, isNotInvoked } = useSelector((state) => state.dashboard);
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    photo: "",
    role: "Staff",
    staffInfo: {
      coachId: "",
      phone: "",
    },
  };
  const [passwordModal, setPasswordModal] = useState(false);
  const dispatch = useDispatch();
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null); // Cropped image result
  const [isModalOpen, setIsModalOpen] = useState(false); // Control modal
  const [error, setError] = useState('');
  const [crop, setCrop] = useState({});

  function handleImage(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setProfile(selectedFile);
    }
  }

  const {
    values,
    setValues,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialState,
    validationSchema: updateStaff,
    onSubmit: (values) => {
      if (output) {
        setButtonLoading(true);
        const fileExtension = output.split(';')[0].split('/')[1]; // Extract file extension from base64 data
        const imageFile = dataURLtoFile(output, `profile.${fileExtension}`); // Use the correct file extension

        // if (profile) {
        // setButtonLoading(true);
        // uploadPhoto({ photo: profile })
        uploadPhoto({ photo: imageFile })
          .then((res) => {
            values.photo = res?.data?.photos[0];
            dispatch(updateUserSettings(values, params?.userId)).then(() => {
              setButtonLoading(false);
            });
          })
          .catch((err) => {
            console.error(err);
            setButtonLoading(false);
          });
      } else {
        setButtonLoading(true);
        dispatch(updateUserSettings(values, params?.userId)).then(() => {
          setButtonLoading(false);
        });
        // if (compareObject(values, staff)) {
        //   successToast("Profile updated successfully");
        // } else {

        // }
      }
    },
  });

  useEffect(() => {
    if (staff?.length) {
      setLoading(true);
      let filteredUser = staff.filter((user) => user._id === params?.userId);
      const userData = filteredUser[0];
      const { firstName, lastName, email, photo, staffInfo } = userData;
      setValues({
        firstName: firstName,
        lastName: lastName,
        email: email,
        photo: photo || "",
        role: "Staff",
        staffInfo: {
          coachId: staffInfo?.coachId,
          phone: staffInfo?.phone,
        },
      });
      setLoading(false);
    }
  }, [staff]);

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isNotInvoked) {
        setLoading(true);
        dispatch(getDashboardData());
      }
    }
  }, []);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract the file type from the base64 URL
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime }); // Use extracted mime type
  };

  function handleProfileChange(e) {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
      imageElement.addEventListener('load', (e) => {
        if (error) setError('');
        const { naturalWidth, naturalHeight } = e.currentTarget;
        // if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
        //   setError('Image is too small, please upload a larger image');
        //   setSrc('');
        //   return;
        // }
      })
      setSrc(imageUrl);
      setIsModalOpen(true);
    });
    reader.readAsDataURL(selectedFile);

  }

  const onImageLoaded = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop)
  }


  return (
    <>
      <HeaderData text="Staff Profile" customClassess="!my-2" />
      <div className="w-full">
        {loading ? (
          <Loader />
        ) : (
          <DashboardFormWrapper customClasses="!bg-white !mt-2">
            <div className="flex justify-center items-center  p-3">
              <label htmlFor="profile" className="cursor-pointer relative py-3">
                <p className="block mb-1 pl-[2px] font-medium text-[14px] capitalize text-center">
                  Profile Picture
                </p>
                <div className="w-28 h-28 rounded-full border">
                  {/* {profile ? (
                    <img
                      className="w-full h-full"
                      src={URL.createObjectURL(profile)}
                      alt="profile "
                    />
                  ) : (
                    <img
                      className="w-full h-full"
                      src={process.env.REACT_APP_IMAGE_URL + values?.photo}
                      alt="profile "
                      loading="lazy"
                    />
                  )} */}
                  {output ? (
                    <img
                      className="w-full h-full object-cover"
                      src={output}
                      alt="profile"
                    />
                  ) : (
                    <img
                      className="w-full h-full object-cover"
                      src={
                        values?.photo?.startsWith("https://") ? values?.photo  
                          : values?.photo !== "" ? process.env.REACT_APP_IMAGE_URL.concat(values?.photo)
                          : defaultProfile
                      }
                      alt="profile"
                    />
                  )}
                </div>
                <input
                  onChange={handleProfileChange}
                  type="file"
                  id="profile"
                  hidden
                  accept="image/*"
                />
                <div className="w-12 h-12 text-2xl flex justify-center items-center rounded-full absolute top-[70%] right-[0%] bg-[#2F80ED] text-white">
                  <FiCamera />
                </div>
              </label>
            </div>
            <InputWrapper customClasses={"!items-stretch"}>
              <InputRequired
                id="firstName"
                name="firstName"
                type="text"
                label="First Name"
                placeholder="John"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName}
                touch={touched.firstName}
              />
              <InputRequired
                id="lastName"
                name="lastName"
                type="text"
                label="Last Name"
                placeholder="Doe"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName}
                touch={touched.lastName}
              />
            </InputWrapper>
            <InputWrapper>
              <InputRequired
                id="phone"
                name="staffInfo.phone"
                type="text"
                label="Phone"
                value={values.staffInfo.phone}
                error={errors.staffInfo && errors.staffInfo.phone}
                touch={touched.staffInfo && touched.staffInfo.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputWrapper>
            <InputWrapper>
              <InputSmall
                id="email"
                name="email"
                value={values?.email}
                type="email"
                label="Email"
                placeholder="johndoe@gmail.com"
                error={errors.email}
                touch={touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disable={false}
              />
            </InputWrapper>
            <div className="mt-4 w-full pt-4 pb-6 flex justify-center items-center gap-3">
              {btnloading ? (
                <ButtonLoading />
              ) : (
                <>
                  <ButtonSmall text="Update" onClick={handleSubmit} />
                  <ButtonSmall
                    text="Update Password"
                    onClick={() => {
                      setPasswordModal(true);
                    }}
                  />
                </>
              )}
            </div>
          </DashboardFormWrapper>
        )}
      </div>
      {passwordModal && (
        <ChangeUserPasswordModal
          open={passwordModal}
          handleModal={() => {
            setPasswordModal(false);
          }}
          userId={params?.userId}
        />
      )}

      {/* Crop Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className={`w-[80%] sm:w-[70%] md:w-[50%] mx-auto rounded-md mt-20 bg-white shadow-lg flex flex-col justify-center items-center py-5`}
      >
        <h2 className="text-lg font-bold">Crop Image</h2>
        <div className="py-3">
          <button
            className="mr-4 bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => {
              cropProfileImage(
                imgRef.current,
                PrevCanvasRef.current,
                convertToPixelCrop(
                  crop,
                  imgRef.current.width,
                  imgRef.current.height
                )
              );
              const dataUrl = PrevCanvasRef.current.toDataURL();
              setOutput(dataUrl);
              setIsModalOpen(false)
            }}
          >
            Crop and Save
          </button>
          <button
            className="bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
        </div>
        {
          error && <p className="text-red-600">{error}</p>
        }
        {src && (
          <ReactCrop
            src={src}
            onImageLoaded={setImage}
            crop={crop}
            onChange={
              (pixelCrop, percentCrop) => setCrop(percentCrop)
            }
            // circularCrop={true}
            keepSelection={true}
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={src}
              alt="imageUpload"
              className=""
              onLoad={onImageLoaded}
            />
          </ReactCrop>
        )}

        {crop && (
          <canvas
            ref={PrevCanvasRef}
            className="mt-4"
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 150,
              height: 150,
            }}
          />
        )}

      </Modal >
    </>
  );
};

export default StaffInfo;
