import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { transactionHistory } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import PaymentTableRow from "../../components/Table/PaymentTableRow";
import UnavailableService from "../../components/Message/UnavailableService";

let Once = true;
const Payment = () => {
  const dispatch = useDispatch();
  const [secondaryLoading, setSecondaryLoading] = useState(false);
  const transactions = useSelector((state) => state.transactions.data);
  const [paymentService, setPaymentService] = useState(
    useSelector((state) => state.services.transactionService)
  );

  function getAllTransactions() {
    if (isEmpty(transactions)) {
      setSecondaryLoading(true);
      dispatch(transactionHistory())
        .then((res) => setSecondaryLoading(false))
        .catch((err) => {
          setSecondaryLoading(false);
          if (err.message === "Something went wrong") {
            setPaymentService(true);
          } else {
            setPaymentService(false);
          }
        });
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      getAllTransactions();
    }
  });
  return (
    <>
      <HeaderData text="Payments" />
      {secondaryLoading ? (
        <Loader />
      ) : (
        <>
          {paymentService ? (
            <>
              {transactions?.length ? (
                <div className="mt-5 rounded-md mr-2">
                  <div className="w-full bg-white px-3 py-3">
                    <h5 className="text-[21px] satoshi-700">
                      Client Transactions History
                    </h5>
                  </div>
                  <div className="w-full overflow-x-auto">
                    <table className="w-full min-w-[770px] border-collapse !overflow-x-auto bg-white rounded-md">
                      <thead className="border-t">
                        <tr>
                          <th className="text-left pl-4 pr-2 py-2 text-[--gray] text-sm">
                            Name
                          </th>
                          <th className="text-left px-2 py-2 text-[--gray] text-sm">
                            Type
                          </th>
                          <th className="text-left px-2 py-2 text-[--gray] text-sm">
                            Amount
                          </th>
                          <th className="text-left px-2 py-2 text-[--gray] text-sm">
                            Date
                          </th>
                          <th className="text-left px-2 py-2 text-[--gray] text-sm ">
                            Package
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions?.map((transaction, index) => {
                          return (
                            <PaymentTableRow key={index} data={transaction} />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <UnavailableService serviceName="Transaction/Payment" />
          )}
        </>
      )}
    </>
  );
};

export default Payment;
