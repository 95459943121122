import React, { useState } from 'react';
import Form from '../../components/Form/Form';
import InputRequired from '../../components/Input/InputRequired';
import ButtonLoading from '../../components/Buttons/ButtonLoading';
import ButtonLarge from '../../components/Buttons/ButtonLarge';
import { otpSchema } from '../../Schema';
import { useFormik } from 'formik';
import { emailVerification, verifyOtp } from '../../Redux/Actions';
import { errorToast } from '../../Utils/Toast';
import { useDispatch } from 'react-redux';

const OtpVeri = ({ stepsValues, onNext }) => {
    const [loading, setLoading] = useState(false);
    // const [resendOTP, setResendOTP] = useState(false);
    // const [showResend, setShowResend] = useState(false);
    const dispatch = useDispatch();

    // const handleResendOTP = () => {
    //     setLoading(true);
    //     emailVerification({ email: stepsValues.email }).then((res) => {
    //         setStepValues({
    //             email: stepsValues.email,
    //             userId: res.userid,
    //             code: res.code
    //         })
    //         setLoading(false);
    //         setShowResend(false);

    //         //     setExpired(false);
    //         setResendOTP(false);
    //         //     setSeconds(OTPduration); 
    //         //     values.inputCode="" 
    //     }).catch((err) => {
    //         console.error(err);
    //         setLoading(false);
    //     })

    // };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: { inputCode: "" },
        validationSchema: otpSchema,
        onSubmit: (formValues) => {
            setLoading(true);
            verifyOtp(stepsValues.data.data._id, {
                inputCode: formValues.inputCode,
                code: stepsValues.data.code,
            })
                .then((res) => {
                    // console.log('res--', res);
                    localStorage.setItem('coachId', res.data.id);
                    localStorage.setItem('ca_access', JSON.stringify(res?.token));
                    localStorage.setItem('ca_refresh', JSON.stringify(res?.refreshToken));
                    dispatch({
                        type: 'LOGIN',
                        payload: res
                    })
                    setLoading(false);
                    onNext();
                })
                .catch((err) => {
                    // console.error("Error in OTP verification:", err);
                    // setShowResend(true);
                    setLoading(false);
                    errorToast('Invalid OTP.');
                });
        },
    });

    // console.log('OtpVeri--', stepsValues)


    return (
        <Form headerText="OTP Verification" description="We have successfully sent an OTP to your email address." onSubmit={handleSubmit}>
            <InputRequired
                label="OTP"
                type="number"
                id="otp"
                name="inputCode"
                value={values.inputCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.inputCode}
                touch={touched.inputCode}
                placeholder="Please enter OTP here"
            />
            {loading ? (
                <ButtonLoading />
            ) : (
                <>
                    <ButtonLarge onClick={handleSubmit} text="Verify OTP" />
                    {/* {showResend ? <ButtonLarge onClick={handleResendOTP} type="button" text="Resend OTP" /> : null} */}
                </>
            )}
        </Form>
    );
};

export default OtpVeri;
