import React, { useState } from "react";
import HeaderData from "../Header/HeaderData";
import moment from "moment";
import { useSelector } from "react-redux";
import TableHeader from "../Table/TableHeader";
import parse,{ domToReact } from 'html-react-parser';
import { convert } from 'html-to-text';
import he from 'he'; // for decoding HTML entities



const SelfTask = () => {

  const { data } = useSelector((state) => state.staffDetail);
  const [taskFilter, setFilter] = useState("");
  const filteredTasks = taskFilter
    ? data?.tasks?.filter((task) => task.curentStatus === taskFilter)
    : data?.tasks;

    const customParseOptions = {
      replace: (domNode) => {
        if (domNode.name === 'a') {
          return (
            <a
              href={domNode.attribs.href}
              target={domNode.attribs.target || "_self"}
              rel={domNode.attribs.rel || ""}
              style={{ color: 'blue', textDecoration: 'underline' }} // Custom link styles
            >
              {domToReact(domNode.children)} {/* Render children of <a> */}
            </a>
          );
        }
      }
    };

  return (
    <>
      <HeaderData text="Staff Tasks" customClassess="!my-2">
        <select
          name="status"
          id="status"
          value={taskFilter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          className="border outline-none py-1 pr-1 pl-2 text-[14px] cursor-pointer"
        >
          <option value="">All</option>
          <option value="ToDo">ToDo</option>
          <option value="InProgress">In Progress</option>
          <option value="Completed">Complete</option>
        </select>
      </HeaderData>
      {data?.tasks.length ? (
        <>
          <div className="bg-white rounded-md mt-3">
            <div className="px-3 w-full">
              <TableHeader text="Task Assigned" />
            </div>
          </div>
          <div className="dashboardTableWrapper bg-white">
            <table className="bg-white w-full min-w-[700px] border-collapse !overflow-x-auto rounded-md ">
              <thead>
                <tr>
                  <th className="px-3 py-2 text-[--gray] satoshi-500 text-left text-sm ">
                    Description
                  </th>
                  <th className="pr-2 pl-8 py-2 text-[--gray] satoshi-500 text-sm text-left">
                    Start Date
                  </th>
                  <th className="px-2 py-2 text-[--gray] satoshi-500 text-sm text-left">
                    End Date
                  </th>
                  {/* <th className="px-2 py-2 text-[--gray] satoshi-500 text-sm text-left">
                    Detail
                  </th> */}
                  <th className="px-2 py-2 text-[--gray] satoshi-500 text-left">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTasks?.map((item, index) => {
                  return (
                    <tr key={index} className="border-t">
                         <td
                        onClick={(e) => {
                          e.target.classList.toggle("whitespace-nowrap");
                        }}
                        className=" px-3 py-2  satoshi-500 text-sm pr-8 max-w-[180px] whitespace-nowrap text-ellipsis "
                      >
                      
                        {/* Decode HTML entities, then parse and replace <a> tag styles */}
                        {parse(he.decode(item?.taskDetails?.description || ""), customParseOptions)}
                      </td>
                      <td className=" pr-2 pl-8 py-2 satoshi-500 text-sm">
                        {moment(parseInt(item.taskDetails.createdAt)).format(
                          "YYYY-MM-DD"
                        )}
                      </td>
                      <td className=" px-2 py-2 satoshi-500 text-sm ">
                        {moment(item.endDate).format("YYYY-MM-DD")}
                      </td>
                      {/* <td className=" px-2 pl-4 py-2 satoshi-500 text-[18px] text-blue-400 cursor-pointer">
                        View Detail
                      </td> */}
                      {/* <td className=" px-2 pl-4 py-2 satoshi-500 text-[18px] ">
                              {item.isLate ? (
                                <RxCross2 className="text-red-600 text-center" />
                              ) : (
                                <FaCheck className="text-green-600 text-center" />
                              )}
                            </td> */}
                      {/* <td className="px-2 py-2 text-sm ">
                <div className=" w-max h-full rounded-full border border-[--orange] bg-[--gray-light] text-[--orange]">
                  <p className="px-2 py-1 satoshi-500">Financial Task</p>
                </div>
              </td> */}
                      <td
                        className={`px-2 py-2 satoshi-500 text-md text-center text-white w-[120px] ${item.curentStatus === "ToDo"
                          ? "bg-orange-800"
                          : item.curentStatus.toLowerCase() === "inprogress"
                            ? "bg-yellow-600"
                            : item.curentStatus.toLowerCase() === "completed"
                              ? "bg-green-700"
                              : "bg-[--primary]"
                          }`}
                      >
                        {item.curentStatus === "ToDo"
                          ? "To Do"
                          : item.curentStatus}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p>This staff don't have any task</p>
      )}
    </>
  );
};

export default SelfTask;
