import { RouterProvider } from "react-router-dom";
import Routes from "./Routes";
import Loader from "./components/Loading/Loader";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "./Redux/Actions";
import { useSocket } from "./context/socketContext";

function App() {
  const [loading, setLoading] = useState(false);
  const coachData = useSelector((state) => state.auth.userData);
  const socket = useSocket();

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(checkLogin())
      .then((res) => {
        if (socket.connected) {
          const data = {
            userId: res.data._id,
            socketId: socket.id,
          };
          socket.emit("create_connection", data);
        }
        setLoading(false);
      })
      .catch((err) => {
        dispatch({ type: "LOGOUT" });
        setLoading(false);
      });
  }, []);
  return (
    <>
      {loading ? (
        <section className="w-screen h-screen flex justify-center items-center">
          <Loader />
        </section>
      ) : (
        <RouterProvider router={Routes} />
      )}
    </>
  );
}

export default App;
