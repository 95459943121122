import React from 'react';
import ButtonSmall from '../Buttons/ButtonSmall';
import Modal from './Modal';

const NoPaymentModal = ({open,handleClose}) => {
    return (
    <Modal open={open} onclick={handleClose} headerText="Alert">
        <div className='w-full px-2 pb-3'>
            <p>We apologize for the inconvenience! At the moment, our payment gateway is undergoing setup and is not available for transactions. 
    Our team is actively working to integrate a secure payment gateway to provide you with a smooth purchasing experience. 
    Please check back soon, as we'll have it up and running in no time. 
    Thank you for your patience and understanding!</p>
        </div>
          <div className="flex justify-center items-center gap-4 mt-6 w-full">
          <ButtonSmall
                  text="Close"
                  onClick={handleClose}
                  customClasses="!bg-red-600 !border-red-600"
                />
          </div>

    </Modal>
        
    );
}

export default NoPaymentModal;
