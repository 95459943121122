import React from "react";
import { MdModeEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FiEye } from "react-icons/fi";
import parse from "html-react-parser";

const TaskCard = ({
  title,
  description,
  onEdit,
  onDelete,
  onAssign,
  onViewDetail,
}) => {
  return (
    <div className="w-[300px] rounded-md bg-white px-2 py-4">
      <div className="flex justify-end items-center text-[20px] gap-3 border-b pb-2">
        <MdModeEdit
          onClick={onEdit}
          className="text-green-700 cursor-pointer transition-all ease-in-out duration-300 hover:text-black"
        />
        <MdDeleteForever
          onClick={onDelete}
          className="text-red-700 cursor-pointer transition-all ease-in-out duration-300 hover:text-black"
        />
        <IoMdAddCircleOutline
          onClick={onAssign}
          className="text-[--primary] cursor-pointer transition-all ease-in-out duration-300 hover:text-black"
        />
        <FiEye
          onClick={onViewDetail}
          className="text-blue-500 cursor-pointer transition-all ease-in-out duration-300 hover:text-black"
        />
      </div>
      <h1 className="text-[18px] satoshi-700 mt-1 capitalize">{title}</h1>
      <div className="mt-3">
        <h6 className="satoshi-500">Description</h6>
        <p className="text-[--gray] text-[14px] description">
          {parse(parse(description))}
        </p>
      </div>
    </div>
  );
};

export default TaskCard;
