import React, { useState } from "react";
import HeaderData from "../Header/HeaderData";
import { useSelector } from "react-redux";
import StepsModal from "../Modal/StepsModal";
import AssignGoal from "../Modal/AssignGoal";
import Avatar from "../Avatar/Avatar";
import moment from "moment";
import { MdDeleteForever } from "react-icons/md";
import DeleteGoal from "../Modal/DeleteGoal";
import UpdateAssignGoal from "../Modal/UpdateAssignGoal";
import ReopenGoalModal from "../Modal/ReopenGoalModal";

const Goals = () => {
  const { data } = useSelector((state) => state.customerDetail);
  const [stepModal, setStepModal] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [modalData, setModalData] = useState({
    goalId: "",
    title: "",
    steps: [],
    note: "",
    staff: [],
  });

  const [updateCoachGoal, setUpdateCoachGoal] = useState({
    modal: false,
    data: null,
  });
  const [goalDelete, setGoalDelete] = useState({
    status: false,
    id: null,
  });
  const [reopenGoal, setReopenModal] = useState({
    status: false,
    data: null,
  });

  function handleModal(data, modalName = "steps") {
    setModalData({
      goalId: data._id,
      title: data?.name,
      steps: data?.steps,
      note: data?.note || "",
      staff: data?.staff || [],
    });
    if (modalName === "staff") {
      setStaffModal(true);
    } else {
      setStepModal(true);
    }
  }

  function handleCloseModal() {
    window.location.reload();
  }

  function handleUpdateCoachModal(goalData = null, status = false) {
    setUpdateCoachGoal({
      modal: status,
      data: goalData,
    });
    if (status === false) {
      window.location.reload();
    }
  }

  function handleDeleteGoal(status = false, goalId = null) {
    setGoalDelete({
      status: status,
      id: goalId,
    });
    if (status === false) {
      window.location.reload();
    }
  }

  function handleReopenGoal(goalData) {
    setReopenModal({ status: !reopenGoal.status, data: goalData });
  }

  return (
    <>
      {reopenGoal.status && (
        <ReopenGoalModal
          open={reopenGoal.status}
          handleClose={() => {
            window.location.reload();
          }}
          data={reopenGoal.data}
        />
      )}
      {goalDelete.status && (
        <DeleteGoal
          open={goalDelete.status}
          data={goalDelete}
          handleModal={() => {
            window.location.reload();
          }}
        />
      )}

      {updateCoachGoal.modal && (
        <UpdateAssignGoal
          open={updateCoachGoal.modal}
          data={updateCoachGoal.data}
          handleClose={() => {
            window.location.reload();
          }}
        />
      )}
      {stepModal ? (
        <StepsModal
          open={stepModal}
          handleClose={() => {
            window.location.reload();
          }}
          data={modalData}
        />
      ) : null}
      {staffModal ? (
        <AssignGoal
          open={staffModal}
          handleClose={() => {
            window.location.reload();
          }}
          data={modalData}
        />
      ) : null}
      <HeaderData text="Customer Goals" customClassess={"!my-3"} />
      {data?.goal?.length ? (
        <div className="dashboardTableWrapper">
          <table className="bg-white w-full min-w-[1100px] border-collapse !overflow-x-auto mt-3">
            <thead>
              <tr className="border-b border-black">
                <th className="text-left px-2 pl-4 py-4 text-[--gray] text-sm ">
                  Name
                </th>
                <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm ">
                  Title
                </th>
                <th className="text-left px-2 py-4 text-[--gray] text-sm ">
                  Amount
                </th>
                <th className="text-left px-2 py-4 text-[--gray] text-sm ">
                  Completed
                </th>
                <th className="text-left px-2 py-4 text-[--gray] text-sm w-[250px]">
                  Duration
                </th>
                <th className="text-right px-2 py-4 text-[--gray] text-sm  w-[200px]"></th>
              </tr>
            </thead>
            <tbody>
              {data?.goal
                ?.sort((a, b) => parseInt(b.createdAt) - parseInt(a.createdAt))
                .map((goal, index) => {
                  return (
                    <tr key={index} className="border-b">
                      <td className="flex justify-start items-center py-2 pl-4 w-max">
                        <Avatar imageRef={goal?.clientId?.photo} />
                        <div className=" ml-2 flex justify-center items-start flex-col">
                          <h5 className="text-[14px] xl:text-[16px]">
                            {goal?.clientId?.firstName +
                              " " +
                              goal?.clientId?.lastName}
                          </h5>
                        </div>
                      </td>

                      <td
                        onClick={(e) => {
                          e.target.classList.toggle("whitespace-nowrap");
                        }}
                        className="px-3 py-2 text-sm pr-8 max-w-[180px] whitespace-nowrap text-ellipsis "
                      >
                        {goal?.name}
                      </td>
                      <td className="text-[14px] text-left px-2 ">
                        {goal?.goalType === "Financial"
                          ? `$ ${goal?.amount}`
                          : "Non-Financial"}
                      </td>
                      <td className="text-[14px] xl:text-base px-2 text-center  text-lime-600">
                        {goal?.completed ? (
                          goal.goalType === "Financial" ? (
                            <span className="text-sm xl:text-base satoshi-500 bg-green-50 text-green-600 px-2 py-1">
                              Completed
                            </span>
                          ) : (
                            <button
                              onClick={() => {
                                handleReopenGoal(goal);
                              }}
                              className="text-sm xl:text-base satoshi-500 rounded-md bg-red-800 text-white px-2 py-1 cursor-pointer"
                            >
                              Re-Open
                            </button>
                          )
                        ) : (
                          <span className="text-sm xl:text-base satoshi-500 bg-red-50 text-red-500 px-2 py-1">
                            Incomplete
                          </span>
                        )}
                      </td>
                      <td className="text-sm xl:text-base">
                        {moment(parseInt(goal?.startDate)).format(
                          "DD MMM, YYYY"
                        ) +
                          " - " +
                          moment(parseInt(goal?.endDate)).format(
                            "DD MMM, YYYY"
                          )}
                      </td>
                      <td className="text-center px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] pl-2">
                        {goal?.createdBy === "Coach" ||
                          goal?.createdBy === "Staff" ? (
                          <div className="flex justify-center items-stretch gap-3">
                            <button
                              onClick={() => {
                                handleUpdateCoachModal(goal, true);
                              }}
                              className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500"
                            >
                              Update Goal
                            </button>

                            <div
                              onClick={() => {
                                handleDeleteGoal(true, goal._id);
                              }}
                              className="cursor-pointer border border-red-700 bg-red-700 text-white rounded-md px-2 py-1 transition-all ease-in-out duration-500 hover:text-red-700 hover:bg-white"
                            >
                              <MdDeleteForever className="text-base md:text-lg lg:text-xl xl:text-2xl" />
                            </div>
                          </div>
                        ) : !goal?.createdByCoach && goal?.staff?.length ? (
                          <div className="flex justify-center items-stretch gap-1">
                            {goal.goalType === "Nonfinancial" && (
                              <button
                                onClick={() => {
                                  handleModal(goal);
                                }}
                                className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500 min-w-max"
                              >
                                Update Steps
                              </button>
                            )}

                            <button
                              onClick={() => {
                                handleModal(goal, "staff");
                              }}
                              className="ml-3 bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500 min-w-max"
                            >
                              Update Staff
                            </button>
                          </div>
                        ) : !goal?.createdByCoach && goal?.steps?.length ? (
                          <div className="flex justify-center items-stretch gap-1">
                            {goal.goalType === "Nonfinancial" && (
                              <button
                                onClick={() => {
                                  handleModal(goal);
                                }}
                                className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500 min-w-max"
                              >
                                Update Steps
                              </button>
                            )}

                            <button
                              onClick={() => {
                                handleModal(goal, "staff");
                              }}
                              className="ml-3 bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500 min-w-max"
                            >
                              Update Staff
                            </button>
                          </div>
                        ) : (
                          <>
                            {goal.goalType === "Nonfinancial" && (
                              <button
                                onClick={() => {
                                  handleModal(goal);
                                }}
                                className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500"
                              >
                                Add Steps
                              </button>
                            )}

                            <button
                              onClick={() => {
                                handleModal(goal, "staff");
                              }}
                              className="ml-3 bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500"
                            >
                              Assign Staff
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>This customer don't have any goal yet</p>
      )}
    </>
  );
};

export default Goals;
