const initialState = {
    mediaService:true,
    programService:true,
    packageService:true,
    transactionService:true,
    reportService:true,
    handoutService:true,
    taskService:true,
    staffService:true,
    clientService:true
}


export const servicesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "UPDATE_SERVICE":
        return {
          ...state,
          [payload.name]: payload.value,
        };
      default:
        return state;
    }
  };