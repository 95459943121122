import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
// import RowWrapper from "../../components/Wrapper/RowWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import moment from "moment";
// import AssignProgramModal from "../../components/Modal/AssignProgramModal";
// import ButtonSmall from "../../components/Buttons/ButtonSmall";
// import DeleteProgramModal from "../../components/Modal/DeleteProgramModal";
import MediaDetailCard from "../../components/Card/MediaDetailCard";

const MediaVideoDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const videosData = useSelector((state) => state.media.videos);
  // const [assignProgram, setAssignProgram] = useState({
  //   modal: false,
  //   programId: null,
  // });

  // const [deleteProgram, setDeleteProgram] = useState({
  //   modal: false,
  //   programId: null,
  // });

  // function closeAssignModal() {
  //   setAssignProgram({
  //     modal: false,
  //     programId: null,
  //   });
  // }

  // function closeDeleteModal() {
  //   setDeleteProgram({
  //     modal: false,
  //     programId: null,
  //   });
  // }

  useEffect(() => {
    if (!videosData.length) {
      navigate("/media");
    } else {
      setLoading(true);
      const targetProgram = videosData.find((item) => item._id === id);
      setSelectedMedia(targetProgram);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);

  return (
    <>
      <HeaderData text="Media Detail" />
      {loading ? (
        <Loader />
      ) : selectedMedia ? (
        <MediaDetailCard
          video={selectedMedia?.video}
          title={selectedMedia?.title}
          description={selectedMedia?.description}
          creationDate={selectedMedia?.createdAt}
        />
      ) : (
        <p>The media you are seraching for is not found</p>
      )}

      {/* {deleteProgram.modal ? (
        <DeleteProgramModal
          open={selectedMedia.modal}
          handleClose={closeDeleteModal}
          data={deleteProgram.programId}
        />
      ) : null} */}
    </>
  );
};

export default MediaVideoDetail;
