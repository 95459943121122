import React, { useEffect, useRef, useState } from "react";
import HeaderData from "../Header/HeaderData";
import CardWrapper from "../Wrapper/CardWrapper";
import { useDispatch, useSelector } from "react-redux";
import SmartPlanCard from "../Card/SmartPlanCard";
import RowWrapper from "../Wrapper/RowWrapper";
import ButtonIcon from "../Buttons/ButtonIcon";
import { FaDownload } from "react-icons/fa";
import { IoEye } from "react-icons/io5";
import ButtonSmall from "../Buttons/ButtonSmall";

import {
  allowAccessToClient,
  viewOrDownloadClientReport,
} from "../../Redux/Actions";
import Loader from "../Loading/Loader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import SmartPlanCont from "../SmartPlanContent/SmartPlanCont";

const SmartPlan = () => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [btn, setBtn] = useState(false);
  const { data } = useSelector((state) => state.customerDetail);
  const [smartPlanData, setSmartPlanData] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();
  let reportName = smartPlanData?.logoPageInfo?.clientName + " " + smartPlanData?.logoPageInfo?.currentDate;
  // console.log("smartPlanData", smartPlanData)

  function handleAllowAccess() {
    setLoading(true);
    const body = {
      // clientId: data.smartPlan[0].userId,
      clientId: params.userId,
      coachId: null,
      // smartPlanId: data.smartPlan[0]._id,
      smartPlanId: data.smartPlan[0]._id,
    };
    dispatch(allowAccessToClient(body)).then(() => {
      setLoading(false);
    });
  }

  const generatePdf = (data, option) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = data;

    // Append the container to the body (it will be removed after PDF generation)
    document.body.appendChild(tempContainer);
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    html2canvas(tempContainer, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.75);
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
        }

        if (option === "view") {
          const blob = pdf.output("blob");
          const url = URL.createObjectURL(blob);
          const newWindow = window.open(url, "_blank");
          if (newWindow) {
            newWindow.focus();
          } else {
            alert("Please allow popups for this website");
          }
        } else {
          pdf.save("financialReport.pdf");
        }
      })
      .catch((error) => {
        console.error("Error generating PDF", error);
      })
      .finally(() => {
        document.body.removeChild(tempContainer);
      });
  };

  async function handleDownloadOrView(option) {
    // setLoading(true);
    const url = `${data?.smartPlan[0]?.userId}/${data?.smartPlan[0]?._id}/${option}`;
    try {
      const response = await viewOrDownloadClientReport(url);
      setSmartPlanData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setBtn(false);
    }
  }

  const handleBeforePrint = async () => {
    setBtn(true);
    // if (!smartPlanData) {
    await handleDownloadOrView('download');
    // }
    return Promise.resolve();
  };

  useEffect(() => {
    handleDownloadOrView('download');
  }, [])

  return loading ? (
    <Loader />
  ) : (
    <>
      <div>
        <HeaderData text="Customer Smart Plan" customClassess={"!my-3"}>
          {data?.smartPlan.length && (
            <ReactToPrint
              trigger={() => (
                <button
                  className={`px-2 sm:px-4 md:px-7 py-3 text-[12px] sm:text-[14px] md:text-[16px] flex justify-center items-center border rounded-lg gap-2 md:gap-3 text-white bg-[--primary]`}
                >
                  {btn ? "Generating PDF..." : <span className="flex gap-4"><FaDownload /> Download PDF</span>}
                </button>
              )}
              onBeforeGetContent={handleBeforePrint}  // Fetch data if necessary before printing
              onAfterPrint={() => setBtn(false)}
              content={() => componentRef.current}
              documentTitle={`${reportName.replace(/ /g, "_")}`}
            />
          )}
        </HeaderData>

        {/* Hidden component for generating the PDF */}
        <div style={{ display: "none" }}>
          <SmartPlanCont ref={componentRef} data={smartPlanData} />
        </div>
      </div>
      {data?.smartPlan.length ? (
        <CardWrapper extraClasses="!py-2">
          {!data?.smartPlan[0].clientHave && (
            <div className="w-full flex justify-end items-center gap-4 mt-3">
              <ButtonSmall
                text="Allow Access"
                onClick={handleAllowAccess}
                customClasses="!bg-green-800 !border-green-800 hover:!bg-white hover:!text-green-700"
              />
            </div>
          )}

          {data?.smartPlan?.map((selectedSmartPlan, index) => {
            return (
              <CardWrapper key={index} extraClasses={"!items-stretch"}>
                {selectedSmartPlan?.income && (
                  <SmartPlanCard headerText={"Personal Income"}>
                    <RowWrapper
                      title="Company Name "
                      value={selectedSmartPlan?.income?.companyName}
                    />
                    <RowWrapper
                      title="Income Amount"
                      value={`$ ${selectedSmartPlan?.income?.income}`}
                    />
                    {selectedSmartPlan?.income?.additionalIncome && (
                      <>
                        <h4 className="satoshi-700 my-2">Additional Income</h4>
                        <RowWrapper
                          title="Income Type "
                          value={
                            selectedSmartPlan?.income?.additionalIncome?.type
                          }
                        />
                        <RowWrapper
                          title="Business Type "
                          value={
                            selectedSmartPlan?.income?.additionalIncome
                              ?.typeOfBusiness
                          }
                        />

                        <RowWrapper
                          title="Industry "
                          value={
                            selectedSmartPlan?.income?.additionalIncome
                              ?.industry
                          }
                        />
                        <RowWrapper
                          title="Monthly Income "
                          value={`$ ${selectedSmartPlan?.income?.additionalIncome?.monthlyIncome}`}
                        />
                      </>
                    )}
                  </SmartPlanCard>
                )}
                {selectedSmartPlan?.financeShared && (
                  <SmartPlanCard headerText={"Spouse Information"}>
                    <RowWrapper
                      title="Gender "
                      value={selectedSmartPlan?.spouse?.gender}
                    />
                    <RowWrapper
                      title="Retirement Age"
                      value={`${selectedSmartPlan?.spouse?.retirementAge} years`}
                    />

                    <RowWrapper
                      title="Birth Month "
                      value={selectedSmartPlan?.spouse?.dob?.month}
                    />
                    <RowWrapper
                      title="Birth Year "
                      value={selectedSmartPlan?.spouse?.dob?.year}
                    />
                  </SmartPlanCard>
                )}

                {selectedSmartPlan?.spouseIncome && (
                  <SmartPlanCard headerText={"Spouse Income"}>
                    <RowWrapper
                      title="Company Name "
                      value={selectedSmartPlan?.spouseIncome?.companyName}
                    />
                    <RowWrapper
                      title="Income Amount"
                      value={`$ ${selectedSmartPlan?.spouseIncome?.income}`}
                    />
                    {selectedSmartPlan?.spouseIncome?.additionalIncome && (
                      <>
                        <h4 className="satoshi-700 my-2">Additional Income</h4>
                        <RowWrapper
                          title="Income Type "
                          value={
                            selectedSmartPlan?.spouseIncome?.additionalIncome
                              ?.type
                          }
                        />
                        <RowWrapper
                          title="Business Type "
                          value={
                            selectedSmartPlan?.spouseIncome?.additionalIncome
                              ?.typeOfBusiness
                          }
                        />

                        <RowWrapper
                          title="Industry "
                          value={
                            selectedSmartPlan?.spouseIncome?.additionalIncome
                              ?.industry
                          }
                        />
                        <RowWrapper
                          title="Monthly Income "
                          value={`$ ${selectedSmartPlan?.spouseIncome?.additionalIncome?.monthlyIncome}`}
                        />
                      </>
                    )}
                  </SmartPlanCard>
                )}

                {selectedSmartPlan?.dependentsInfp?.doHave ? (
                  <SmartPlanCard headerText={"Dependents Information"}>
                    {selectedSmartPlan?.dependentsInfp?.dependents?.map(
                      (item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className={
                                selectedSmartPlan?.dependentsInfp?.dependents1?.length > 1
                                  ? "w-full pb-1 border-b"
                                  : "w-full"
                              }
                            >
                              <RowWrapper title="Name" value={item?.name} />
                              <RowWrapper
                                title="Age"
                                value={`${item?.age} years`}
                              />
                            </div>
                          </>
                        );
                      }
                    )}
                    {selectedSmartPlan?.dependentsInfp?.custodialAccountInfo?.length ? (
                      <>
                        <h4 className="satoshi-700 my-2">Custodial Account</h4>
                        {selectedSmartPlan?.dependentsInfp?.custodialAccountInfo?.map(
                          (item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className={
                                    selectedSmartPlan?.dependentsInfp
                                      ?.custodialAccountInfo?.length > 1
                                      ? "w-full pb-1 border-b"
                                      : "w-full"
                                  }
                                >
                                  <RowWrapper
                                    title="Institution Name"
                                    value={item?.Institution}
                                  />
                                  <RowWrapper
                                    title="Account Number"
                                    value={`$ ${item?.Amount}`}
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                      </>
                    ) : null}
                  </SmartPlanCard>
                ) : null}

                {selectedSmartPlan?.Home && (
                  <SmartPlanCard headerText={"Home Information"}>
                    <RowWrapper
                      title="Home Type"
                      value={selectedSmartPlan?.Home?.type}
                    />
                    {selectedSmartPlan?.Home?.type === "rent" && (
                      <RowWrapper
                        title="Rent"
                        value={`$ ${selectedSmartPlan?.Home?.rent}`}
                      />
                    )}

                    {selectedSmartPlan?.Home?.mortgageDetails && (
                      <>
                        <RowWrapper
                          title="Home Value"
                          value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.homeValue}`}
                        />

                        <RowWrapper
                          title="Loan Balance"
                          value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.loanBalance}`}
                        />
                        <RowWrapper
                          title="Monthly Payment"
                          value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.monthlyPayment}`}
                        />
                        <RowWrapper
                          title="Interest Rate"
                          value={
                            selectedSmartPlan?.Home?.mortgageDetails
                              ?.interestRate
                          }
                        />
                      </>
                    )}
                  </SmartPlanCard>
                )}

                {selectedSmartPlan?.debts.length ? (
                  <SmartPlanCard headerText={"Debt Information"}>
                    {selectedSmartPlan?.debts.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSmartPlan?.debts.length > 1
                              ? "w-full pb-1 border-b"
                              : "w-full"
                          }
                        >
                          <RowWrapper
                            title="Debt Type"
                            value={item?.debtType}
                          />

                          <RowWrapper
                            title="Institution Name"
                            value={item?.institution}
                          />
                          <RowWrapper
                            title="Debt Balance"
                            value={`$ ${item?.balance}`}
                          />
                          <RowWrapper
                            title="Monthly Payment"
                            value={`$ ${item?.monthlyPayment}`}
                          />
                          <RowWrapper title="Debt Rate" value={item?.rate} />
                        </div>
                      );
                    })}

                    {/* <RowWrapper
                      title="Total Debt"
                      value={`$ ${selectedSmartPlan?.totalDebt}`}
                    /> */}
                    <RowWrapper
                      title="Debt Standing "
                      value={selectedSmartPlan?.debtStanding}
                    />
                  </SmartPlanCard>
                ) : null}

                {selectedSmartPlan?.accounts.length ? (
                  <SmartPlanCard headerText={"Accounts Information"}>
                    {selectedSmartPlan?.accounts.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSmartPlan?.accounts.length > 1
                              ? "w-full pb-1 border-b"
                              : "w-full"
                          }
                        >
                          <RowWrapper title="Account Type" value={item?.type} />

                          <RowWrapper
                            title="Institution Name"
                            value={item?.institution}
                          />
                          <RowWrapper
                            title="Account Balance"
                            value={`$ ${item?.balance}`}
                          />
                        </div>
                      );
                    })}
                  </SmartPlanCard>
                ) : null}

                {selectedSmartPlan?.property.length ? (
                  <SmartPlanCard headerText={"Assets Information"}>
                    {selectedSmartPlan?.property.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSmartPlan?.property.length > 1
                              ? "w-full pb-1 border-b"
                              : "w-full"
                          }
                        >
                          <RowWrapper title="Asset Name" value={item?.name} />

                          <RowWrapper
                            title="Asset Value"
                            value={`$ ${item?.value}`}
                          />
                        </div>
                      );
                    })}
                  </SmartPlanCard>
                ) : null}

                <SmartPlanCard headerText={"Other Information"}>
                  <RowWrapper
                    title="Last Will "
                    value={
                      selectedSmartPlan?.questions?.lastWill ? "Yes" : "No"
                    }
                  />

                  <RowWrapper
                    title="Living Trust "
                    value={
                      selectedSmartPlan?.questions?.livingTrust ? "Yes" : "No"
                    }
                  />

                  <RowWrapper
                    title="Health Care Power Of Attorney "
                    value={
                      selectedSmartPlan?.questions?.healthCarePowerOfAttorney
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Financial Power Of Attorney "
                    value={
                      selectedSmartPlan?.questions?.financialPowerOfAttorney
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Documents Organized "
                    value={
                      selectedSmartPlan?.questions?.documentsOrganized
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Identity Theft Protection "
                    value={
                      selectedSmartPlan?.questions?.identityTheftProtection
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Debt Free "
                    value={
                      selectedSmartPlan?.questions?.onTrackDebtFree
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Budgeting And Debt-Payoff Tool "
                    value={
                      selectedSmartPlan?.questions?.budgetingAndDebtPayoffTool
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Prepared To Manage Finances "
                    value={
                      selectedSmartPlan?.questions?.preparedToManageFinances
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Interested In Education And Services "
                    value={
                      selectedSmartPlan?.questions
                        ?.interestedInEducationAndServices
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Reporting Rent Payments "
                    value={
                      selectedSmartPlan?.questions?.reportingRentPayments
                        ? "Yes"
                        : "No"
                    }
                  />
                </SmartPlanCard>
              </CardWrapper>
            );
          })}
        </CardWrapper>
      ) : (
        <p>You don't have any smart plan</p>
      )}
    </>
  );
};

export default SmartPlan;


// import React, { useState } from "react";
// import HeaderData from "../Header/HeaderData";
// import CardWrapper from "../Wrapper/CardWrapper";
// import { useDispatch, useSelector } from "react-redux";
// import SmartPlanCard from "../Card/SmartPlanCard";
// import RowWrapper from "../Wrapper/RowWrapper";
// import ButtonIcon from "../Buttons/ButtonIcon";
// import { FaDownload } from "react-icons/fa";
// import { IoEye } from "react-icons/io5";
// import ButtonSmall from "../Buttons/ButtonSmall";
// import {
//   allowAccessToClient,
//   viewOrDownloadClientReport,
// } from "../../Redux/Actions";
// import Loader from "../Loading/Loader";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

// const SmartPlan = () => {
//   const [loading, setLoading] = useState(false);
//   const { data } = useSelector((state) => state.customerDetail);
//   const dispatch = useDispatch();

//   function handleAllowAccess() {
//     setLoading(true);
//     const body = {
//       clientId: data.smartPlan[0].userId,
//       coachId: null,
//       smartPlanId: data.smartPlan[0]._id,
//     };
//     dispatch(allowAccessToClient(body)).then(() => {
//       setLoading(false);
//     });
//   }

//   const generatePdf = (data, option) => {
//     const tempContainer = document.createElement("div");
//     tempContainer.innerHTML = data;

//     // Append the container to the body (it will be removed after PDF generation)
//     document.body.appendChild(tempContainer);
//     const pdf = new jsPDF("p", "mm", "a4");
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = pdf.internal.pageSize.getHeight();

//     html2canvas(tempContainer, { scale: 1 })
//       .then((canvas) => {
//         const imgData = canvas.toDataURL("image/jpeg", 0.75);
//         const imgProps = pdf.getImageProperties(imgData);
//         const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

//         let heightLeft = imgHeight;
//         let position = 0;

//         pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
//         heightLeft -= pdfHeight;

//         while (heightLeft >= 0) {
//           position = heightLeft - imgHeight;
//           pdf.addPage();
//           pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
//           heightLeft -= pdfHeight;
//         }

//         if (option === "view") {
//           const blob = pdf.output("blob");
//           const url = URL.createObjectURL(blob);
//           const newWindow = window.open(url, "_blank");
//           if (newWindow) {
//             newWindow.focus();
//           } else {
//             alert("Please allow popups for this website");
//           }
//         } else {
//           pdf.save("financialReport.pdf");
//         }
//       })
//       .catch((error) => {
//         console.error("Error generating PDF", error);
//       })
//       .finally(() => {
//         document.body.removeChild(tempContainer);
//       });
//   };

//   async function handleDownloadOrView(option) {
//     setLoading(true);
//     const url = `${data?.smartPlan[0].userId}/${data.smartPlan[0]._id}/${option}`;
//     console.log('waseem', url);
//     try {
//       const response = await viewOrDownloadClientReport(url);
//       generatePdf(response.data, option);
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   }

//   return loading ? (
//     <Loader />
//   ) : (
//     <>
//       <HeaderData text="Customer Smart Plan" customClassess={"!my-3"}>
//         {data?.smartPlan.length && (
//           <>
//             <ButtonIcon
//               onclick={() => {
//                 handleDownloadOrView("download");
//               }}
//               text="Download"
//               icon={<FaDownload />}
//             />
//             <ButtonIcon
//               onclick={() => {
//                 handleDownloadOrView("view");
//               }}
//               text="View"
//               icon={<IoEye />}
//             />
//           </>
//         )}
//       </HeaderData>
//       {data?.smartPlan.length ? (
//         <CardWrapper extraClasses="!py-2">
//           {!data?.smartPlan[0].clientHave && (
//             <div className="w-full flex justify-end items-center gap-4 mt-3">
//               <ButtonSmall
//                 text="Allow Access"
//                 onClick={handleAllowAccess}
//                 customClasses="!bg-green-800 !border-green-800 hover:!bg-white hover:!text-green-700"
//               />
//             </div>
//           )}

//           {data?.smartPlan?.map((selectedSmartPlan, index) => {
//             return (
//               <CardWrapper key={index} extraClasses={"!items-stretch"}>
//                 {selectedSmartPlan?.income && (
//                   <SmartPlanCard headerText={"Personal Income"}>
//                     <RowWrapper
//                       title="Company Name "
//                       value={selectedSmartPlan?.income?.companyName}
//                     />
//                     <RowWrapper
//                       title="Income Amount"
//                       value={`$ ${selectedSmartPlan?.income?.income}`}
//                     />
//                     {selectedSmartPlan?.income?.additionalIncome && (
//                       <>
//                         <h4 className="satoshi-700 my-2">Additional Income</h4>
//                         <RowWrapper
//                           title="Income Type "
//                           value={
//                             selectedSmartPlan?.income?.additionalIncome?.type
//                           }
//                         />
//                         <RowWrapper
//                           title="Business Type "
//                           value={
//                             selectedSmartPlan?.income?.additionalIncome
//                               ?.typeOfBusiness
//                           }
//                         />

//                         <RowWrapper
//                           title="Industry "
//                           value={
//                             selectedSmartPlan?.income?.additionalIncome
//                               ?.industry
//                           }
//                         />
//                         <RowWrapper
//                           title="Monthly Income "
//                           value={`$ ${selectedSmartPlan?.income?.additionalIncome?.monthlyIncome}`}
//                         />
//                       </>
//                     )}
//                   </SmartPlanCard>
//                 )}
//                 {selectedSmartPlan?.financeShared && (
//                   <SmartPlanCard headerText={"Spouse Information"}>
//                     <RowWrapper
//                       title="Gender "
//                       value={selectedSmartPlan?.spouse?.gender}
//                     />
//                     <RowWrapper
//                       title="Retirement Age"
//                       value={`${selectedSmartPlan?.spouse?.retirementAge} years`}
//                     />

//                     <RowWrapper
//                       title="Birth Month "
//                       value={selectedSmartPlan?.spouse?.dob?.month}
//                     />
//                     <RowWrapper
//                       title="Birth Year "
//                       value={selectedSmartPlan?.spouse?.dob?.year}
//                     />
//                   </SmartPlanCard>
//                 )}

//                 {selectedSmartPlan?.spouseIncome && (
//                   <SmartPlanCard headerText={"Spouse Income"}>
//                     <RowWrapper
//                       title="Company Name "
//                       value={selectedSmartPlan?.spouseIncome?.companyName}
//                     />
//                     <RowWrapper
//                       title="Income Amount"
//                       value={`$ ${selectedSmartPlan?.spouseIncome?.income}`}
//                     />
//                     {selectedSmartPlan?.spouseIncome?.additionalIncome && (
//                       <>
//                         <h4 className="satoshi-700 my-2">Additional Income</h4>
//                         <RowWrapper
//                           title="Income Type "
//                           value={
//                             selectedSmartPlan?.spouseIncome?.additionalIncome
//                               ?.type
//                           }
//                         />
//                         <RowWrapper
//                           title="Business Type "
//                           value={
//                             selectedSmartPlan?.spouseIncome?.additionalIncome
//                               ?.typeOfBusiness
//                           }
//                         />

//                         <RowWrapper
//                           title="Industry "
//                           value={
//                             selectedSmartPlan?.spouseIncome?.additionalIncome
//                               ?.industry
//                           }
//                         />
//                         <RowWrapper
//                           title="Monthly Income "
//                           value={`$ ${selectedSmartPlan?.spouseIncome?.additionalIncome?.monthlyIncome}`}
//                         />
//                       </>
//                     )}
//                   </SmartPlanCard>
//                 )}

//                 {selectedSmartPlan?.dependentsInfp?.doHave ? (
//                   <SmartPlanCard headerText={"Dependents Information"}>
//                     {selectedSmartPlan?.dependentsInfp?.dependents.map(
//                       (item, index) => {
//                         return (
//                           <>
//                             <div
//                               key={index}
//                               className={
//                                 selectedSmartPlan?.dependentsInfp?.dependents
//                                   .length > 1
//                                   ? "w-full pb-1 border-b"
//                                   : "w-full"
//                               }
//                             >
//                               <RowWrapper title="Name" value={item?.name} />
//                               <RowWrapper
//                                 title="Age"
//                                 value={`${item?.age} years`}
//                               />
//                             </div>
//                           </>
//                         );
//                       }
//                     )}
//                     {selectedSmartPlan?.dependentsInfp?.custodialAccountInfo
//                       .length ? (
//                       <>
//                         <h4 className="satoshi-700 my-2">Custodial Account</h4>
//                         {selectedSmartPlan?.dependentsInfp?.custodialAccountInfo.map(
//                           (item, index) => {
//                             return (
//                               <>
//                                 <div
//                                   key={index}
//                                   className={
//                                     selectedSmartPlan?.dependentsInfp
//                                       ?.custodialAccountInfo.length > 1
//                                       ? "w-full pb-1 border-b"
//                                       : "w-full"
//                                   }
//                                 >
//                                   <RowWrapper
//                                     title="Institution Name"
//                                     value={item?.Institution}
//                                   />
//                                   <RowWrapper
//                                     title="Account Number"
//                                     value={`$ ${item?.Amount}`}
//                                   />
//                                 </div>
//                               </>
//                             );
//                           }
//                         )}
//                       </>
//                     ) : null}
//                   </SmartPlanCard>
//                 ) : null}

//                 {selectedSmartPlan?.Home && (
//                   <SmartPlanCard headerText={"Home Information"}>
//                     <RowWrapper
//                       title="Home Type"
//                       value={selectedSmartPlan?.Home?.type}
//                     />
//                     {selectedSmartPlan?.Home?.type === "rent" && (
//                       <RowWrapper
//                         title="Rent"
//                         value={`$ ${selectedSmartPlan?.Home?.rent}`}
//                       />
//                     )}

//                     {selectedSmartPlan?.Home?.mortgageDetails && (
//                       <>
//                         <RowWrapper
//                           title="Home Value"
//                           value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.homeValue}`}
//                         />

//                         <RowWrapper
//                           title="Loan Balance"
//                           value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.loanBalance}`}
//                         />
//                         <RowWrapper
//                           title="Monthly Payment"
//                           value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.monthlyPayment}`}
//                         />
//                         <RowWrapper
//                           title="Interest Rate"
//                           value={
//                             selectedSmartPlan?.Home?.mortgageDetails
//                               ?.interestRate
//                           }
//                         />
//                       </>
//                     )}
//                   </SmartPlanCard>
//                 )}

//                 {selectedSmartPlan?.debts.length ? (
//                   <SmartPlanCard headerText={"Debt Information"}>
//                     {selectedSmartPlan?.debts.map((item, index) => {
//                       return (
//                         <div
//                           key={index}
//                           className={
//                             selectedSmartPlan?.debts.length > 1
//                               ? "w-full pb-1 border-b"
//                               : "w-full"
//                           }
//                         >
//                           <RowWrapper
//                             title="Debt Type"
//                             value={item?.debtType}
//                           />

//                           <RowWrapper
//                             title="Institution Name"
//                             value={item?.institution}
//                           />
//                           <RowWrapper
//                             title="Debt Balance"
//                             value={`$ ${item?.balance}`}
//                           />
//                           <RowWrapper
//                             title="Monthly Payment"
//                             value={`$ ${item?.monthlyPayment}`}
//                           />
//                           <RowWrapper title="Debt Rate" value={item?.rate} />
//                         </div>
//                       );
//                     })}

//                     <RowWrapper
//                       title="Total Debt"
//                       value={`$ ${selectedSmartPlan?.totalDebt}`}
//                     />
//                     <RowWrapper
//                       title="Debt Standing "
//                       value={selectedSmartPlan?.debtStanding}
//                     />
//                   </SmartPlanCard>
//                 ) : null}

//                 {selectedSmartPlan?.accounts.length ? (
//                   <SmartPlanCard headerText={"Accounts Information"}>
//                     {selectedSmartPlan?.accounts.map((item, index) => {
//                       return (
//                         <div
//                           key={index}
//                           className={
//                             selectedSmartPlan?.accounts.length > 1
//                               ? "w-full pb-1 border-b"
//                               : "w-full"
//                           }
//                         >
//                           <RowWrapper title="Account Type" value={item?.type} />

//                           <RowWrapper
//                             title="Institution Name"
//                             value={item?.institution}
//                           />
//                           <RowWrapper
//                             title="Account Balance"
//                             value={`$ ${item?.balance}`}
//                           />
//                         </div>
//                       );
//                     })}
//                   </SmartPlanCard>
//                 ) : null}

//                 {selectedSmartPlan?.property.length ? (
//                   <SmartPlanCard headerText={"Assets Information"}>
//                     {selectedSmartPlan?.property.map((item, index) => {
//                       return (
//                         <div
//                           key={index}
//                           className={
//                             selectedSmartPlan?.property.length > 1
//                               ? "w-full pb-1 border-b"
//                               : "w-full"
//                           }
//                         >
//                           <RowWrapper title="Asset Name" value={item?.name} />

//                           <RowWrapper
//                             title="Asset Value"
//                             value={`$ ${item?.value}`}
//                           />
//                         </div>
//                       );
//                     })}
//                   </SmartPlanCard>
//                 ) : null}

//                 <SmartPlanCard headerText={"Other Information"}>
//                   <RowWrapper
//                     title="Last Will "
//                     value={
//                       selectedSmartPlan?.questions?.lastWill ? "Yes" : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Living Trust "
//                     value={
//                       selectedSmartPlan?.questions?.livingTrust ? "Yes" : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Health Care Power Of Attorney "
//                     value={
//                       selectedSmartPlan?.questions?.healthCarePowerOfAttorney
//                         ? "Yes"
//                         : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Financial Power Of Attorney "
//                     value={
//                       selectedSmartPlan?.questions?.financialPowerOfAttorney
//                         ? "Yes"
//                         : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Documents Organized "
//                     value={
//                       selectedSmartPlan?.questions?.documentsOrganized
//                         ? "Yes"
//                         : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Identity Theft Protection "
//                     value={
//                       selectedSmartPlan?.questions?.identityTheftProtection
//                         ? "Yes"
//                         : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Debt Free "
//                     value={
//                       selectedSmartPlan?.questions?.onTrackDebtFree
//                         ? "Yes"
//                         : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Budgeting And Debt-Payoff Tool "
//                     value={
//                       selectedSmartPlan?.questions?.budgetingAndDebtPayoffTool
//                         ? "Yes"
//                         : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Prepared To Manage Finances "
//                     value={
//                       selectedSmartPlan?.questions?.preparedToManageFinances
//                         ? "Yes"
//                         : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Interested In Education And Services "
//                     value={
//                       selectedSmartPlan?.questions
//                         ?.interestedInEducationAndServices
//                         ? "Yes"
//                         : "No"
//                     }
//                   />

//                   <RowWrapper
//                     title="Reporting Rent Payments "
//                     value={
//                       selectedSmartPlan?.questions?.reportingRentPayments
//                         ? "Yes"
//                         : "No"
//                     }
//                   />
//                 </SmartPlanCard>
//               </CardWrapper>
//             );
//           })}
//         </CardWrapper>
//       ) : (
//         <p>You don't have any smart plan</p>
//       )}
//     </>
//   );
// };

// export default SmartPlan;
