import React, { useEffect, useState } from "react";
import stripe from "../../Assets/stripe.png";
import InputWrapper from "../Wrapper/InputWrapper";
import InputSmall from "../Input/InputSmall";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { stripeSchema } from "../../Schema";
import ButtonLoading from "../Buttons/ButtonLoading";
import { addStripeKey, getStripeKey } from "../../Redux/Actions";
import Loader from "../Loading/Loader";
const inititialState = {
  secretKey: "",
  publicKey: "",
};

const StripeCard = () => {
  let Once = true;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { data, isInvoked } = useSelector((state) => state.stripe);

  const { values, touched, handleChange, handleBlur, errors, handleSubmit } =
    useFormik({
      initialValues: inititialState,
      validationSchema: stripeSchema,
      onSubmit: (values, { resetForm }) => {
        setBtnLoading(true);
        dispatch(addStripeKey(userData.id, values)).then(() => {
          resetForm();
          setBtnLoading(false);
        });
      },
    });

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setLoading(true);
        dispatch(getStripeKey(userData.id))
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      }
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="w-[full] bg-white rounded-md px-4 py-5 mt-5 mb-3">
      <div className="w-full flex gap-2 justify-start items-center ">
        <div className="w-[60px] h-[60px] bg-[--gray-light] rounded-full flex justify-center items-center ">
          <img className="w-[70%] h-[70%]" src={stripe} alt="Stripe logo" />
        </div>
        <h5 className="satoshi-700 text-[21px]">Stripe</h5>
      </div>
      {data ? (
        <div className="mt-2 mb-3 px-4">
          <h1 className="satoshi-700 text-[16px] sm:text-[18px]">
            Below is your stripe information
          </h1>
          <p className="text-[14px] sm:text-[16px] break-words mt-4">
            <span className="font-medium">Stripe Public Key</span> :{" "}
            {data?.publicKey}
          </p>
          <p className="text-[14px] sm:text-[16px] mt-2">
            <span className="font-medium">Stripe Secret Key</span> :{" "}
            {data?.secretKey}
          </p>
        </div>
      ) : (
        <div className="mt-3">
          <p className="text-[14px] sm:text-[16px] satoshi-500">
            Kindly add your stripe key for payment
          </p>
        </div>
      )}

      <div className="w-[90%] mb-6">
        <InputWrapper customClasses="!items-stretch">
          <InputSmall
            name="publicKey"
            onChange={handleChange}
            error={errors?.publicKey}
            value={values?.publicKey}
            onBlur={handleBlur}
            id="publicKey"
            touch={touched?.publicKey}
            label="Public Key"
            placeholder="Stripe public key here"
          />

          <InputSmall
            name="secretKey"
            onChange={handleChange}
            error={errors?.secretKey}
            value={values?.secretKey}
            onBlur={handleBlur}
            id="secretKey"
            touch={touched?.secretKey}
            label="Secret Key"
            placeholder="Stripe secret key here"
          />
        </InputWrapper>
      </div>
      {btnLoading ? (
        <ButtonLoading customClasses={"!w-[150px]"} spinOnly={true} />
      ) : (
        <ButtonSmall onClick={handleSubmit} text={!data ? "Add" : "Update"} />
      )}
    </div>
  );
};

export default StripeCard;
