import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import InputSmall from "../Input/InputSmall";
import { useFormik } from "formik";
import { packageSchema } from "../../Schema";
import SelectInput from "../Input/SelectInput";
import { PackageCheckBoxes } from "../../Data/StaticData";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelfPackages,
  getStripeKey,
  updateSelfPackages,
} from "../../Redux/Actions";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import Loader from "../Loading/Loader";
import { Link } from "react-router-dom";
import InputWrapper from "../Wrapper/InputWrapper";
let Once = true;

const PackageModal = ({ open, handleClose, isEdit, dataPackage }) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const coachData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const [itemsError, setItemsError] = useState("");
  const [loading, setLoading] = useState(false);
  const [durationError, setDurationError] = useState("");
  const [packageData, setPackageData] = useState({
    name: isEdit ? dataPackage?.name : "",
    description: isEdit ? dataPackage?.description : "",
    packageType: isEdit ? dataPackage?.packageType : "Sell",
    amount: isEdit ? dataPackage?.amount : 0,
    included: isEdit ? dataPackage?.included : [],
    duration: {
      durationType: isEdit ? dataPackage?.duration?.durationType : "month",
      duration: isEdit ? dataPackage?.duration?.duration : 0,
    },
  });

  const { data, isInvoked } = useSelector((state) => state.stripe);
  const userData = useSelector((state) => state.auth.userData);

  function handleCheckBox(e) {
    const checkboxValue = e.target.value;
    if (e.target.checked) {
      let updatedCheckBoxList = [...packageData.included, checkboxValue];
      setSelectAllChecked(
        updatedCheckBoxList.length === PackageCheckBoxes.length
      );
      setPackageData((preValues) => {
        return {
          ...preValues,
          included: updatedCheckBoxList,
        };
      });
    } else {
      setSelectAllChecked(false);
      setPackageData((preValues) => {
        return {
          ...preValues,
          included: preValues.included.filter((item) => item !== checkboxValue),
        };
      });
    }
  }

  // Select All function
  function handleSelectAllChange(e) {
    const isChecked = e.target.checked;
    setSelectAllChecked(isChecked);
    if (isChecked) {
      packageData.included = coachData?.permissions?.map((item) => item);
      // console.log(packageData)
    } else {
      packageData.included = isEdit ? dataPackage.included : [];
    }
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: packageData,
      validationSchema: packageSchema,
      onSubmit: (values, action) => {
        if (packageData.duration.duration == 0) {
          setDurationError("Please select duration of the package");
        } else if (!packageData.included.length) {
          setItemsError("Please select atleast 1 item for package");
        } else {
          setLoading(true);
          values.duration = packageData.duration;
          values.included = packageData.included;
          if (isEdit) {
            dispatch(updateSelfPackages(values, dataPackage._id)).then(() => {
              setPackageData({
                name: "",
                description: "",
                amount: "",
                included: [],
                duration: {
                  durationType: "month",
                  duration: 0,
                },
              });
              action.resetForm();
              setLoading(false);
              handleClose();
            });
          } else {
            dispatch(addSelfPackages(values)).then(() => {
              setPackageData({
                name: "",
                description: "",
                amount: "",
                included: [],
                duration: {
                  durationType: "month",
                  duration: 0,
                },
              });
              action.resetForm();
              setLoading(false);
              handleClose();
            });
          }
        }
      },
    });

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setLoading(true);
        dispatch(getStripeKey(userData.id))
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      }
    }
  }, []);
  return (
    <Modal
      open={open}
      onclick={handleClose}
      headerText={isEdit ? "Update Package" : "Add Package"}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {data ? (
            <>
              <InputSmall
                name="name"
                id="packageName"
                label="Package Name"
                type="text"
                placeholder="Enter Package Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                touch={touched.name}
              />

              <InputSmall
                customClasses="!mt-4"
                name="description"
                type="text"
                id="description"
                label="Description"
                placeholder="Write description"
                onChange={handleChange}
                value={values.description}
                onBlur={handleBlur}
                error={errors.description}
                touch={touched.description}
              />

              <InputWrapper>
                <div className="flex-grow basis-48">
                  <label
                    htmlFor="packageType"
                    className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
                  >
                    Package Type
                  </label>
                  <select
                    className="w-full rounded-md  border outline-none py-2 px-2"
                    name="packageType"
                    id="packageType"
                    value={values.packageType}
                    onChange={(e) => {
                      values.amount = 0;
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  >
                    <option className="text-black" value="Free">
                      Free (No payment required )
                    </option>
                    <option className="text-black" value="Sell">
                      Sell (Payment required)
                    </option>
                  </select>
                  {errors.packageType && touched.packageType ? (
                    <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
                      {errors.packageType}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </InputWrapper>
              {values.packageType === "Sell" ? (
                <InputSmall
                  customClasses="!mt-4"
                  type="number"
                  name="amount"
                  id="price"
                  label="Price"
                  placeholder="Price of package"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.amount}
                  error={errors.amount}
                  touch={touched.amount}
                />
              ) : null}

              <SelectInput
                value={packageData.duration.duration}
                name="duration"
                customClassess="!mt-4"
                error={durationError}
                onChange={(e) => {
                  setDurationError("");
                  setPackageData((prevPackageData) => ({
                    ...prevPackageData,
                    duration: {
                      durationType: e.target.value === "12" ? "year" : "month",
                      duration: parseInt(e.target.value),
                    },
                  }));
                }}
                options={[
                  { label: "Duration", value: "0", disabled: true },
                  { label: "Month", value: 1, disabled: false },
                  { label: "Six Month ", value: 6, disabled: false },
                  { label: "Year", value: 12, disabled: false },
                  { label: "One time payment", value: 12, disabled: false },
                ]}
              />
              {/* Slect All */}

              <div className="flex justify-end items-center">
                <input
                  name={"selectall"}
                  // value={checkbox.value}
                  id={"selectall"}
                  type="checkbox"
                  checked={selectAllChecked}
                  onChange={handleSelectAllChange}
                />

                <label
                  htmlFor="selectall"
                  className="pl-2 capitalize text-[12px] sm:text-[16px] satoshi-500"
                >
                  Select All
                </label>
              </div>

              {coachData && coachData?.permissions?.length ? (
                <div className="flex justify-between items-center flex-wrap py-4">
                  {coachData?.permissions?.map((permission, index) => {
                    const isChecked = packageData.included.includes(permission);
                    const name = permission
                      .toLowerCase()
                      .replace(/[^a-zA-Z0-9]+/g, "_");
                    return (
                      <div
                        key={index}
                        className="w-[130px] sm:w-[200px] flex justify-start items-center"
                      >
                        {isEdit ? (
                          <input
                            name={name}
                            value={permission}
                            id={name}
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckBox}
                            className="min-w-[15px] h-[15px]"
                          />
                        ) : (
                          <input
                            name={name}
                            value={permission}
                            id={name}
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckBox}
                            className="min-w-[15px] h-[15px]"
                          />
                        )}

                        <label
                          htmlFor={name}
                          className="pl-2 capitalize text-[12px] sm:text-[16px] satoshi-500"
                        >
                          {permission}
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              <p className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-2 mb-3">
                {itemsError}
              </p>

              <div className="flex justify-center items-center gap-4 pt-8">
                {loading ? (
                  <ButtonLoading />
                ) : (
                  <>
                    <ButtonSmall
                      type="button"
                      text="Cancel"
                      customClasses={"!w-1/2 !bg-white !text-[--primary]"}
                      onClick={handleClose}
                    />
                    <ButtonSmall
                      onClick={handleSubmit}
                      type="button"
                      text={isEdit ? "Update" : "Add"}
                      customClasses={"!w-1/2"}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <p className="text-[16px] md:text-[18px] text-red-600 ">
                Welcome to our platform! To seamlessly add packages, kindly
                navigate to your account settings or{" "}
                <Link
                  to="/setting"
                  className="text-blue-500 underline satoshi-500"
                >
                  Click here to redirect
                </Link>{" "}
                where you can upload your Stripe public and secret keys. These
                keys are essential for secure payment processing. Once you've
                uploaded them, you'll be able to add packages hassle-free. If
                you encounter any issues or need assistance, please don't
                hesitate to reach out to our support team.
                <h1 className="text-center">Thank you !</h1>
              </p>
              <div className="flex justify-center items-center mt-3">
                <ButtonSmall
                  onClick={handleClose}
                  type="button"
                  text={"Close"}
                  customClasses={"!w-1/2"}
                />
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default PackageModal;
