import React from "react";
import Modal from "./Modal";
import ButtonSmall from "../Buttons/ButtonSmall";
import CardWrapper from "../Wrapper/CardWrapper";
import TaskAssociationDetail from "../Card/TaskAssociationDetail";
import parse from "html-react-parser";

const TaskDetailModal = ({ open, handleClose, data, handleReopen }) => {
  return (
    <Modal open={open} onclick={handleClose} headerText="Task Details">
      <div className="px-5">
        <h2 className="satoshi-700">Task Title</h2>
        <p>{data?.title}</p>

        <h3 className="satoshi-700 mt-2">Task Description</h3>
        <p className="description"> {parse(parse(data?.description))}</p>
      </div>
      {data?.taskassociations?.length ? (
        <CardWrapper extraClasses={"!items-stretch"}>
          {data.taskassociations.map((item, index) => {
            return (
              <TaskAssociationDetail
                key={index}
                data={item}
                handleReOpen={handleReopen}
              />
            );
          })}
        </CardWrapper>
      ) : null}

      <div className="flex justify-center items-center gap-4 pt-8">
        <ButtonSmall
          type="button"
          text="Cancel"
          customClasses={"!w-1/2"}
          onClick={handleClose}
        />
        <ButtonSmall
          onClick={() => {
            handleReopen(data.id, null, "all");
          }}
          type="button"
          text="Re-Open for all"
          customClasses={"!w-1/2 !bg-red-800 !text-white !border-red-600"}
        />
      </div>
    </Modal>
  );
};

export default TaskDetailModal;
