import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { assignPackage, getDashboardData } from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loading/Loader";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import InputWrapper from "../Wrapper/InputWrapper";
import InputSmall from "../Input/InputSmall";

let Once = true;

const AssignPackageModal = ({ handleClose, open, data }) => {
  const dashboardData = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [allSelect, setAllSelect] = useState(false);
  const [values, setValues] = useState(
    data?.users?.map((user) => user?._id) || []
  );

  function handleClient(e) {
    if (e.target.checked) {
      setValues([...values, e.target.value]);
      setError("");
    } else {
      setValues(values.filter((item) => item !== e.target.value));
    }
  }

  function handleSelectAll(e) {
    if (e.target.checked) {
      setValues(dashboardData?.clients?.map((item) => item._id));
      setAllSelect(true);
    } else {
      setValues([]);
      setAllSelect(false);
    }
  }

  function handleSubmit() {
    if (!values.length) {
      setError("Please select at least one client");
      return;
    }
    setBtnLoading(true);

    const body = {
      clientId: values,
      packageId: data?._id,
    };
    dispatch(assignPackage(body)).then(() => {
      setBtnLoading(false);
      handleClose();
    });
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (dashboardData.isNotInvoked) {
        setLoading(true);
        dispatch(getDashboardData()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);
  return (
    <Modal onclick={handleClose} open={open} headerText="Assign Package">
      {loading ? (
        <Loader />
      ) : (
        <>
          {dashboardData?.clients?.length ? (
            <>
              <InputWrapper>
                <InputSmall
                  label={"Package Name"}
                  disable={true}
                  value={data?.name?.toUpperCase()}
                />
              </InputWrapper>

              <InputWrapper>
                <InputSmall
                  label={"Package Price"}
                  disable={true}
                  value={data?.amount}
                />
              </InputWrapper>
              <div className="relative w-[60%] text-[--gray] bg-white border rounded-md mb-2 mt-3">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button
                    type="submit"
                    className="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </button>
                </span>
                <input
                  type="search"
                  name="q"
                  className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
                  placeholder="Search Client"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="mb-3 satoshi-700 mt-8 text-[14px] md:text-[16px]">
                    Please select Clients
                  </p>
                </div>
                <div className="flex justify-start items-center pl-2 mb-1 min-w-[100px] border py-1 rounded-md bg-slate-50">
                  <input
                    type="checkbox"
                    value={allSelect}
                    onChange={handleSelectAll}
                    checked={dashboardData?.clients?.length === values?.length}
                    className="w-3 h-3 satoshi-500 cursor-pointer"
                  />
                  <label className="ml-2 text-[12px] satoshi-500 w-max cursor-pointer">
                    Select All
                  </label>
                </div>
              </div>

              <div className="max-h-[200px] flex justify-start w-full items-stretch flex-wrap gap-2 ">
                {dashboardData?.clients
                  ?.filter((client) => {
                    const fullName = `${
                      client?.firstName + " " + client?.lastName
                    }`;
                    const searchQueryNormalized = searchQuery
                      .toLowerCase()
                      .replace(/\s/g, "");
                    return fullName
                      .toLowerCase()
                      .replace(/\s/g, "")
                      .includes(searchQueryNormalized);
                  })
                  .map((client, index) => (
                    <div
                      key={index}
                      className="flex justify-start items-center pl-2 mb-1 min-w-[200px] max-w-max border py-2 rounded-md bg-slate-50"
                    >
                      <input
                        type="checkbox"
                        value={client._id}
                        onChange={handleClient}
                        checked={values.includes(client._id)}
                        className="w-4 h-4 satoshi-500 cursor-pointer"
                      />
                      <label className="ml-2 text-[13px] satoshi-500 w-max cursor-pointer pr-3">
                        {`${client.firstName} ${client.lastName}`}
                      </label>
                    </div>
                  ))}
              </div>

              {error && (
                <p className="text-red-600 text-[14px] mt-1 text-center">
                  {error}
                </p>
              )}
            </>
          ) : (
            <p className="text-red-400">
              You don't have any coach to assign package
            </p>
          )}
        </>
      )}

      <div className="flex justify-center items-center gap-4 mt-6 w-full">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              text="Cancel"
              onClick={handleClose}
              customClasses="!bg-white !text-black"
            />
            <ButtonSmall text="Assign" onClick={handleSubmit} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default AssignPackageModal;
