import React, { useEffect, useState } from "react";
import PackageCard from "../../components/Card/PackageCard";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  fetchAdminPackages,
  getAdminPublicKey,
  getStripeKey,
  logout,
  subscribePackage,
} from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import { loadStripe } from "@stripe/stripe-js";
import api from "../../Redux/Actions/axiosInstance";
import { errorToast } from "../../Utils/Toast";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import NoPaymentModal from "../../components/Modal/NoPaymentModal";

const PackageSelection = () => {
  let Once = true;
  const [loading, setLoading] = useState(false);
  const packagesInfo = useSelector((state) => state.packages.adminPackages);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const [modal, setModal] = useState(false);
  const [adminPublicKey, setAdminPublicKey] = useState("");
  const [apiResponse, setApiResponse] = useState(false);

  async function handleSubscription(id, name, price) {
    try {
      if (!adminPublicKey) {
        setModal(true);
        return;
      }
      setLoading(true);
      const stripe = await loadStripe(adminPublicKey);
      const body = {
        packageName: name,
        id: id,
        price: price,
        userId: userData._id,
        role: "Coach",
      };
      const response = await api.post("/createPaymentIntent", body);
      // console.log(response);
      localStorage.setItem("sessionId", response?.data?.sessionId);
      const result = stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
    } catch (error) {
      console.log(error);
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  function handleFreePackage(packageId) {
    setLoading(true);
    subscribePackage({ packageId: packageId }).then(() => {
      window.location.reload();
    });
  }

  function handleLogout() {
    setLoading(true);
    dispatch(logout()).then(() => {
      window.location.reload();
    });
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      getAdminPublicKey()
        .then((res) => {
          if (res?.data?.publicKey) {
            setAdminPublicKey(res?.data?.publicKey);
            setApiResponse(true);
            dispatch(fetchAdminPackages()).then(() => {
              setLoading(false);
            });
          } else {
            setApiResponse(true);
            setTimeout(() => {
              setLoading(false);
            }, 1500);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          errorToast("Something went wrong");
        });
    }
  }, []);
  return (
    <>
      <NoPaymentModal
        open={modal}
        handleClose={() => {
          setModal(false);
        }}
      />
      <div className="flex justify-end items-center w-full bg-[--bg-yellow-light] py-8">
        <ButtonSmall
          onClick={handleLogout}
          text="Logout"
          customClasses={"!bg-red-700 !border !border-red-700 !mr-4"}
        />
      </div>
      <section className="w-full min-h-screen px-10 pt-2 pb-4 bg-[--bg-yellow-light]">
        {loading ? (
          <Loader />
        ) : apiResponse && !adminPublicKey ? (
          <p className="mt-5 text-[20px]">
            We're sorry for the inconvenience! It seems that our admin hasn't
            added any packages yet. Please rest assured, our team is working
            diligently to bring you the best subscription options. Your interest
            is highly valued, and we appreciate your patience. Please check back
            shortly, and we'll have exciting packages ready for you to explore!
          </p>
        ) : packagesInfo.length ? (
          <>
            <div className="py-2 my-2 px-3 bg-white rounded-md">
              <p className="text-red-400">
                <span className="satoshi-700">Note: </span>
                To access this portal's features and services, it's essential to
                have an active subscription or package. We value the importance
                of providing our users with comprehensive access to our
                platform, and a subscription or package ensures that you can
                utilize its full potential. Please consider subscribing or
                obtaining a package to continue enjoying our offerings
                seamlessly.
              </p>
            </div>
            <CardWrapper extraClasses={"!items-stretch !justify-start"}>
              {packagesInfo.map((packageData, index) => {
                return (
                  <PackageCard
                    key={index}
                    packageName={packageData.name}
                    description={packageData.description}
                    price={
                      packageData?.packageType === "Sell"
                        ? `$ ${packageData?.amount}`
                        : "Free"
                    }
                    subscriptionTime={
                      packageData.duration.durationType.toLowerCase() === "year"
                        ? "1 Year"
                        : `${packageData.duration.duration} month`
                    }
                    susbcriptionDetail={packageData.included}
                    self={false}
                    isActive={false}
                    packageStatus={false}
                    onHandleSubscribe={() => {
                      if (packageData?.packageType === "Free") {
                        handleFreePackage(packageData.id);
                      } else {
                        handleSubscription(
                          packageData.id,
                          packageData.name,
                          packageData.amount
                        );
                      }
                    }}
                  />
                );
              })}
            </CardWrapper>
          </>
        ) : (
          <p>
            We're sorry for the inconvenience! It seems that our admin hasn't
            added any packages yet. Please rest assured, our team is working
            diligently to bring you the best subscription options. Your interest
            is highly valued, and we appreciate your patience. Please check back
            shortly, and we'll have exciting packages ready for you to explore!
          </p>
        )}
      </section>
    </>
  );
};

export default PackageSelection;
