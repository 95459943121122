const initialState = {
  auth: false,
  userData: null,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN":
      return {
        ...state,
        auth: true,
        userData: payload.data,
      };
    case "LOGOUT": {
      return {
        auth: false,
        userData: null,
      };
    }
    case "UPDATE_USER":
      return {
        ...state,
        userData: {
          ...state.userData,
          firstName: payload.firstName,
          lastName: payload.lastName,
          photo: payload.photo,
          coachInfo: {
            ...state.userData.coachInfo,
            BrandLogo: payload.coachInfo.BrandLogo,
          },
        },
      };
    case "UPDATE_USER_EMAIL":
      return {
        ...state,
        userData: {
          ...state.userData,
          email: payload,

        },
      };
    default:
      return state;
  }
};

export default authReducer;
