import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { reOpenStaffTask } from "../../Redux/Actions";

const AddTaskNote = ({ open, handleModal, data }) => {
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const initialState = {
    method: data.method,
    note: "",
    staffId: data.staffId,
    taskId: data.taskId,
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { handleSubmit } = useFormik({
    initialValues: initialState,
    onSubmit: (values) => {
      if (!note) {
        setError("Please add a note");
        return;
      }
      setLoading(true);
      values.note = note;
      dispatch(reOpenStaffTask(values)).then(() => {
        setLoading(false);
        handleModal();
      });
    },
  });

  return (
    <Modal headerText="Re Open Task" open={open} onclick={handleModal}>
      <div className="flex justify-start items-start flex-col">
        <label
          htmlFor="note"
          className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
        >
          Add Note
        </label>
        <textarea
          onChange={(e) => {
            setError("");
            setNote(e.target.value);
          }}
          value={note}
          name="note"
          id="note"
          className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-md focus:border-gray-800 block w-full px-2.5 py-2"
          rows="7"
        ></textarea>
        {error ? (
          <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
            {error}
          </span>
        ) : null}
      </div>

      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!bg-white !border-[--primary] !text-[--primary]"}
              onClick={handleModal}
            />
            <ButtonSmall onClick={handleSubmit} type="button" text="Update" />
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddTaskNote;
