import React, { useEffect, useState } from "react";
import PackageCard from "../../components/Card/PackageCard";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  fetchAdminPackages,
  getAdminPublicKey,
  subscribePackage,
} from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import HeaderData from "../../components/Header/HeaderData";
import { loadStripe } from "@stripe/stripe-js";
import { errorToast } from "../../Utils/Toast";
import api from "../../Redux/Actions/axiosInstance";
// import NoPaymentModal from "../../components/Modal/NoPaymentModal";
// import TextModal from "../../components/Modal/TextModal";

let Once = true;

const UpgradePackage = () => {
  const [loading, setLoading] = useState(false);
  const packagesInfo = useSelector((state) => state.packages.adminPackages);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const [adminPublicKey, setAdminPublicKey] = useState("");
  const [apiResponse, setApiResponse] = useState(false);
  const [modal, setModal] = useState(false);

  // console.log("packagesInfo--", packagesInfo)
  async function handleUpgrade(id, name, price) {
    try {
      if (!adminPublicKey) {
        setModal(true);
        return;
      }
      setLoading(true);
      // admin public key here
      const stripe = await loadStripe(adminPublicKey);
      const body = {
        packageName: name,
        id: id,
        price: price,
        userId: userData._id,
        role: "Coach",
      };

      const response = await api.post("/createPaymentIntent", body);
      localStorage.setItem("sessionId", response?.data?.sessionId);
      const result = stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
    } catch (error) {
      console.log(error);
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  function handleFreePackage(packageId) {
    setLoading(true);
    subscribePackage({ packageId: packageId }).then(() => {
      window.location.reload();
    });
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      setLoading(true);
      getAdminPublicKey()
        .then((res) => {
          if (res?.data?.publicKey) {
            setAdminPublicKey(res?.data?.publicKey);
            setApiResponse(true);
            if (isEmpty(packagesInfo)) {
              dispatch(fetchAdminPackages()).then(() => {
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
          } else {
            setApiResponse(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          errorToast("Something went wrong");
        });
    }
  }, []);

  return (
    <>
      <HeaderData text="Upgrade Package" />
      {loading ? (
        <Loader />
      ) : apiResponse && !adminPublicKey ? (
        <p className="mt-5 text-[20px]">
          We apologize for the inconvenience! At the moment, our payment gateway
          is undergoing setup and is not available for transactions. Our team is
          actively working to integrate a secure payment gateway to provide you
          with a smooth purchasing experience. Please check back soon, as we'll
          have it up and running in no time.Thank you for your patience and
          understanding!
        </p>
      ) : packagesInfo.length ? (
        <CardWrapper extraClasses={"!items-stretch !justify-start"}>
          {packagesInfo.map((packageData, index) => {
            return (
              <PackageCard
                key={index}
                packageName={packageData.name}
                description={packageData.description}
                price={
                  packageData?.packageType === "Sell"
                    ? `$ ${packageData?.amount}`
                    : "Free"
                }
                subscriptionTime={
                  packageData.duration.durationType.toLowerCase() === "year"
                    ? "1 Year"
                    : `${packageData.duration.duration} month`
                }
                susbcriptionDetail={packageData.included}
                self={false}
                isActive={packageData._id === userData.CoachPackage.packageId}
                packageStatus={true}
                onHandleSubscribe={() => {
                  if (packageData?.packageType === "Free") {
                    handleFreePackage(packageData._id);
                  } else {
                    handleUpgrade(
                      packageData._id,
                      packageData.name,
                      packageData.amount
                    );
                  }
                }}
              />
            );
          })}
        </CardWrapper>
      ) : (
        <p>
          We're sorry for the inconvenience! It seems that our admin hasn't
          added any packages yet. Please rest assured, our team is working
          diligently to bring you the best subscription options. Your interest
          is highly valued, and we appreciate your patience. Please check back
          shortly, and we'll have exciting packages ready for you to explore!
        </p>
      )}
    </>
  );
};

export default UpgradePackage;
