import React from "react";

const ButtonSmall = ({ type, onClick, text, customClasses }) => {
  return (
    <button
      type={type ? type : "submit"}
      onClick={onClick}
      className={`bg-[--primary]  text-white text-[12px] sm:text-[14px] md:text-[16px]  border border-[--primary] font-semibold rounded-md px-4  sm:px-12 md:px-16 py-2 ${customClasses}`}
    >
      {text}
    </button>
  );
};

export default ButtonSmall;
