import React, { useState } from "react";
import MediaContainer from "../Wrapper/MediaContainer";
import InputWrapper from "../Wrapper/InputWrapper";
import InputRequired from "../Input/InputRequired";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { mediaSchema } from "../../Schema";
// import RadioInput from "../Input/RadioInput";
import ReactQuill from "react-quill";
import { FaImage } from "react-icons/fa";

import "react-quill/dist/quill.snow.css";
import {
  // addMedia,
  updateMediaBlog,
  updateMediaVideo,
  uploadPhoto,
} from "../../Redux/Actions";
import parse from "html-react-parser";
import Dropzone from "react-dropzone";
import { errorToast } from "../../Utils/Toast";

const EditMedia = ({ handleClose, data, mediaType }) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [description, setDescription] = useState(parse(data.description));
  const [btnLoading, setBtnLoading] = useState(false);
  const [dataVideoLoad, setVideoLoad] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const dispatch = useDispatch();
  const initialState = {
    title: data.title,
    description: data.description,
    type: "media",
    showToUsers: data.showToUsers,
  };

  const [error, setError] = useState({
    imageError: "",
    videoError: "",
    thumnailError: "",
    descriptionError: "",
  });

  const onDropThumbnail = (files) => {
    setError((pre) => {
      return {
        ...pre,
        thumnailError: "",
      };
    });
    // Check if the dropped file has an allowed extension
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setThumbnail(files[0]);
    } else {
      // Display an error message or handle the case where the file is not allowed
      setError((pre) => {
        return {
          ...pre,
          thumnailError:
            "Invalid file type. Please upload a PNG ,JPG or JPEG file.",
        };
      });
    }
  };

  const onDropImage = (files) => {
    setError((pre) => {
      return {
        ...pre,
        imageError: "",
      };
    });
    // Check if the dropped file has an allowed extension
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setSelectedFiles(files[0]);
    } else {
      // Display an error message or handle the case where the file is not allowed
      setError((pre) => {
        return {
          ...pre,
          imageError:
            "Invalid file type. Please upload a PNG ,JPG or JPEG file.",
        };
      });
    }
  };

  const onDropVideo = (files) => {
    setError((pre) => {
      return {
        ...pre,
        videoError: "",
      };
    });
    // Check if the dropped file has an allowed extension
    const allowedExtensions = ["mp4", "mkv", "flv"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setVideoLoad(true);
      setSelectedVideo(files[0]);
      setTimeout(() => {
        setVideoLoad(false);
      }, 2000);
    } else {
      // Display an error message or handle the case where the file is not allowed
      setError((pre) => {
        return {
          ...pre,
          videoError:
            "Invalid file type. Please upload an MP4, MKV, or FLV video file.",
        };
      });
    }
  };

  function handleBlog(reqBody) {
    dispatch(updateMediaBlog(reqBody)).then(() => {
      setBtnLoading(false);
      // window.location.reload();
      handleClose();
    });
  }

  function handlevideo(reqBody) {
    dispatch(updateMediaVideo(reqBody)).then(() => {
      setBtnLoading(false);
      // window.location.reload();
      handleClose();
    });
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: mediaSchema,
      onSubmit: (values) => {
        if (!description) {
          setError((pre) => {
            return {
              ...pre,
              descriptionError: "Description is required",
            };
          });
        } else {
          values.description = description;
          values.id = data._id;
          setBtnLoading(true);
          if (selectedFiles || selectedVideo) {
            uploadPhoto({ photo: selectedFiles || selectedVideo }).then(
              (response) => {
                if (selectedFiles) {
                  values.photo = response.data.photos[0];
                  handleBlog(values);
                } else {
                  values.video = response.data.photos[0];
                  if (thumbnail) {
                    uploadPhoto({ photo: thumbnail })
                      .then((res) => {
                        values.thumbnail = res.data.photos[0];
                        handlevideo(values);
                      })
                      .catch((err) => {
                        console.error(err);
                        errorToast("Thumnail not updated");
                        handlevideo(values);
                      });
                  } else {
                    handlevideo(values);
                  }
                }
              }
            );
          } else {
            if (mediaType === "video") {
              values.video = data.video;
              if (thumbnail) {
                uploadPhoto({ photo: thumbnail })
                  .then((res) => {
                    values.thumbnail = res.data.photos[0];
                    handlevideo(values);
                  })
                  .catch((err) => {
                    console.error(err);
                    errorToast("Thumnail not updated");
                    handlevideo(values);
                  });
              } else {
                handlevideo(values);
              }
            } else {
              values.photo = data.photo;
              handleBlog(values);
            }
          }
        }
      },
    });

  return (
    <section className="w-full flex justify-between items-start gap-4">
      <div className="w-[73%]">
        <MediaContainer text={"Please fill the data"} />
        <div className="bg-white rounded-md px-6 py-4">
          <InputWrapper customClasses={"!mt-6"}>
            <InputRequired
              label="Title"
              placeholder="Enter title"
              name="title"
              id="title"
              value={values.title}
              error={errors.title}
              touch={touched.title}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWrapper>
          <InputWrapper>
            <div className="w-full">
              <label
                htmlFor="description"
                className="block pl-[2px] font-medium text-[14px] capitalize"
              >
                Description
              </label>
              <ReactQuill
                theme="snow"
                id="description"
                value={description}
                className="w-full py-3 rounded-md"
                onChange={setDescription}
                placeholder="Write description here ..."
              />
              <p className="text-red-600 mb-6">{error.descriptionError}</p>
            </div>
          </InputWrapper>
          <InputWrapper customClasses={"!items-stretch"}>
            {mediaType === "blog" ? (
              <div className="flex-grow basis-48">
                <Dropzone onDrop={onDropImage} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps({
                          className: "dropzone",
                        })}
                      >
                        <input {...getInputProps()} />
                        {error.imageError && (
                          <div className="text-center text-red-500">
                            <p>{error.imageError}</p>
                          </div>
                        )}
                        {selectedFiles ? (
                          <div className="mx-auto">
                            <img
                              className="h-24 w-24"
                              src={URL.createObjectURL(selectedFiles)}
                              alt={`Selected file preview`}
                            />
                          </div>
                        ) : (
                          <div className="mx-auto">
                            <img
                              className="h-24 w-24"
                              src={process.env.REACT_APP_IMAGE_URL + data.photo}
                              alt={`Selected file preview`}
                            />
                          </div>
                        )}
                        <h3 className="satoshi-500 mt-2 text-[12px] text-center  md:text-[14px] xl:text-[16px]">
                          Drop your image here, or
                          <span className="text-blue-700 satoshi-500">
                            {" "}
                            browse
                          </span>
                        </h3>
                        <p className="text-[--gray] text-[14px]">
                          .jpg .png .jpeg files supported
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            ) : (
              <>
                <div className="flex-grow basis-48">
                  <Dropzone onDrop={onDropThumbnail} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps({
                            className: "dropzone",
                          })}
                        >
                          <input {...getInputProps()} />
                          {error.imageError && (
                            <div className="text-center text-red-500">
                              <p>{error.imageError}</p>
                            </div>
                          )}
                          {thumbnail ? (
                            <div className="mx-auto">
                              <img
                                className="h-24 w-24"
                                src={URL.createObjectURL(thumbnail)}
                                alt={`Selected file preview`}
                              />
                            </div>
                          ) : data?.thumbnail ? (
                            <div className="mx-auto">
                              <img
                                className="h-24 w-24"
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  data?.thumbnail
                                }
                                alt={`Thumbnail preview`}
                              />
                            </div>
                          ) : (
                            <div className="mx-auto text-7xl">
                              <FaImage />
                            </div>
                          )}
                          <h3 className="satoshi-500 mt-2 text-[12px] text-center  md:text-[14px] xl:text-[16px]">
                            Drop your thumbnail here, or
                            <span className="text-blue-700 satoshi-500">
                              {" "}
                              browse
                            </span>
                          </h3>
                          <p className="text-[--gray] text-[14px]">
                            .jpg .png .jpeg files supported
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="flex-grow basis-48">
                  <Dropzone onDrop={onDropVideo} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps({
                            className: "dropzone",
                          })}
                        >
                          <input {...getInputProps()} />
                          {error.videoError && (
                            <div className="text-center text-red-500">
                              <p>{error.videoError}</p>
                            </div>
                          )}
                          {dataVideoLoad ? (
                            <div
                              role="status"
                              className="flex justify-center items-center h-32 w-full"
                            >
                              <svg
                                aria-hidden="true"
                                className="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : selectedVideo ? (
                            <div className="mx-auto">
                              <video className="h-32 w-52" controls>
                                <source
                                  src={URL.createObjectURL(selectedVideo)}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          ) : (
                            <div className="mx-auto">
                              <video className="h-32 w-52" controls>
                                <source
                                  src={
                                    process.env.REACT_APP_IMAGE_URL + data.video
                                  }
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}
                          <h3 className="satoshi-500 mt-2 text-[12px] text-center  md:text-[14px] xl:text-[16px]">
                            Drop your video here, or
                            <span className="text-blue-700 satoshi-500">
                              {" "}
                              browse
                            </span>
                          </h3>
                          <p className="text-[--gray] text-[14px]">
                            .MP4 .MKV .FLV files supported
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </>
            )}
          </InputWrapper>

          {/* <InputWrapper>
            <div className="flex justify-start items-center pl-1 mb-1">
              <input
                onChange={handleChange}
                name="showToUsers"
                type="checkbox"
                id="showToUsers"
                checked={values.showToUsers}
                className={`satoshi-500 w-[15px] mt-2 h-[15px]`}
              />
              <label
                htmlFor="showToUsers"
                className={`ml-2  satoshi-500 mt-2 text-[16px]`}
              >
                Show To User
              </label>
            </div>
          </InputWrapper> */}
          <div className="flex justify-start items-center gap-4 mt-3">
            {btnLoading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  text="Cancel"
                  onClick={handleClose}
                  customClasses="!bg-white !text-black"
                />
                <ButtonSmall text="Update Content" onClick={handleSubmit} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditMedia;
