import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
import { useSelector } from "react-redux";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import SmartPlanCard from "../../components/Card/SmartPlanCard";
import RowWrapper from "../../components/Wrapper/RowWrapper";
import ButtonSmall from "../../components/Buttons/ButtonSmall";

const SmartPlanDetail = () => {
  const { id } = useParams();
  const { data } = useSelector((state) => state.customerDetail);
  const [selectedSmartPlan, setSelectedSmartPlan] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useNavigate();

  // console.log("Data ===>", data);

  useEffect(() => {
    setLoading(true);
    setSelectedSmartPlan(data?.smartPlan?.find((item) => item._id === id));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <HeaderData text="Smart Plan Detail">
        <ButtonSmall
          onClick={() => {
            history(-1);
          }}
          text="Go back"
        />
      </HeaderData>
      {loading ? (
        <Loader />
      ) : (
        <>
          {selectedSmartPlan ? (
            <>
              <h5 className="mt-5 text-[18px] satoshi-700">
                {selectedSmartPlan?.name}
              </h5>
              <p className="mt-2 text-[14px] md:text-[16px] xl:text-[18px]">
                {selectedSmartPlan?.description}
              </p>
              <CardWrapper extraClasses={"!items-stretch"}>
                {selectedSmartPlan?.personalInformation?.clientInformation ? (
                  <SmartPlanCard headerText={"Personal Information"}>
                    <RowWrapper
                      title="Name"
                      value={
                        selectedSmartPlan?.personalInformation
                          ?.clientInformation?.name
                      }
                    />
                    <RowWrapper
                      title="Email"
                      customClasses={"!lowercase"}
                      value={
                        selectedSmartPlan?.personalInformation
                          ?.clientInformation?.email
                      }
                    />
                    <RowWrapper
                      title="Phone"
                      value={`+${selectedSmartPlan?.personalInformation?.clientInformation?.phone}`}
                    />
                    <RowWrapper
                      title="Address"
                      value={
                        selectedSmartPlan?.personalInformation
                          ?.clientInformation?.address
                      }
                    />
                  </SmartPlanCard>
                ) : null}

                {selectedSmartPlan?.personalInformation?.spouseInformation ? (
                  <SmartPlanCard headerText={"Spouse Information"}>
                    <RowWrapper
                      title="Name"
                      value={
                        selectedSmartPlan?.personalInformation
                          ?.spouseInformation?.name
                      }
                    />
                    <RowWrapper
                      title="Email"
                      customClasses={"!lowercase"}
                      value={
                        selectedSmartPlan?.personalInformation
                          ?.spouseInformation?.email
                      }
                    />
                    <RowWrapper
                      title="Phone"
                      value={`+${selectedSmartPlan?.personalInformation?.spouseInformation?.phone}`}
                    />
                    <RowWrapper
                      title="Address"
                      value={
                        selectedSmartPlan?.personalInformation
                          ?.spouseInformation?.address
                      }
                    />
                  </SmartPlanCard>
                ) : null}

                {selectedSmartPlan?.personalInformation?.hasChildren ? (
                  <SmartPlanCard headerText={"Children Information"}>
                    {selectedSmartPlan?.personalInformation?.childrenInfo.map(
                      (item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className={
                                selectedSmartPlan?.personalInformation
                                  ?.childrenInfo?.length > 1
                                  ? "w-full border-b"
                                  : "w-full"
                              }
                            >
                              <RowWrapper
                                title="Name"
                                value={item?.childName}
                              />
                              <RowWrapper
                                title="Age"
                                value={`${item?.age} years`}
                              />
                            </div>
                          </>
                        );
                      }
                    )}
                  </SmartPlanCard>
                ) : null}
                {selectedSmartPlan?.Debt?.OtherLiabilities?.length ? (
                  <SmartPlanCard headerText={"Other Liabilities"}>
                    {selectedSmartPlan?.Debt?.OtherLiabilities.map(
                      (item, index) => {
                        return (
                          <RowWrapper
                            key={index}
                            title={`Liability ${index + 1}`}
                            value={`$ ${item}`}
                          />
                        );
                      }
                    )}
                  </SmartPlanCard>
                ) : null}
                <SmartPlanCard headerText={"Assets Information"}>
                  {selectedSmartPlan?.AssetsManagement?.Assets.map(
                    (item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSmartPlan?.AssetsManagement?.Assets
                              ?.length > 1
                              ? "w-full border-b pb-3"
                              : "w-full"
                          }
                        >
                          <RowWrapper
                            title="Description"
                            value={item?.Description}
                          />
                          <RowWrapper
                            title="Balance"
                            value={`$ ${item?.Balance}`}
                          />
                          <RowWrapper
                            title="Annual Contribution"
                            value={`${item?.anualContribution}%`}
                          />
                          <RowWrapper
                            title="Employee Contribution"
                            value={`${item?.employeeContribution}%`}
                          />
                        </div>
                      );
                    }
                  )}
                </SmartPlanCard>
                {selectedSmartPlan?.Debt?.debtInformation?.length ? (
                  <SmartPlanCard headerText={"Debt Information"}>
                    {selectedSmartPlan?.Debt?.debtInformation.map(
                      (item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className={
                                selectedSmartPlan?.Debt?.debtInformation
                                  .length > 1
                                  ? "w-full pb-1 border-b"
                                  : "w-full"
                              }
                            >
                              <RowWrapper
                                title="Debt Type"
                                value={item?.debtType}
                              />
                              <RowWrapper
                                title="Balance"
                                value={`$ ${item?.balance}`}
                              />
                              <RowWrapper
                                title="Annual Contribution"
                                value={`${item?.anualContribution}%`}
                              />
                              <RowWrapper
                                title="Description"
                                value={item?.description}
                              />
                            </div>
                          </>
                        );
                      }
                    )}
                  </SmartPlanCard>
                ) : null}

                <SmartPlanCard headerText={"Spouse Savings"}>
                  {selectedSmartPlan?.AssetsManagement?.SpouseSavings.map(
                    (item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSmartPlan?.AssetsManagement?.SpouseSavings
                              ?.length > 1
                              ? "w-full border-b pb-3"
                              : "w-full"
                          }
                        >
                          <RowWrapper
                            title="Description"
                            value={item?.Description}
                          />
                          <RowWrapper
                            title="Balance"
                            value={`$ ${item?.Balance}`}
                          />
                          <RowWrapper
                            title="Annual Contribution"
                            value={`${item?.anualContribution}%`}
                          />
                          <RowWrapper
                            title="Employee Contribution"
                            value={`${item?.employeeContribution}%`}
                          />
                        </div>
                      );
                    }
                  )}
                </SmartPlanCard>
                <SmartPlanCard headerText={"Retirement Work"}>
                  <RowWrapper
                    title="Work in retirement"
                    value={
                      selectedSmartPlan?.workInRetirement?.status ? "Yes" : "No"
                    }
                  />
                  {selectedSmartPlan?.workInRetirement?.status ? (
                    <RowWrapper
                      title="Working years"
                      value={`${selectedSmartPlan?.workInRetirement?.howManyYears} years`}
                    />
                  ) : null}

                  <RowWrapper
                    title="Expected Income"
                    value={`$ ${selectedSmartPlan?.workInRetirement?.expectedIncome}`}
                  />
                  <RowWrapper
                    title="Spouse work in retirement"
                    value={
                      selectedSmartPlan?.workInRetirement?.spouseStatus
                        ? "Yes"
                        : "No"
                    }
                  />
                  {selectedSmartPlan?.workInRetirement?.spouseStatus ? (
                    <RowWrapper
                      title="Working years"
                      value={`${selectedSmartPlan?.workInRetirement?.spouseHowManyYears} years`}
                    />
                  ) : null}
                  <RowWrapper
                    title="Spouse Expected Income"
                    value={`$ ${selectedSmartPlan?.workInRetirement?.spouseExpectedIncome}`}
                  />

                  <RowWrapper
                    title="Desired Income in Retirement"
                    value={`$ ${selectedSmartPlan?.workInRetirement?.desiredIncomeInRetirement}`}
                  />
                  <RowWrapper
                    title="Acceptable Age"
                    value={`${selectedSmartPlan?.workInRetirement?.acceptableAge} years`}
                  />
                </SmartPlanCard>
                <SmartPlanCard headerText={"Social Security Benefits"}>
                  <RowWrapper
                    title="Monthly Benefits"
                    value={`$ ${selectedSmartPlan?.socialSecurityBenefits?.monthlyBenefits}`}
                  />
                  <RowWrapper
                    title="Spouse Monthly Benefits"
                    value={`$ ${selectedSmartPlan?.socialSecurityBenefits?.spouseMonthlyBenefits}`}
                  />
                  <RowWrapper
                    title="Divorced"
                    value={
                      selectedSmartPlan?.socialSecurityBenefits?.divorced
                        ? "Yes"
                        : "No"
                    }
                  />
                  <RowWrapper
                    title="Widow"
                    value={
                      selectedSmartPlan?.socialSecurityBenefits?.widow
                        ? "Yes"
                        : "No"
                    }
                  />
                </SmartPlanCard>

                <SmartPlanCard headerText={"Risk Tolerance"}>
                  <RowWrapper
                    title="Your risk willingness (1-10)"
                    value={selectedSmartPlan?.RiskTolerance?.self}
                  />
                  <RowWrapper
                    title="Spouse risk willingness (1-10)"
                    value={selectedSmartPlan?.RiskTolerance?.spouse}
                  />
                  {selectedSmartPlan?.RiskTolerance?.lastUpdated?.will ? (
                    <RowWrapper
                      title="Last Updated"
                      value={
                        selectedSmartPlan?.RiskTolerance?.lastUpdated
                          ?.description
                      }
                    />
                  ) : null}
                  {selectedSmartPlan?.RiskTolerance?.lastAmmended?.will ? (
                    <RowWrapper
                      title="Last Ammended"
                      value={
                        selectedSmartPlan?.RiskTolerance?.lastAmmended
                          ?.description
                      }
                    />
                  ) : null}
                  {selectedSmartPlan?.RiskTolerance?.selfLifeInsurance
                    ?.Answer ? (
                    <RowWrapper
                      title="Self Life Insurance"
                      value={`$ ${selectedSmartPlan?.RiskTolerance?.selfLifeInsurance?.howMuch}`}
                    />
                  ) : null}
                  {selectedSmartPlan?.RiskTolerance?.wifeLifeInsurance
                    ?.Answer ? (
                    <RowWrapper
                      title="Wife Life Insurance"
                      value={`$ ${selectedSmartPlan?.RiskTolerance?.wifeLifeInsurance?.howMuch}`}
                    />
                  ) : null}
                  <RowWrapper
                    title="Question & Concern"
                    value={
                      selectedSmartPlan?.RiskTolerance?.questionsAndConcerns
                    }
                  />
                </SmartPlanCard>

                <SmartPlanCard headerText={"Retirement Information"}>
                  <RowWrapper
                    title="Age to Retire"
                    value={`${selectedSmartPlan?.desiredRetirement?.ageToRetire} years`}
                  />
                  <RowWrapper
                    title="Acceptable Age"
                    value={`${selectedSmartPlan?.desiredRetirement?.acceptableAge} years`}
                  />
                  <RowWrapper
                    title="Spouse Age to Retire"
                    value={`${selectedSmartPlan?.desiredRetirement?.spouseAgeToRetire} years`}
                  />
                  <RowWrapper
                    title="Spouse Acceptable Age"
                    value={`${selectedSmartPlan?.desiredRetirement?.spouseAcceptableAge} years`}
                  />
                </SmartPlanCard>
              </CardWrapper>
            </>
          ) : (
            <p>No smart plan found</p>
          )}
        </>
      )}
    </>
  );
};

export default SmartPlanDetail;
