import React, { useState } from "react";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import InputSmall from "../Input/InputSmall";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoIosAdd } from "react-icons/io";
import ButtonSmall from "../Buttons/ButtonSmall";
import ButtonLoading from "../Buttons/ButtonLoading";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch } from "react-redux";
import { addGoalSteps } from "../../Redux/Actions";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import { FaEdit } from "react-icons/fa";

import "react-quill/dist/quill.snow.css";
import InputRequired from "../Input/InputRequired";

const StepsModal = ({ open, handleClose, data }) => {
  const [step, setStep] = useState("");
  const [btnLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(false);
  const [note, setNote] = useState(parse(data?.note || ""));
  const [isStepUpdate, setStepUpdate] = useState({
    status: false,
    targetIndex: 0,
  });

  const [stepTitle, setStepTitle] = useState("");
  const [stepDescription, setStepDescription] = useState("");

  const [formData, setFormData] = useState({
    goalType: data?.goalType,
    goalId: data?.goalId,
    steps: data?.steps?.map((item) => {
      return {
        ...item,
        step: {
          ...item.step,
          description: parse(item?.step?.description),
        },
      };
    }),
    staff: data?.staff,
    note: "",
  });

  const dispatch = useDispatch();

  function handleAddStep(e) {
    e.preventDefault();
    e.stopPropagation();

    // Check if we are updating an existing step
    if (isStepUpdate.status) {
      // Map through the steps and update the target step
      const updatedStep = formData?.steps?.map((item, index) => {
        if (index === isStepUpdate.targetIndex) {
          // Update the step with new title and description
          return {
            ...item,
            step: {
              title: stepTitle,
              description: stepDescription,
            },
          };
        } else {
          return item;
        }
      });

      // Update the state with the updated steps
      setFormData((prevValues) => ({
        ...prevValues,
        steps: updatedStep,
      }));
    } else {
      // Add a new step to the steps array
      setFormData((prevValues) => ({
        ...prevValues,
        steps: [
          ...prevValues.steps,
          {
            step: { title: stepTitle, description: stepDescription },
            status: false,
          },
        ],
      }));
    }

    // Clear the input fields
    setStepTitle("");
    setStepDescription("");

    // Reset the step update state
    setStepUpdate({ status: false, targetIndex: 0 });
  }

  function handleDeleteStep(stpIndex) {
    setFormData((prevValues) => ({
      ...prevValues,
      steps: prevValues.steps.filter((_, index) => index !== stpIndex),
    }));
  }

  function handleSelectUpdateStep(stepIndex) {
    const targetValue = formData.steps[stepIndex];
    setStepTitle(targetValue.step.title);
    setStepDescription(targetValue.step.description);
    setStepUpdate({ status: true, targetIndex: stepIndex });
  }

  function handleSubmit() {
    if (!formData.steps.length) {
      setError(true);
    } else {
      setButtonLoading(true);
      formData.note = note;
      dispatch(addGoalSteps(formData)).then(() => {
        setButtonLoading(false);
        handleClose();
      });
    }
  }

  return (
    <Modal open={open} headerText="Assign steps" onclick={handleClose}>
      {error ? (
        <p className="text-center text-[14px] md:text-[14px] text-red-500 ml-3 mt-1">
          ** Please add atleast one step **
        </p>
      ) : (
        false
      )}
      <div className="w-full">
        <label
          htmlFor="Category"
          className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
        >
          Goal Type
        </label>
        <select
          className="w-full rounded-md  border outline-none py-2 px-2"
          name="goalType"
          id="programType"
          disabled
          value={formData.goalType}
        >
          <option className="text-black" value="Financial">
            Financial
          </option>
          <option className="text-black" value="Nonfinancial">
            Non Financial
          </option>
        </select>
      </div>

      <InputWrapper>
        <InputSmall disable={true} value={data.title} label="Goal Title" />
      </InputWrapper>
      <InputWrapper>
        <div className="w-full">
          <label
            htmlFor="description"
            className="block pl-[2px] font-medium text-[14px] capitalize"
          >
            Description
          </label>
          <ReactQuill
            theme="snow"
            id="description"
            value={note}
            className="w-full py-3 rounded-md"
            onChange={setNote}
            placeholder="Write description here ..."
          />
          {/* <p className="text-red-600 mb-6">{erro}</p> */}
        </div>
      </InputWrapper>
      {formData?.steps?.length ? (
        <div className="py-3">
          {formData.steps.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-center items-start gap-3"
              >
                <div
                  key={index}
                  className="flex w-full justify-start items-start gap-3 bg-slate-50 border rounded-md px-3 py-1 my-2"
                >
                  <input
                    disabled
                    type="checkbox"
                    className="min-w-[15px] min-h-[15px] mt-1"
                    checked={item?.status}
                  />
                  <div>
                    <div className="mb-3">
                      <h3 className="border-b border-black satoshi-700">
                        {item?.step?.title}
                      </h3>
                    </div>
                    <div className="text-sm satoshi-500 description">
                      {parse(item?.step?.description)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-start items-start flex-col pr-1 min-w-max">
                  <MdDeleteForever
                    onClick={() => handleDeleteStep(index)}
                    className="text-2xl mt-2 text-red-700 cursor-pointer hover:text-red-400"
                  />

                  <FaEdit
                    className="text-xl text-green-600 mt-3 cursor-pointer hover:text-green-400"
                    onClick={() => {
                      handleSelectUpdateStep(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}

      {formData?.goalType === "Nonfinancial" && (
        <form onSubmit={handleAddStep}>
          <InputWrapper>
            <InputRequired
              name="stepTitle"
              value={stepTitle}
              label="Step Title"
              placeholder="Enter step title"
              onChange={(e) => {
                setStepTitle(e.target.value);
              }}
              required={true}
            />
          </InputWrapper>
          <InputWrapper>
            <div className="w-full">
              <label
                htmlFor="step"
                className="block pl-[2px] font-medium text-[14px] capitalize"
              >
                Step
              </label>
              <ReactQuill
                theme="snow"
                id="step"
                value={stepDescription}
                className="w-full py-1 rounded-md"
                onChange={setStepDescription}
                placeholder="Write step description here ..."
              />
            </div>
          </InputWrapper>
          <div className="flex justify-center items-center my-3">
            <ButtonIcon
              type="submit"
              customClasses="!py-2 !px-3"
              text="Add Step"
              icon={<IoIosAdd />}
            />
          </div>
        </form>
      )}

      <div className="flex justify-center items-center gap-4 mt-6 w-full">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              onClick={handleClose}
              customClasses="!bg-white !text-black"
            />
            <ButtonSmall
              type="button"
              text={"Save & Exit"}
              onClick={handleSubmit}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default StepsModal;
