import React from "react";
import Avatar from "../Avatar/Avatar";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const TableRow = ({ clientData }) => {
  const navigate = useNavigate();
  return (
    <tr
      onClick={() => {
        navigate(`/clients-detail/${clientData?._id}`);
      }}
      className="text-[13px] tableRow cursor-pointer"
    >
      <td className="flex justify-start items-center py-2 pl-2 ">
        <Avatar imageRef={clientData?.photo} />
        <div className=" ml-2 flex justify-center items-center flex-col">
          <h5 className="font-semibold text-[15px]">
            {clientData.firstName + " " + clientData.lastName}
          </h5>
          {/* <p className="text-[13px] text-[--gray] ">@ijohn_doe</p> */}
        </div>
      </td>
      <td className="text-[16px]">{clientData.email}</td>
      <td className="text-[16px]">
        {moment(parseInt(clientData.joinedAt)).format("MM-DD-YYYY")}
      </td>
      {/* <td className="uppercase text-white text-center">
        <p
          className={`${
            packageType.toLowerCase() === "gold"
              ? "bg-[--green]"
              : "bg-[--orange]"
          } px-3  rounded-full py-1 text-sm`}
        >
          {packageType}
        </p>
      </td> */}
    </tr>
  );
};

export default TableRow;
