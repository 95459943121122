import React, { useState } from "react";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import { useFormik } from "formik";
import InputRequired from "../Input/InputRequired";
import { taskSchema } from "../../Schema";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { addTask, updateTask } from "../../Redux/Actions";
import ReactQuill from "react-quill";
import parse from "html-react-parser";

const addTaskInitialState = {
  title: "",
  coachId: "",
};

const AddUpdatedTask = ({ open, handleClose, data, isEdit }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [description, setDescription] = useState(
    data?.description ? parse(data.description) : null
  );
  const [descriptionError, setDescriptionError] = useState("");
  const updatedInitialState = {
    title: data?.title,
    id: data?.id,
    coachId: "",
  };

  const dispatch = useDispatch();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: isEdit ? updatedInitialState : addTaskInitialState,
      validationSchema: taskSchema,
      onSubmit: (values, action) => {
        if (!description) {
          setDescriptionError("Description is required");
          return;
        }
        setBtnLoading(true);
        values.description = description;
        if (isEdit) {
          dispatch(updateTask(values)).then(() => {
            setBtnLoading(false);
            action.resetForm();
            handleClose();
          });
        } else {
          dispatch(addTask(values)).then(() => {
            setBtnLoading(false);
            action.resetForm();
            handleClose();
          });
        }
      },
    });
  return (
    <Modal
      open={open}
      onclick={handleClose}
      headerText={isEdit ? "Update Task" : "Add Task"}
    >
      <InputWrapper>
        <InputRequired
          name="title"
          id="title"
          placeholder="Enter task title"
          onChange={handleChange}
          onBlur={handleBlur}
          touch={touched.title}
          label="Title"
          value={values.title}
          error={errors.title}
        />
      </InputWrapper>
      <InputWrapper>
        <div className="w-full">
          <label
            htmlFor="step"
            className="block pl-[2px] font-medium text-[14px] capitalize"
          >
            Description
          </label>
          <ReactQuill
            theme="snow"
            id="step"
            value={description}
            className="w-full py-1 rounded-md"
            onChange={(e) => {
              setDescriptionError("");
              setDescription(e);
            }}
            placeholder="Write description here ..."
          />
          {descriptionError && (
            <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
              {descriptionError}
            </span>
          )}
        </div>
      </InputWrapper>

      <div className="flex justify-center items-center gap-4 mt-6 w-full">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              text="Cancel"
              onClick={handleClose}
              customClasses="!bg-white !text-black"
            />
            <ButtonSmall
              text={isEdit ? "Update" : "Add"}
              onClick={handleSubmit}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddUpdatedTask;
