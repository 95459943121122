import React, { useState } from "react";
import FormLayout from "../../Layouts/FormLayout";
import Form from "../../components/Form/Form";
import InputRequired from "../../components/Input/InputRequired";
import InputPassword from "../../components/Input/InputPassword";
import ButtonLarge from "../../components/Buttons/ButtonLarge";
import FormFooter from "../../components/Form/FormFooter";
import InputWrapper from "../../components/Wrapper/InputWrapper";
import { useFormik } from "formik";
import { coachSchema } from "../../Schema";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/Input/FileUplaod"; // Corrected "FileUplaod" to "FileUpload"
import { FaImage } from "react-icons/fa";
import { signup, uploadPhoto } from "../../Redux/Actions";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import OtpVeri from "../../components/PasswordRecoverySteps/OtpVeri";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "Coach",
};

const SignUp = () => {
  const [brandForm, setBrandForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  // const [brandName, setBrandName] = useState("");
  const [brandURL, setBrandURL] = useState("");
  const [showOtpStep, setShowOtpStep] = useState(false);
  const [otpData, setOtpData] = useState(null);
  const [customError, setCustomError] = useState({
    name: "",
    logoError: "",
    photoError: "",
    endpoint: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDropLogo = (files) => {
    customErrorHandler("logoError");
    const allowedExtensions = ["png"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setLogo(files[0]);
    } else {
      customErrorHandler(
        "logoError",
        "Invalid file type. Please upload a PNG file."
      );
    }
  };

  const onDropPicture = (files) => {
    customErrorHandler("photoError");
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setProfilePicture(files[0]);
    } else {
      customErrorHandler(
        "photoError",
        "Invalid file type. Please upload a .JPG, .JPEG or .PNG file."
      );
    }
  };

  function customErrorHandler(name, error = null) {
    setCustomError((prev) => ({
      ...prev,
      [name]: error,
    }));
  }

  function handleBrandEndpoint(e) {
    const value = e.target.value;
    if (value.includes(".")) {
      customErrorHandler("endpoint", ". is not allowed");
    } else {
      customErrorHandler("endpoint");
      setBrandURL(value);
    }
  }

  // function handleSignUp(e, preValues) {
  //   e.preventDefault();

  //   if (!brandName) {
  //     customErrorHandler("name", "Field is required");
  //     return;
  //   }
  //   if (!brandURL) {
  //     customErrorHandler("endpoint", "Field is required");
  //     return;
  //   }
  //   // if (!logo) {
  //   //   customErrorHandler("logoError", "Field is required");
  //   //   return;
  //   // }
  //   // if (!profilePicture) {
  //   //   customErrorHandler("photoError", "Field is required");
  //   //   return;
  //   // }

  //   setLoading(true);

  //   uploadPhoto({ photo: logo })
  //     .then(async (response) => {
  //       const uploadedBody = {
  //         firstName: preValues.firstName,
  //         lastName: preValues.lastName,
  //         email: preValues.email,
  //         password: preValues.password,
  //         photo: "",
  //         coachInfo: {
  //           BrandLogo: response.data.photos[0],
  //           name: brandName,
  //           endpoint: brandURL,
  //         },
  //         role: "Coach",
  //       };

  //       return uploadPhoto({ photo: profilePicture })
  //         .then((res) => {
  //           uploadedBody.photo = res.data.photos[0];
  //           return signup(uploadedBody);
  //         })
  //         .then((resp) => {
  //           setLoading(false);
  //           setShowOtpStep(true);
  //           setOtpData(resp);
  //         });
  //     })
  //     .catch((err) => {
  //       console.error("Error during signup process:", err);
  //       setLoading(false);
  //     });
  // }

  function handleSignUp(e, preValues) {
    e.preventDefault();

    // if (!brandName) {
    //   customErrorHandler("name", "Field is required");
    //   return;
    // }
    if (!brandURL) {
      customErrorHandler("endpoint", "Field is required");
      return;
    }

    setLoading(true);

    // If logo is provided, upload it. Otherwise, proceed without uploading.
    const logoUpload = logo
      ? uploadPhoto({ photo: logo })
      : Promise.resolve({ data: { photos: [""] } });

    logoUpload
      .then(async (response) => {
        const uploadedBody = {
          firstName: preValues.firstName,
          lastName: preValues.lastName,
          email: preValues.email,
          password: preValues.password,
          photo: "",
          coachInfo: {
            BrandLogo: response.data.photos[0] || "", // Empty if no logo uploaded
            // name: brandName,
            endpoint: brandURL,
          },
          role: "Coach",
        };

        // If profile picture is provided, upload it. Otherwise, proceed without uploading.
        const profilePictureUpload = profilePicture
          ? uploadPhoto({ photo: profilePicture })
          : Promise.resolve({ data: { photos: [""] } });

        return profilePictureUpload
          .then((res) => {
            uploadedBody.photo = res.data.photos[0] || ""; // Empty if no profile picture uploaded
            return signup(uploadedBody);
          })
          .then((resp) => {
            setLoading(false);
            setShowOtpStep(true);
            setOtpData(resp);
          });
      })
      .catch((err) => {
        console.error("Error during signup process:", err);
        setLoading(false);
      });
  }


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: coachSchema,
    onSubmit: () => {
      setBrandForm(true);
    },
  });

  return (
    <FormLayout>
      {showOtpStep ? (
        <OtpVeri
          stepsValues={otpData}
          onNext={() => navigate("/")}
        />
      ) : (
        <>
          {brandForm ? (
            <Form
              headerText="Brand Information"
              description="Create your account by entering your information below"
              onSubmit={handleSubmit}
              customClass={'!pb-0'}
            >
              {/* <InputWrapper customClasses={"w-full !mt-1"}>
                <InputRequired
                  label="Brand Name"
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) => {
                    customErrorHandler("name");
                    setBrandName(e.target.value);
                  }}
                  error={customError.name}
                  value={brandName}
                  touch={true}
                  placeholder="John Production"
                />
              </InputWrapper> */}
              <InputWrapper customClasses={"w-full !mt-0"}>
                <InputRequired
                  label="Brand Url"
                  type="text"
                  id="endpoint"
                  name="endpoint"
                  touch={true}
                  value={brandURL}
                  onChange={handleBrandEndpoint}
                  error={customError.endpoint}
                  placeholder="johndoeproduction"
                />
              </InputWrapper>

              {/* 
               <InputWrapper customClasses={"w-full !items-stretch"}>
                <FileUpload
                  icon={<FaImage />}
                  onDrop={onDropLogo}
                  error={customError.logoError}
                  selectedFiles={logo}
                  headerText="Drop your logo here, or"
                  description=".png files supported"
                  type="image"
                />

                <FileUpload
                  icon={<FaImage />}
                  onDrop={onDropPicture}
                  error={customError.photoError}
                  selectedFiles={profilePicture}
                  headerText="Drop your profile picture here, or"
                  description=".jpg .jpeg or .png files supported"
                  type="image"
                />
              </InputWrapper>
 */}
              <div className="mt-2 w-full">
                {loading ? (
                  <ButtonLoading />
                ) : (
                  <>
                    <ButtonLarge
                      text="Sign Up"
                      onClick={(e) => handleSignUp(e, values)}
                    />
                    <ButtonLarge
                      customClasses={"!bg-white !text-[--primary] !mt-2"}
                      text="Back"
                      onClick={() => {
                        setBrandForm(false);
                      }}
                    />
                  </>
                )}
              </div>
            </Form>
          ) : (
            <Form
              customClass={'!pb-0'}
              headerText="Create your account"
              description="Create your account by entering your information below."
            >
              <InputWrapper customClasses="w-full !mt-1">
                <InputRequired
                  label="First Name"
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  error={errors.firstName}
                  touch={touched.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="John"
                />
              </InputWrapper>
              <InputWrapper customClasses="w-full !mt-0">
                <InputRequired
                  label="Last Name"
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  error={errors.lastName}
                  touch={touched.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Doe"
                />
              </InputWrapper>
              <InputWrapper customClasses="w-full !mt-0">
                <InputRequired
                  label="Email"
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  error={errors.email}
                  touch={touched.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="johndoe@gmail.com"
                />
              </InputWrapper>
              <InputPassword
                id="password"
                name="password"
                label="Password"
                customClasses={"!mb-0"}
                onChange={handleChange}
                placeholder="Enter password"
                value={values.password}
                error={errors.password}
                onBlur={handleBlur}
                touch={touched.password}
              />
              <div className="mt-2 w-full">
                <ButtonLarge text="Next" onClick={handleSubmit} />
              </div>
            </Form>
          )}
          {!brandForm && (
            <FormFooter
              text="Already have account?"
              url="/signin"
              urltext="Sign In"
              customClass={'mt-0 sm:mt-0'}
            />
          )}
        </>
      )}
    </FormLayout>
  );
};

export default SignUp;
