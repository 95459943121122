import React from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

const MediaCardHeader = ({
  image,
  videoUrl,
  thumbnailUrl,
  text,
  onEdit,
  onDelete,
}) => {
  return (
    <>
      <div className="w-full">
        {videoUrl ? (
          <div className="w-full  overflow-hidden rounded-t-md">
            {thumbnailUrl ? (
              <img
                className="w-full h-[170px] rounded-t-md"
                src={thumbnailUrl}
                alt="card banner"
                loading="lazy"
              />
            ) : (
              <video width="100%" className="h-[180px]" controls>
                <source src={videoUrl} type="video/mp4" />
              </video>
            )}
          </div>
        ) : (
          <img
            className="w-full h-[170px] rounded-t-md"
            src={image}
            alt="card banner"
          />
        )}
      </div>
      <div className="flex justify-between items-center px-2 mt-2 relative">
        <h4 className="satoshi-900 text-base capitalize">{text}</h4>
        <div className="text-[20px] flex justify-center items-center gap-2 min-w-[80px]">
          <BiEdit onClick={onEdit} className=" text-green-600 cursor-pointer" />
          <MdDelete
            onClick={onDelete}
            className=" text-red-600 cursor-pointer"
          />
        </div>
      </div>
    </>
  );
};

export default MediaCardHeader;
