import React, { useState } from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddEventModal from "../Modal/AddEventModal";
import { useSelector } from "react-redux";
import TextModal from "../Modal/TextModal";
import useHasPermission from "../../Utils/permissionutil";
import UnavailableService from "../Message/UnavailableService";

const localizer = momentLocalizer(moment);
// Tue Apr 02 2024 00:00:00 GMT+0500 (Pakistan Standard Time)
const MyCalender = ({ serviceAvailable }) => {
  const hasPermissionToCreate = useHasPermission("Scheduling");
  const hasPermissionToManage = useHasPermission("Meeting information");

  const [modal, setModal] = useState(false);
  const [newEvent, setNewEvent] = useState({});
  const events = useSelector((state) => state.events.data);
  const [isEdit, setIsEdit] = useState(false);
  const [textModal, setTextModal] = useState(false);

  const handleSelect = ({ start, end }) => {
    console.log("STart date ==>", start);
    if (hasPermissionToCreate) {
      setNewEvent({
        startDate: start,
        endDate: end,
        title: "",
        clients: [],
        staffs: [],
        isUpdate: false,
        createdBy: "Coach",
      });
      setModal(true);
    } else {
      setTextModal(true);
    }
  };

  const updateMeeting = (event) => {
    if (hasPermissionToManage) {
      setIsEdit(true);
      setNewEvent({
        _id: event._id,
        startDate: event.start,
        endDate: event.end,
        title: event.title,
        clients: event.clients,
        staffs: event.staffs,
        isUpdate: true,
        createdBy: event.createdBy,
      });
      setModal(true);
    } else {
      setTextModal(true);
    }
  };

  const eventPropGetter = (event) => {
    let backgroundColor = event.createdBy === "Staff" ? "#90662C" : "#296B31"; // Default color
    return { style: { backgroundColor } };
  };

  return (
    <>
      {textModal && (
        <TextModal
          isPermission={true}
          open={textModal}
          handleClose={() => {
            setTextModal(false);
          }}
        />
      )}
      {serviceAvailable ? (
        <Calendar
          views={["month"]}
          selectable
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={events}
          style={{ height: "600px" }}
          onSelectEvent={updateMeeting}
          onSelectSlot={handleSelect}
          popup
          eventPropGetter={eventPropGetter}
        />
      ) : (
        <UnavailableService />
      )}

      {modal && (
        <AddEventModal
          open={modal}
          data={newEvent}
          isEdit={isEdit}
          handleClose={() => {
            setIsEdit(false);
            setModal(false);
          }}
        />
      )}
    </>
  );
};

export default MyCalender;
