const initialState = {
  data: [],
};

const goalsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_GOALS":
      return {
        ...state,
        data: payload.data,
      };
    case "UPDATE_GOAL":
      return {
        ...state,
        data: state.data.map((goal) => {
          return goal._id === payload?.data?.goal._id
            ? {
                ...goal,
                steps: payload?.data?.goal?.steps,
                staff: payload?.data?.goal?.staff,
                note: payload?.data?.goal?.note,
              }
            : goal;
        }),
      };
    default:
      return state;
  }
};

export default goalsReducer;
