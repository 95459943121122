import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { deleteEvent } from "../../Redux/Actions";
import { useDispatch } from "react-redux";

const DeleteEvent = ({ open, handleModal, data }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function handleDelete() {
    setLoading(true);
    dispatch(deleteEvent(data.id)).then(() => {
      setLoading(false);
      handleModal();
    });
  }

  return (
    <Modal headerText="Delete Event" open={open} onclick={handleModal}>
      <div className="text-center w-full">
        <h4 className="satoshi-700">Are you sure to delete this Event ? </h4>
      </div>

      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleModal}
            />
            <ButtonSmall
              onClick={handleDelete}
              type="button"
              text="Delete"
              customClasses={"!w-1/2 !border-red-600 !bg-red-600"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteEvent;
