const initialState = {
  videos: [],
  blogs: [],
};

const mediaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_MEDIA":
      return {
        ...state,
        videos: payload.videos,
        blogs: payload.blogs,
      };
    case "INSERT_VIDEO":
      return {
        ...state,
        videos: [...state.videos, payload],
      };
    case "INSERT_BLOG":
      return {
        ...state,
        blogs: [...state.blogs, payload],
      };
    case "UPDATE_BLOG": {
      console.log("paylaod", payload);
      return {
        ...state,
        blogs: state.blogs.map((blog) =>
          blog.id === payload.id ? { ...blog, ...payload } : blog
        ),
      };
    }
    case "UPDATE_VIDEOS":
      return {
        ...state,
        videos: state.videos.map((video) =>
          video.id === payload.id ? { ...video, ...payload } : video
        ),
      };
    case "DELETE_VIDEO":
      const updatedList = state.videos.filter((video) => video._id !== payload);
      return {
        ...state,
        videos: updatedList,
      };

    case "DELETE_BLOG":
      const updatedBlog = state.blogs.filter((blog) => blog._id !== payload);
      return {
        ...state,
        blogs: updatedBlog,
      };
    default:
      return state;
  }
};

export default mediaReducer;
