import React from 'react';
import default_image from '../../Assets/default-profile.png'
import { commaNumberFormat } from '../../Utils/FormatNumber';

const SmartPlanCont = React.forwardRef((props, ref) => {

    const formatAmount = (amount) => {
        // Check if the amount is a valid number or can be converted to a number
        const numericValue = parseFloat(amount);

        if (!isNaN(numericValue)) {
            return numericValue.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }

        return amount; // default value if the amount is not a valid number
    };


    const user = props?.data?.logoPageInfo || {};
    const incomeData = props?.data?.incomeData || { incomes: [] };
    const expenseData = props?.data?.expenseData || { expenses: [] };
    const goals = props?.data?.goals || [];
    const ActionPlan_and_Accounts = props?.data?.ActionPlan_and_Accounts || {};

    return (
        <div ref={ref}>
            {/* Financial Plan Section */}
            <section
                style={{
                    height: "auto",
                    paddingTop: "7rem",
                    paddingBottom: "0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "0",
                }}
            >
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: '3%' }}>
                    <img src={user?.brandLogo ? user?.brandLogo : default_image} alt="logoimg" style={{ width: '500px', height: '500px' }} />
                </div>
                <div>
                    <h1 style={{ fontSize: "27px", fontWeight: "600", color: "rgba(0, 0, 0, 0.8)" }}>
                        {user?.clientName} Financial Plan
                    </h1>
                </div>
                <div
                    style={{
                        width: "50vw",
                        marginTop: "10rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <div style={{ paddingBottom: "2.25rem" }}>
                        <h1 style={{ fontSize: "17px" }}>Prepared By: {user?.coachName}</h1>
                        <div style={{ textAlign: "center" }}>
                            <span
                                style={{
                                    color: "#3182ce",
                                    textDecoration: "underline",
                                    textAlign: "center",
                                }}
                            >
                                {user?.coachEmail}
                            </span>
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <h1 style={{ fontSize: "16px" }}>Prepared on: {user?.currentDate}</h1>
                    </div>
                </div>
            </section>

            {/* Page Break before Welcome Section */}
            {
                user !== null && (
                    <div
                        style={{
                            width: "95vw",
                            marginLeft: "auto",
                            marginRight: "auto",
                            pageBreakBefore: "always",
                            pageBreakInside: "avoid",
                            paddingTop: '50px',
                            paddingLeft: "50px",
                            paddingRight: "50px",
                        }}
                    >
                        <div style={{ marginBottom: "3rem", lineHeight: "2.25rem" }}>
                            {/* {
                                user?.welcomeText && (
                                    <h1
                                        style={{ fontSize: "27px", fontWeight: "bold", marginTop: '20px' }}
                                        dangerouslySetInnerHTML={{ __html: user.welcomeText }}
                                    />
                                )
                            } */}
                            <p style={{ fontSize: "16px" }}>
                                {user?.coachName} Financial Coaching was founded on the belief that everyone deserves a chance
                                to become financially successful, no matter their income bracket or how much money their
                                investment portfolio contains.
                                <br />
                                We would like to congratulate you on taking the first step towards a brighter financial future!
                            </p>


                        </div>
                    </div>
                )
            }


            {/* Goals Section */}
            {
                user !== null && goals.length > 0 && (
                    <div style={{
                        paddingTop: "20px",
                        marginTop: "10px", paddingLeft: "50px", paddingRight: "50px",
                    }}>
                        <h1 style={{ fontSize: "27px", fontWeight: "600", paddingBottom: '10px' }}>
                            {user?.clientName} Goals
                        </h1>
                        <p style={{ fontSize: "16px", paddingBottom: '10px' }}>
                            The table below represents our analysis of the goals you listed in the Financial Goals section.
                            Based on your goals and the info provided, we’ve created a realistic Action Plan on the next page
                            that contains recommendations to help you accomplish them.
                        </p>
                        <table style={{ width: "100%", borderCollapse: "collapse", pageBreakInside: "avoid" }}>
                            <thead style={{ fontSize: "16px" }}>
                                <tr style={{ textAlign: 'Left' }}>
                                    <th style={{ fontWeight: 'bold' }}>Financial Goal</th>
                                    <th style={{ fontWeight: 'bold' }}>Months Passed</th>
                                    <th style={{ fontWeight: 'bold' }}>Month Duration</th>
                                    <th style={{ fontWeight: 'bold' }}>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {goals?.map((goal, index) => (
                                    <tr key={goal._id} style={{ backgroundColor: index % 2 === 0 ? "#f4f5f7" : "transparent" }}>
                                        <td style={{ paddingLeft: '5px' }}>{goal.title || 'No Specific Goal'}</td>
                                        <td style={{ paddingLeft: '5px' }}>{goal.monthsPassed}</td>
                                        <td style={{ paddingLeft: '5px' }}>{goal.monthsDuration}</td>
                                        <td style={{ paddingLeft: '5px' }}>{goal.progressPercentage}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                )
            }

            {/* Smart Plan Messages Section */}
            {
                ActionPlan_and_Accounts !== null && (
                    <div
                        style={{
                            pageBreakBefore: "always",
                            pageBreakInside: "avoid",
                            paddingTop: "50px"
                        }}
                    >
                        <h1 style={{ fontSize: "27px", fontWeight: "600", paddingLeft: '50px', paddingBottom: '10px', paddingTop: '30px' }}>
                            {user?.clientName} Income Allocation
                        </h1>

                        <div style={{ width: '100%', paddingLeft: '50px', paddingRight: '50px' }}>
                            <ul style={{ listStyleType: 'disc' }} className="print-list" >
                                <div style={{ fontSize: '16px', paddingBottom: '10px', display: 'flex' }}>
                                    <span style={{ marginRight: '10px' }}>&#8226;</span>
                                    <li style={{ fontSize: '16px', }}>You Spend ${" "}{commaNumberFormat(expenseData?.TotalNeeds)} per month for all your <a href='https://client.smartfbp.com/budget'> Essential NEEDS</a>.</li>
                                </div>
                                <div style={{ fontSize: '16px', paddingBottom: '10px', display: 'flex' }}>
                                    <span style={{ marginRight: '10px' }}>&#8226;</span>
                                    <li style={{ fontSize: '16px' }}>You Spend ${" "}{commaNumberFormat(expenseData?.TotalWants)} per month for all your <a href='https://client.smartfbp.com/budget'>WANTS</a>.</li>
                                </div>
                                <div style={{ fontSize: '16px', paddingBottom: '10px', display: 'flex' }}>
                                    <span style={{ marginRight: '10px' }}>&#8226;</span>
                                    <li style={{ fontSize: '16px' }}>You Spend ${" "}{commaNumberFormat(expenseData?.TotalSavingAndInvestment)} per month for all your <a href='https://client.smartfbp.com/budget'>SAVING AND INVESTMENT</a>.</li>
                                </div>
                            </ul>
                            <ul style={{ listStyleType: 'disc' }}>
                                {Array.isArray(ActionPlan_and_Accounts?.getSmartPlanMessages) &&
                                    ActionPlan_and_Accounts.getSmartPlanMessages.map((message, index) => (
                                        <div
                                            key={index}
                                            style={{ fontSize: '16px', paddingBottom: '10px', display: 'flex' }}>
                                            <span style={{ marginRight: '10px' }}>&#8226;</span>
                                            <li style={{ fontSize: '16px' }}>
                                                {message}
                                            </li>
                                        </div>
                                    ))
                                }
                            </ul>

                        </div>
                    </div>
                )
            }

            {/* Action Plan and Accounts Section */}
            <div
                style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "50px",
                    pageBreakBefore: "always",
                    pageBreakInside: "avoid",
                }}
            >
                <h1 style={{ fontSize: "27px", fontWeight: "600", marginTop: '30px' }}>
                    {user?.clientName} Budget Details
                </h1>
                <p style={{ fontSize: '16px' }}>
                    Here show the budget plan for the client and the total income and expense.

                </p>

                <h1 style={{ fontSize: '22px', fontWeight: '600' }}>
                    Income and Expense
                </h1>

                {/* Income Section */}
                {/* <div style={{ marginTop: '30px', paddingRight: "60px", pageBreakBefore: "avoid" }}>
                    <h1 style={{ fontSize: "27px", fontWeight: "600" }}>Income </h1>
                    <p style={{ fontSize: "16px" }}>
                        The table below represents your income information.
                    </p>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: 'bold', textAlign: "left" }}>Income Source</th>
                                <th style={{ fontWeight: 'bold', textAlign: "left", paddingLeft:"100px"}}>Amount</th>
                                <th style={{ fontWeight: 'bold', textAlign: "right" }}>Duration</th>
                            </tr>
                        </thead>
                        <tbody style={{ width: "100%" }}>
                            {incomeData?.incomes?.map((income, index) => (
                                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f4f5f7" : "transparent" }}>
                                    <td style={{ paddingLeft: "5px" }}>{income.name}</td>
                                    <td style={{ textAlign: "left",paddingLeft:"100px" }}>${" "}{formatAmount(income.amount)}</td>
                                    <td style={{ textAlign: "right", paddingLeft: "5px" }}>{income.duration}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between", borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        <span style={{ fontWeight: 'bold' }}>Total Income</span>
                        <span style={{ fontWeight: 'bold', paddingLeft: '10px' }} colSpan="2">${" "}{formatAmount(incomeData?.totalIncome)}</span>
                    </div>
                </div> */}


                {/* Expense Section */}

                <div style={{ paddingRight: "50px" }}>
                    <h1 style={{ fontSize: "27px", fontWeight: "600" }}>Income </h1>
                    <p style={{ fontSize: "16px" }}>
                        The table below represents your income information.
                    </p>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr style={{ width: "100%", justifyContent: "space-between" }}>
                                <th style={{ fontWeight: 'bold', textAlign: "left" }}>Income Source</th>
                                <th style={{ fontWeight: 'bold', textAlign: "left", paddingLeft: "100px" }}>Amount</th>
                                <th style={{ fontWeight: 'bold', textAlign: "right" }}>Duration</th>
                            </tr>
                            
                        </thead>
                        <tbody style={{ width: "100%" }}>
                            {incomeData?.incomes?.map((income, index) => (
                                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f4f5f7" : "transparent" }}>
                                    <td style={{ width:"50%" }}>{income.name}</td>
                                    <td style={{ textAlign: "left", paddingLeft: "100px" }}>${" "}{formatAmount(income.amount)}</td>
                                    <td style={{ textAlign: "right", paddingLeft: "5px" }}>{income.duration}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between", borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        <span style={{ fontWeight: 'bold' }}>Total Income</span>
                        <span style={{ fontWeight: 'bold', paddingLeft: '10px' }} colSpan="2">${" "}{formatAmount(incomeData?.totalIncome)}</span>
                    </div>
                    <h1 style={{ fontSize: "27px", fontWeight: "600",marginTop:"10px" }}>Expense </h1>
                    <p style={{ fontSize: "16px" }}>
                        The table below represents your expense information.
                    </p>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr style={{ width: "100%", justifyContent: "space-between" }}>
                                <th style={{ fontWeight: 'bold', fontSize: '16px', textAlign: "left" }}>Expense Type</th>
                                <th style={{ fontWeight: 'bold', fontSize: '16px', textAlign: "left", paddingLeft: "100px" }}>Category Name</th>
                                <th style={{ fontWeight: 'bold', fontSize: '16px', textAlign: "right" }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody style={{ width: "100%" }}>
                            {expenseData?.expenses.map((expenseCategory, index) => (
                                <React.Fragment key={index} style={{ marginBottom: "2rem", pageBreakInside: "avoid" }}>
                                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                                        {expenseCategory.subcategoryName}
                                    </h3>

                                    {expenseCategory.data?.map((expense, i) => (
                                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "#f4f5f7" : "transparent" }}>
                                            <td style={{ width: "50%" }}>{expense.purposeName}</td>
                                            <td style={{ textAlign: "left", paddingLeft: "100px" }}>{expense.categoryName}</td>
                                            <td style={{ textAlign: "right" }}>${" "}{formatAmount(expense.amount)}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between", borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        <span style={{ fontWeight: 'bold' }}>Total Expense</span>
                        <span style={{ fontWeight: 'bold', paddingLeft: '10px' }} colSpan="2">${" "}{formatAmount(expenseData?.totalExpense)}</span>
                    </div>
                </div>

            </div>

            {/* Financial Position */}
            {
                ActionPlan_and_Accounts !== null && (
                    <div style={{
                        pageBreakBefore: "always",
                        pageBreakInside: "avoid",
                        paddingTop: "50px",
                        paddingLeft: '50px', paddingRight: '50px', marginTop: '30px'
                    }}>
                        <h1 style={{ fontSize: "27px", fontWeight: "600" }}>
                            {user?.clientName} Financial Position
                        </h1>
                        <p style={{ fontSize: '16px', paddingBottom: '0.75rem' }}>
                            A Balance Sheet is a statement at a moment in time of your financial position that shows what you own (Assets), what you owe (Liabilities) and what is left over (Net Worth). You’ll also see your Household Net Worth (Total Assets minus Total Liabilities). Additionally, you’ll see how your Total Assets are allocated across each category. This is useful in seeing trends over time, as well as the composition of your Balance Sheet.
                        </p>
                        <div style={{ textAlign: 'center', paddingTop: '1.25rem' }}>
                            <h1 style={{ fontSize: '1.125rem', fontWeight: 'bold' }}>
                                Statement of Financial Position
                            </h1>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* Assets Section */}
                            <div style={{ width: '100%', marginTop: '1.25rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    <h4 style={{ fontWeight: 'bold' }}>Assets</h4>
                                    <h4>% of Total Assets</h4>
                                </div>

                                {/* Current Assets */}
                                <div>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                                        <tbody>
                                            {/* Current Assets */}
                                            <tr>
                                                <th style={{ textAlign: 'left', fontWeight: 'bold' }}>Current Assets</th>
                                            </tr>
                                            {ActionPlan_and_Accounts?.getAccounts
                                                ?.filter((amount) => amount.type !== "Investment Accounts" && amount.type !== "Retirement Accounts")
                                                .map((amount, index) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: 'left' }}>{amount.type}</td>
                                                        <td style={{ textAlign: 'right' }}>${" "}{formatAmount(amount.balance)}</td>
                                                        <td style={{ textAlign: 'right' }}>{formatAmount(amount.percentageLabel)}{" "}%</td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                                    {ActionPlan_and_Accounts?.getTotals?.totalCurrentAssets?.label}
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    ${" "}{formatAmount(ActionPlan_and_Accounts?.getTotals?.totalCurrentAssets?.value)}
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {formatAmount(ActionPlan_and_Accounts?.getTotals?.totalCurrentAssets?.percentageLabel)}{" "}
                                                    %
                                                </td>
                                            </tr>

                                            {/* Investment Assets */}
                                            <React.Fragment>
                                                <tr>
                                                    <th colSpan="3" style={{ textAlign: 'left', fontWeight: 'bold', paddingTop: '0.625rem' }}>
                                                        Investment Assets
                                                    </th>
                                                </tr>
                                                {ActionPlan_and_Accounts?.getAccounts
                                                    ?.filter((amount) => amount.type === "Investment Accounts" || amount.type === "Retirement Accounts")
                                                    .map((amount, index) => (
                                                        <tr key={index}>
                                                            <td style={{ textAlign: 'left' }}>{amount.type}</td>
                                                            <td style={{ textAlign: 'right' }}>${" "}{formatAmount(amount.balance)}</td>
                                                            <td style={{ textAlign: 'right' }}>{formatAmount(amount.percentageLabel)}{" "}%</td>
                                                        </tr>
                                                    ))}
                                                <tr>
                                                    <td style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                                        {ActionPlan_and_Accounts?.getTotals?.totalInvestmentAssets?.label}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        ${" "}{formatAmount(ActionPlan_and_Accounts?.getTotals?.totalInvestmentAssets?.value)}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {formatAmount(ActionPlan_and_Accounts?.getTotals?.totalInvestmentAssets?.percentageLabel)}{" "}
                                                        %
                                                    </td>
                                                </tr>
                                            </React.Fragment>

                                            {/* Personal Property Assets */}
                                            <React.Fragment>
                                                <tr>
                                                    <th colSpan="3" style={{ textAlign: 'left', fontWeight: 'bold', paddingTop: '0.625rem' }}>
                                                        Personal Property Assets
                                                    </th>
                                                </tr>
                                                {ActionPlan_and_Accounts?.getAssets?.map((amount, index) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: 'left' }}>{amount.name}</td>
                                                        <td style={{ textAlign: 'right' }}>${" "}{formatAmount(amount.value)}</td>
                                                        <td style={{ textAlign: 'right' }}>{formatAmount(amount.percentageLabel)}{" "}%</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                                        {ActionPlan_and_Accounts?.getTotals?.totalPersonalProperty?.label}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        ${" "}{formatAmount(ActionPlan_and_Accounts?.getTotals?.totalPersonalProperty?.value)}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {formatAmount(ActionPlan_and_Accounts?.getTotals?.totalPersonalProperty?.percentageLabel)}{" "}
                                                        %
                                                    </td>
                                                </tr>
                                            </React.Fragment>

                                        </tbody>
                                    </table>
                                    {/* Total Assets */}
                                    <div style={{ fontWeight: 'bold', display: "flex", justifyContent: "space-between", borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                                        <td style={{ fontWeight: 'bold' }}>Total Assets</td>
                                        <td style={{ fontWeight: 'bold' }}>
                                            ${" "}{formatAmount(ActionPlan_and_Accounts?.getTotals?.totalAllAssets)}
                                        </td>
                                    </div>
                                </div>
                            </div>

                            {/* Liabilities and Net Worth Section */}
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ width: '100%', marginTop: '1.25rem' }}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <h4 style={{ fontWeight: 'bold' }}>Liabilities</h4>
                                        <h4>% of Total Liabilities</h4>
                                    </div>

                                    <div>
                                        {/* Current Liabilities */}
                                        <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'left', fontWeight: 'bold' }}>Debt</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ width: '100%' }}>
                                                {
                                                    ActionPlan_and_Accounts?.debts?.map((debt, index) => (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                                                    {debt.debtType}
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    ${" "}{formatAmount(debt.balance)}
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    {formatAmount(debt.percentageLabel)}{" "}%
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Total Liabilities */}
                                    <div style={{ marginTop: '3px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span style={{ fontWeight: 'bold' }}>Total Liabilities</span>
                                        <span style={{ fontWeight: 'bold' }}>${" "}{formatAmount(ActionPlan_and_Accounts?.getTotalLiabilities)}</span>
                                    </div>
                                </div>

                                {/* Total Net Worth */}
                                <div>
                                    <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr style={{ width: '100%', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid black', borderTop: '1px solid black' }}>
                                                <th style={{ fontWeight: 'bold' }}>Total Net Worth</th>
                                                <th style={{ fontWeight: 'bold' }}>${" "}{formatAmount(ActionPlan_and_Accounts?.totalNetWorth)}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div >
    )
});

export default SmartPlanCont;
