import React from "react";
import Modal from "./Modal";
import ButtonSmall from "../Buttons/ButtonSmall";
import { Link } from "react-router-dom";

const TextModal = ({
  open,
  handleClose,
  description,
  isPermission = false,
}) => {
  return (
    <Modal open={open} onclick={handleClose} headerText="Thank You">
      <div className="w-full px-2 pb-3">
        {isPermission ? (
          <p>
            Your package doesnot include this service. If you want to unable
            this service you can upgrade your package from settings->Upgrade
            Package or{" "}
            <Link
              to="/upgrade-package"
              className="text-blue-500 underline satoshi-500"
            >
              click here to upgrade
            </Link>
          </p>
        ) : (
          <p>{description}</p>
        )}
      </div>
      <div className="flex justify-center items-center gap-4 mt-6 w-full">
        <ButtonSmall
          text="Close"
          onClick={handleClose}
          customClasses="!bg-red-600 !border-red-600"
        />
      </div>
    </Modal>
  );
};

export default TextModal;
