import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
import RowWrapper from "../../components/Wrapper/RowWrapper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import AssignProgramModal from "../../components/Modal/AssignProgramModal";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import DeleteProgramModal from "../../components/Modal/DeleteProgramModal";
import { isEmpty } from "lodash";
import { getProgramData } from "../../Redux/Actions";
import EditProgramModal from "../../components/Modal/EditProgramModal";
// import MediaCard from "../../components/Card/MediaCard";

const ProgramDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const programData = useSelector((state) => state.program.data);
  const [assignProgram, setAssignProgram] = useState({
    modal: false,
    programId: null,
  });

  const [deleteProgram, setDeleteProgram] = useState({
    modal: false,
    programId: null,
  });

  const [editProgram, setEditProgram] = useState({
    modal: false,
    programId: null,
  });

  function closeAssignModal() {
    setAssignProgram({
      modal: false,
      programId: null,
    });
  }

  function closeDeleteModal() {
    setDeleteProgram({
      modal: false,
      programId: null,
    });
  }
  function closeEditModal() {
    setEditProgram({
      modal: false,
      programId: null,
    });
  }

  useEffect(() => {
    if (isEmpty(programData)) {
      setLoading(true);
      dispatch(getProgramData())
        .then((res) => {
          const targetProgram = res?.data?.find((item) => item._id === id);
          if (!targetProgram) {
            navigate("/not-found");
          }
          setSelectedProgram(targetProgram);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          navigate("/programs");
        });
    } else {
      setLoading(true);
      const targetProgram = programData.find((item) => item._id === id);
      setSelectedProgram(targetProgram);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);

  return (
    <>
      <HeaderData text="Course Detail">
        {selectedProgram ? (
          <>
            <ButtonSmall
              onClick={() => {
                setEditProgram({
                  modal: true,
                  programId: selectedProgram._id,
                });
              }}
              text="Edit"
            />
            <ButtonSmall
              onClick={() => {
                setDeleteProgram({
                  modal: true,
                  programId: selectedProgram._id,
                });
              }}
              customClasses={"!border-red-600 !bg-red-600"}
              text="Delete"
            />
          </>
        ) : null}
      </HeaderData>
      {/* {selectedProgram ? (
          <ButtonSmall
            onClick={() => {
              setAssignProgram({
                programId: selectedProgram._id,
                modal: true,
              });
            }}
            text={"Assign Program"}
          />
        ) : null}
      </HeaderData> */}
      {loading ? (
        <Loader />
      ) : selectedProgram ? (
        <>
          <div className="w-[95%] mt-5 bg-white">
            <div className="w-full flex justify-between items-stretch gap-3 flex-wrap">
              <div className="flex-grow basis-[330px]">
                <div className="w-full max-w-[450px] h-[250px]">
                  <img
                    className="w-full h-full"
                    src={
                      process.env.REACT_APP_IMAGE_URL + selectedProgram.photo
                    }
                    alt="Course banner"
                  />
                </div>
              </div>

              <div className="flex-grow basis-[300px] flex justify-start items-start flex-col py-4 px-4">
                <h1 className="satoshi-700">{selectedProgram.title}</h1>
                <RowWrapper
                  title={"Videos Included"}
                  value={`${selectedProgram.media?.videos?.length || 0} videos`}
                />
                <RowWrapper
                  title={"Blogs Included"}
                  value={`${selectedProgram.media?.blogs?.length || 0} blogs`}
                />

                <RowWrapper
                  customClasses={"!text-green-500 satoshi-900"}
                  title={"Course Type"}
                  value={selectedProgram?.programType}
                />
                <RowWrapper
                  customClasses={"!text-green-500 satoshi-900"}
                  title={"Course Price"}
                  value={
                    selectedProgram?.programType === "Sell"
                      ? `$ ${selectedProgram?.price}`
                      : "Free"
                  }
                />

                <RowWrapper
                  title={"Created At"}
                  value={moment(parseInt(selectedProgram.createdAt)).format(
                    "DD MMM, YYYY"
                  )}
                />
              </div>
            </div>
            <div className="w-full mt-2 border-t pt-4 px-5">
              <h1 className="satoshi-700 mb-2 text-[14px] md:text-[16px] xl:text-[18px]">
                Course Description
              </h1>
              <div className="w-full max-h-max satoshi-500 description">
                {parse(parse(selectedProgram.description))}
              </div>
            </div>
            {selectedProgram.media?.videos?.length ? (
              <div className="w-full mt-12 pl-4 pb-4">
                <h1 className="satoshi-700 mb-2 text-[14px] md:text-[16px] xl:text-[18px] border-b">
                  Course Videos
                </h1>
                <div className="w-full flex justify-start items-start gap-6 px-4 flex-wrap mt-4">
                  {selectedProgram.media?.videos?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-[250px] flex justify-start items-start flex-col mediaCardShadow border-b"
                      >
                        <div className="w-full">
                          <video className="h-[143px] w-full" controls>
                            <source
                              src={process.env.REACT_APP_IMAGE_URL + item.video}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>

                          <div className="flex justify-between items-center px-2 mt-2 relative">
                            <h4 className="satoshi-700 text-[14px] md:text-[16px] capitalize">
                              {item.title}
                            </h4>
                          </div>

                          <div className="w-full text-right pb-2 pr-4">
                            <Link
                              to={`/media-video-detail/${item._id}`}
                              className="text-blue-600 text-[13px] satoshi-500"
                            >
                              View Detail
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {selectedProgram.media?.blogs?.length ? (
              <div className="w-full mt-12 pl-4 pb-4">
                <h1 className="satoshi-700 mb-2 text-[14px] md:text-[16px] xl:text-[18px] border-b">
                  Course Blogs
                </h1>
                <div className="w-full flex justify-start items-start gap-6 px-4 flex-wrap mt-4">
                  {selectedProgram.media?.blogs?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-[250px] flex justify-start items-start flex-col mediaCardShadow border-b"
                      >
                        <div className="w-full">
                          <img
                            className="h-[145px] w-full"
                            src={process.env.REACT_APP_IMAGE_URL + item.photo}
                            alt="Blog cover"
                          />

                          <div className="flex justify-between items-center px-2 mt-2 relative">
                            <h4 className="satoshi-700 text-[14px] md:text-[16px] capitalize">
                              {item.title}
                            </h4>
                          </div>

                          <div className="w-full text-right pb-2 pr-4">
                            <Link
                              to={`/media-blog-detail/${item._id}`}
                              className="text-blue-600 text-[13px] satoshi-500"
                            >
                              View Detail
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            <div className="mt-3 mb-2 w-1/2 mx-auto flex justify-center items-center">
              <ButtonSmall
                onClick={() => {
                  navigate(-1);
                }}
                text={"Go Back to Courses"}
              />
              {/* <ButtonSmall
                onClick={() => {
                  setDeleteProgram({
                    modal: true,
                    programId: selectedProgram._id,
                  });
                }}
                customClasses={"!border-red-600 !bg-red-600"}
                text="Delete"
              /> */}
            </div>
          </div>
        </>
      ) : (
        <p>No program found</p>
      )}

      {deleteProgram.modal ? (
        <DeleteProgramModal
          open={deleteProgram.modal}
          handleClose={closeDeleteModal}
          data={deleteProgram.programId}
        />
      ) : null}
      {assignProgram.modal ? (
        <AssignProgramModal
          open={assignProgram.modal}
          handleClose={closeAssignModal}
          data={assignProgram}
        />
      ) : null}

      {/* Edit Program */}
      {editProgram.modal ? (
        <EditProgramModal
          // open={editProgram.modal}
          handleClose={closeEditModal}
          progData={programData?.find((item) => item._id === editProgram.programId)}
        />
      ) : null}
    </>
  );
};

export default ProgramDetail;
