import moment from "moment";

export function formatDateArray(data) {
  let formatedEvents = data;
  if (data.length) {
    formatedEvents = data.map((eventData) => {
      return {
        clients: eventData.clients,
        _id: eventData._id,
        title: eventData.title,
        start: moment(eventData.startDate).format(
          "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
        ),
        end: moment(eventData.endDate).format(
          "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
        ),
        staffs: eventData.staffs,
        id: eventData.id,
        createdBy: eventData?.createdBy,
      };
    });
  }

  return formatedEvents;
}

export function formatDateSingle(data) {
  return {
    clients: data.clients,
    _id: data._id,
    title: data.title,
    start: moment(data.startDate).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"),
    end: moment(data.endDate).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"),
    staffs: data?.staffs,
    clients: data?.clients,
    id: data.id,
    createdBy: data?.createdBy,
  };
}

export function calculateGoalPercentage(data) {
  if (data?.goalType === "Financial") {
    const totalAmount = data?.amount;
    const savedAmount = data?.savedAmount;
    const percentage = (savedAmount / totalAmount) * 100;
    return percentage.toFixed(2);
  } else {
    const totalSteps = data?.steps?.length;
    const completedSteps = data?.stepsCompleted;
    const percentage = (completedSteps / totalSteps) * 100;
    return isNaN(percentage) ? "0.00" : percentage.toFixed(2);
  }
}
