import React from "react";

const ButtonIcon = ({ icon, text, type, onclick, customClasses }) => {
  return (
    <div>
      <button
        onClick={onclick}
        type={type || "button"}
        className={`px-2 sm:px-4 md:px-7 py-3 text-[12px] sm:text-[14px] md:text-[16px] flex justify-center items-center border rounded-lg gap-2 md:gap-3 text-white bg-[--primary] ${customClasses}`}
      >
        {icon}
        <span className="text-[9px] sm:text-[12px] capitalize">{text}</span>
      </button>
    </div>
  );
};

export default ButtonIcon;
