import React, { useEffect, useState } from "react";
import Loader from "../../components/Loading/Loader";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import TaskCard from "../../components/Card/TaskCard";
import AddUpdatedTask from "../../components/Modal/AddUpdatedTask";
import DeleteTaskModal from "../../components/Modal/DeleteTaskModal";
import AssignTaskModal from "../../components/Modal/AssignTaskModal";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { fetchTasks } from "../../Redux/Actions";
import TaskDetailModal from "../../components/Modal/TaskDetailModal";
import AddTaskNote from "../../components/Modal/AddTaskNote";
import useHasPermission from "../../Utils/permissionutil";
import UnavailableService from "../../components/Message/UnavailableService";
let Once = true;

const Tasks = () => {
  const hasPermission = useHasPermission("Tasks");

  const [loading, setLoading] = useState(false);
  const [serviceAvailable, setServiceAvailable] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteTask, setDeleteTask] = useState({
    modalOpen: false,
    taskId: null,
  });

  const [associateModal, setAssociateModal] = useState({
    modalOpen: false,
    taskId: null,
    taskDetail: null,
  });

  const [taskDetail, setTaskDetail] = useState({
    modal: false,
    data: null,
  });

  const [reOpenTaskModal, setReopenTaskModal] = useState({
    modal: false,
    method: null,
    note: null,
    staffId: null,
    taskId: null,
  });

  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.data);

  function toggleModal() {
    setAddModal(!addModal);
  }

  function handleCloseModal() {
    setEditData(null);
    setIsEdit(false);
    toggleModal();
  }

  function handleEditModalOpen(taskData) {
    setEditData(taskData);
    setIsEdit(true);
    toggleModal();
  }

  function closeDeleteModal() {
    setDeleteTask({
      modalOpen: false,
      taskId: null,
    });
  }

  function closeAssociateModal() {
    setAssociateModal({
      modalOpen: false,
      taskId: null,
      taskDetail: null,
    });
  }

  function handleViewTaskDetail(status = false, taskDetail = null) {
    setTaskDetail({
      modal: status,
      data: taskDetail,
    });
  }

  function handleReopenTaskModal(taskId, staff, method) {
    setReopenTaskModal({
      modal: true,
      method,
      note: null,
      staffId: staff,
      taskId: taskId,
    });

    handleViewTaskDetail();
  }

  function handleCloseReopenModal() {
    setReopenTaskModal({
      modal: false,
      method: null,
      note: null,
      staffId: null,
      taskId: null,
    });
  }

  useEffect(() => {
    if (Once) {
      if (hasPermission) {
        Once = false;
        if (isEmpty(tasks)) {
          setLoading(true);

          dispatch(fetchTasks())
            .then((res) => {
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              console.error(err);
            });
        }
      } else {
        setServiceAvailable(false);
      }
    }
  }, []);

  return (
    <>
      {addModal ? (
        <AddUpdatedTask
          open={addModal}
          handleClose={handleCloseModal}
          data={editData}
          isEdit={isEdit}
        />
      ) : null}
      {deleteTask.modalOpen ? (
        <DeleteTaskModal
          open={deleteTask.modalOpen}
          handleClose={closeDeleteModal}
          data={deleteTask.taskId}
        />
      ) : null}
      {associateModal.modalOpen ? (
        <AssignTaskModal
          open={associateModal.modalOpen}
          handleClose={closeAssociateModal}
          data={associateModal}
        />
      ) : null}
      {taskDetail.modal && (
        <TaskDetailModal
          open={taskDetail.modal}
          data={taskDetail.data}
          handleClose={() => {
            handleViewTaskDetail(false, null);
          }}
          handleReopen={handleReopenTaskModal}
        />
      )}

      {reOpenTaskModal.modal && (
        <AddTaskNote
          open={reOpenTaskModal.modal}
          handleModal={handleCloseReopenModal}
          data={reOpenTaskModal}
        />
      )}

      {loading ? (
        <Loader />
      ) : serviceAvailable ? (
        <>
          <HeaderData text="Tasks">
            {serviceAvailable ? (
              <>
                <ButtonIcon
                  onclick={toggleModal}
                  icon={<IoMdAdd />}
                  text="Add Task"
                />
              </>
            ) : (
              <p>Please upgrade package for this service</p>
            )}
          </HeaderData>
          {tasks.length ? (
            <div className="w-full flex justify-start items-stretch flex-wrap gap-3 py-5">
              {tasks.map((task, index) => {
                return (
                  <TaskCard
                    key={index}
                    title={task.title}
                    description={task.description}
                    onDelete={() => {
                      setDeleteTask({
                        modalOpen: true,
                        taskId: task.id,
                        taskDetail: task,
                      });
                    }}
                    onEdit={() => {
                      handleEditModalOpen({
                        title: task.title,
                        description: task.description,
                        id: task.id,
                      });
                    }}
                    onAssign={() => {
                      setAssociateModal({
                        modalOpen: true,
                        taskId: task.id,
                        taskDetail: task,
                      });
                    }}
                    onViewDetail={() => {
                      handleViewTaskDetail(true, task);
                    }}
                    onReopenTask={handleReopenTaskModal}
                  />
                );
              })}
            </div>
          ) : (
            <p>You don't have any task. Please add tasks</p>
          )}
        </>
      ) : (
        <UnavailableService />
      )}
    </>
  );
};

export default Tasks;
