import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import PackageCard from "../../components/Card/PackageCard";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { fetchSelfPackages } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import PackageModal from "../../components/Modal/PackageModal";
import DeletePackageModal from "../../components/Modal/DeletePackageModal";
import UnavailableService from "../../components/Message/UnavailableService";
import AssignPackageModal from "../../components/Modal/AssignPackageModal";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
let Once = true;
const Packages = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deletedPackage, setDeletedPackage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [assignModal, setAssignModal] = useState({
    modal: false,
    data: null,
  });
  const packagesData = useSelector((state) => state?.packages?.selfPackages);
  const [packageService, setPackageService] = useState(
    useSelector((state) => state?.services?.packageService)
  );

  const [activePackage, setActivePackage] = useState(null);

  function getPackages() {
    if (isEmpty(packagesData)) {
      setLoading(true);
      dispatch(fetchSelfPackages())
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.message === "Something went wrong") {
            setPackageService(true);
          } else {
            setPackageService(false);
          }
        });
    }
  }

  function handleModal() {
    setOpenModal(!openModal);
  }

  function handleEdit(packageInfo) {
    setIsEdit(true);
    setEditData(packageInfo);
    handleModal();
  }

  function handleDeleteClose() {
    setDeletedPackage("");
    setDeleteModal(false);
  }

  function closeModal() {
    setIsEdit(false);
    setEditData(null);
    handleModal();
  }

  function openAssignModal(data) {
    setAssignModal({
      data: data,
      modal: true,
    });
  }

  function closeAssignModal() {
    setAssignModal({
      data: null,
      modal: false,
    });
  }

  useEffect(() => {
    if (query.size > 0) {
      setActivePackage(query?.get("_id"));
    }
    if (Once) {
      Once = false;
      getPackages();
    }
  });
  return (
    <>
      <HeaderData text="Packages">
        <ButtonIcon
          onclick={closeModal}
          icon={<IoMdAdd />}
          text="Add a package"
        />
      </HeaderData>
      {loading ? (
        <Loader />
      ) : (
        <>
          {openModal && (
            <PackageModal
              open={openModal}
              handleClose={closeModal}
              isEdit={isEdit}
              dataPackage={editData ? editData : null}
            />
          )}
          {deleteModal && (
            <DeletePackageModal
              open={deleteModal}
              handleClose={handleDeleteClose}
              data={deletedPackage}
            />
          )}

          {assignModal?.modal && (
            <AssignPackageModal
              data={assignModal?.data}
              open={assignModal?.modal}
              handleClose={closeAssignModal}
            />
          )}

          {packageService ? (
            <>
              {packagesData?.length ? (
                <CardWrapper extraClasses={"!items-stretch !justify-start"}>
                  {packagesData?.map((packageInfo, index) => {
                    return (
                      <PackageCard
                        active={packageInfo._id === activePackage}
                        key={index}
                        packageName={packageInfo?.name}
                        description={packageInfo?.description}
                        price={
                          packageInfo?.packageType === "Sell"
                            ? `$ ${packageInfo?.amount}`
                            : "Free"
                        }
                        subscriptionTime={
                          packageInfo?.duration?.durationType.toLowerCase() ===
                          "year"
                            ? "1 Year"
                            : `${packageInfo?.duration?.duration} month`
                        }
                        susbcriptionDetail={packageInfo?.included}
                        self={true}
                        onEdit={() => {
                          handleEdit(packageInfo);
                        }}
                        onDelete={() => {
                          setDeletedPackage(packageInfo?._id);
                          setDeleteModal(true);
                        }}
                        onAssign={() => {
                          openAssignModal(packageInfo);
                        }}
                      />
                    );
                  })}
                </CardWrapper>
              ) : null}
            </>
          ) : (
            <UnavailableService />
          )}
        </>
      )}
    </>
  );
};

export default Packages;
