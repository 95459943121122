import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Avatar from "../Avatar/Avatar";
import moment from "moment";
import { changeStaffStatus } from "../../Redux/Actions";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Link } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";

const StaffTableRow = ({ staff, onDeleteStaff }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();

  function handleStaffStatus(id) {
    setBtnLoading(true);
    dispatch(changeStaffStatus(id)).then(() => {
      setBtnLoading(false);
    });
  }
  return (
    <tr className="border-b">
      <td>
        <Link
          to={`/staffs-detail/${staff?._id}`}
          className="flex justify-start items-center py-2 pl-4"
        >
          <Avatar imageRef={staff?.photo} />
          <div className=" ml-2 flex justify-center items-start flex-col">
            <h5 className="text-[14px] min-w-max pr-2">
              {staff?.firstName + " " + staff.lastName}
            </h5>
          </div>
        </Link>
      </td>
      <td
        onClick={(e) => {
          e.target.classList.toggle("whitespace-nowrap");
        }}
        className=" px-3 py-2 text-sm pr-8  max-w-[180px] xl:max-w-max whitespace-nowrap text-ellipsis "
      >
        {staff?.email}
      </td>
      <td className="text-[14px] text-left px-3 min-w-[130px]">
        {moment(parseInt(staff.joinedAt)).format("DD-MMM-YYYY")}
      </td>
      <td
        onClick={(e) => {
          e.target.classList.toggle("whitespace-nowrap");
        }}
        className=" px-3 py-2 text-sm pr-8 max-w-[180px] whitespace-nowrap text-ellipsis "
      >
        {staff?.staffInfo?.phone ? staff?.staffInfo?.phone : "N/A"}
      </td>
      <td className="min-w-[100px] px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] pl-4 underline text-blue-400">
        <Link to={`/staffs-detail/${staff._id}`}>View Detail</Link>
      </td>
      <td>
        {btnLoading ? (
          <ButtonLoading
            customClasses={"!bg-red-700 !py-1 !px-0 !w-[50px]"}
            spinOnly={true}
          />
        ) : (
          <div className="flex justify-center items-stretch gap-3">
            <button
              onClick={() => {
                handleStaffStatus(staff._id);
              }}
              className="bg-red-700 text-white rounded-md py-1 px-2 satoshi-500 text-[14px]"
            >
              {staff?.blockactive ? "Block" : "Unblock"}
            </button>
            <div
              onClick={onDeleteStaff}
              className="cursor-pointer border border-red-700 text-red-700 rounded-md px-2 py-1 transition-all ease-in-out duration-500 hover:bg-red-700 hover:text-white"
            >
              <MdDeleteForever className="text-base md:text-lg lg:text-xl xl:text-2xl" />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default StaffTableRow;
