import React, { useEffect, useState } from "react";
import successIcon from "../../Assets/success.png";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import { useParams } from "react-router-dom";
import { subscribePackage } from "../../Redux/Actions";
let Once = true;

const SuccessPayment = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const sessionId = localStorage.getItem("sessionId");

  useEffect(() => {
    if (Once) {
      Once = false;
      function handleUpgrade() {
        setLoading(true);
        subscribePackage({ packageId: id, sessionId: sessionId }).then(() => {
          setLoading(false);
        });
      }
      handleUpgrade();
    }
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-[--bg-yellow-light]">
      <div className="py-3 px-4 mediaCardShadowm rounded-md bg-white flex justify-center items-center flex-col w-[90%] md:w-[60%] lg:w-[50%] max-w-[800px]">
        <div className=" bg-white px-3 py-6 sm:px-6 rounded-lg flex justify-center items-center flex-col">
          <div className=" w-[120px] h-[120px] sm:w-[160px] sm:h-[160px] md:w-[200px] md:h-[200px]">
            <img className="w-full h-full" src={successIcon} alt="tick here" />
          </div>
          <p className="mt-8 text-[--blue] text-center poppin-600 text-[14px] sm:text-[16px]">
            We recieved your payment. Please click below to confirm your
            package.
          </p>
          <div className="mx-auto px-3 mt-3 max-w-[500px]">
            {loading ? (
              <ButtonLoading />
            ) : (
              <ButtonSmall
                onClick={() => {
                  window.location.href = "/";
                }}
                text="Confirm Payment"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPayment;
