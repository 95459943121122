const initialState = {
  data: [],
};

const eventReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_EVENTS":
      return {
        ...state,
        data: payload,
      };
    case "ADD_EVENT":
      return {
        ...state,
        data: [...state.data, payload],
      };
    default:
      return state;
  }
};

export default eventReducer;
