import React, { useEffect, useState } from 'react';
import HeaderData from '../Header/HeaderData';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSmall from '../Buttons/ButtonSmall';
import Loader from '../Loading/Loader';
import InputWrapper from '../Wrapper/InputWrapper';
import ReactQuill from 'react-quill';
import { addPDFText, getPDFText } from '../../Redux/Actions';
import ButtonLoading from '../Buttons/ButtonLoading';
import parse from "html-react-parser";


const ReportForm = ({onClose}) => {
    let Once = true;
    const [loading, setLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false);
    const dispatch = useDispatch();
    const [welcome,setWelcome] = useState("")
    const {isInvoked,welcomeText} = useSelector((state)=>state.pdfText);
    const [error,setError]=useState(false);




    function handleSubmit(e){
        e.preventDefault();
        if(!welcome || welcome ==="<p><br></p>"){
            setError(true)
            setTimeout(()=>{setError(false)},3000)
        }else{
            setBtnLoading(true);
            dispatch(addPDFText(welcome)).then(()=>{
                setBtnLoading(false);
            })
        }
           
    }


    useEffect(()=>{
        if(welcomeText){
            setWelcome(parse(welcomeText));
        }
    },[welcomeText])



    useEffect(()=>{
        if(Once){
            Once = false;
            if(!isInvoked){
                setLoading(true);
                dispatch(getPDFText()).then(()=>{
                    setLoading(false);
                })
            }

        }
    },[])
    return (
        <>
        <HeaderData text="Report Form">
            <ButtonSmall onClick={onClose} text={"Close Form"}/>
        </HeaderData>
        {
            loading ? <Loader/>
            :
            <div className='bg-white mt-3 px-3'>
            <InputWrapper>
            <div className="w-full">
              <label
                htmlFor="description"
                className="block pl-[2px] font-medium text-[14px] capitalize"
              >
                Welcome Text for pdf reports
              </label>
              <ReactQuill
                theme="snow"
                id="welcome"
                value={welcome}
                className="w-full py-3 rounded-md "
                onChange={setWelcome}
                placeholder="Write welcome text for pdf here ..."
              />
              {
                error? (
                  <p className="text-red-600 mb-2 text-[14px]">Welcome text is required for pdf</p>
                ) : null
              }
            </div>
          </InputWrapper>
          <div className='w-1/2 mx-auto flex justify-center items-center pb-3'>
            {
                btnLoading?<ButtonLoading/>:<ButtonSmall onClick={handleSubmit} text={"Submit"}/>
            }
          
          </div>
          </div>
        }
       

        </>
    );
}

export default ReportForm;
