import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import DashboardSidebar from "../components/Sidebar/DashboardSidebar";
import DashboardHeader from "../components/Header/DashboardHeader";

const DashboardLayout = () => {
  const location = useLocation();
  localStorage.setItem("coachLocation", JSON.stringify(location.pathname));
  return (
    <section className="min-w-[100vw] min-h-screen max-h-auto flex justify-between items-start gap-1 bg-[--bg-yellow-light]">
      <DashboardSidebar />
      <div className="w-[92%] xl:w-[82%] min-h-screen transition-all duration-300 ease-in-out flex justify-start items-center flex-col">
        <DashboardHeader />
        <div style={{ width: "96%" }} className="mt-5 pb-8">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default DashboardLayout;
