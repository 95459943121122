import React from "react";
import Avatar from "../Avatar/Avatar";
import { Link } from "react-router-dom";

const ListRow = ({ data }) => {
  return (
    <Link
      to={`/staffs-detail/${data?._id}`}
      className="py-2 flex justify-between items-center gap-2 border-b"
    >
      <div className="flex justify-start items-center gap-3">
        <Avatar imageRef={data?.photo} />
        <h5 className="text-[14px] satoshi-700">{`${data?.firstName} ${data?.lastName}`}</h5>
      </div>
      {/* <HiOutlineDotsVertical className="mr-2 " /> */}
    </Link>
  );
};

export default ListRow;
