import { combineReducers } from "redux";
import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import mediaReducer from "./mediaReducer";
import programReducer from "./programReducer";
import reportReducer from "./reportsReducer";
import handoutReducer from "./handoutReducer";
import packageReducer from "./packageReducer";
import { taskReducer } from "./taskReducer";
import stripeReducer from "./stripeReducer";
import reportFormReducer from "./reportFormReducer";
import { transactionReducer } from "./transactionReducer";
import { servicesReducer } from "./servicesReducer";
import goalsReducer from "./goalsReducer";
import CustomerDetailReducer from "./customerDetailReducer";
import staffDetailReducer from "./staffDetailReducer";
import chatListingReducer from "./chatListingReducer";
import chatAllUser from "./chatAllUser";
import eventReducer from "./eventReducer";

export default combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  media: mediaReducer,
  program: programReducer,
  reports: reportReducer,
  handouts: handoutReducer,
  packages: packageReducer,
  tasks: taskReducer,
  stripe: stripeReducer,
  pdfText: reportFormReducer,
  transactions: transactionReducer,
  services: servicesReducer,
  goals: goalsReducer,
  customerDetail: CustomerDetailReducer,
  staffDetail: staffDetailReducer,
  chatListing: chatListingReducer,
  allChatUser: chatAllUser,
  events: eventReducer,
});
