import React from "react";
import ButtonSmall from "../Buttons/ButtonSmall";
import moment from "moment";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const MediaDetailCard = ({
  video,
  image,
  title,
  description,
  creationDate,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-[95%] max-w-[800px] mt-5 bg-white">
      <div className="w-full flex justify-between flex-col items-stretch gap-3 flex-wrap">
        <div className="w-full h-[180px] sm:h-[280px] flex justify-start items-start">
          {video ? (
            <video width="100%" className="h-full" controls>
              <source
                src={process.env.REACT_APP_IMAGE_URL + video}
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              className="w-full h-full object-contain"
              src={process.env.REACT_APP_IMAGE_URL + image}
              alt="Program banner"
            />
          )}
        </div>

        <div className="flex justify-start items-start flex-col py-4 px-4">
          <h1 className="satoshi-700 text-[14px] sm:text-[16px] xl:text-[18px]">
            {title}
          </h1>

          <div className="w-full mt-2 border-t pt-4">
            <h1 className="satoshi-700 mb-2 text-[14px] xl:text-[16px]">
              Description
            </h1>
          </div>
          <div className="w-full max-h-max satoshi-500 description">
            {parse(parse(description))}
          </div>
        </div>
        <div className="text-right w-full px-4 pb-3">
          <p className="text-[12px] xl:text-[14px] text-gray-500">
            Uploaded at :{" "}
            {moment(parseInt(creationDate)).format("DD MMM, YYYY")}
          </p>
        </div>
      </div>

      <div className="mt-3 mb-2 w-1/2 mx-auto flex justify-center items-center">
        <ButtonSmall
          onClick={() => {
            navigate(-1);
          }}
          text="Go Back"
        />
      </div>
    </div>
  );
};

export default MediaDetailCard;
