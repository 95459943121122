import React from "react";

const InputWrapper = ({ children, customClasses }) => {
  return (
    <div
      className={`flex justify-between items-center flex-wrap  gap-2 mt-3 ${customClasses}`}
    >
      {children}
    </div>
  );
};

export default InputWrapper;
