import React, { useState } from "react";
import Avatar from "../Avatar/Avatar";
import moment from "moment";
import { useDispatch } from "react-redux";
import { changeClientStatus } from "../../Redux/Actions";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Link } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";

const ClientRow = ({ data, onDeleteClient }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  function handleChangeStatus() {
    setLoading(true);
    dispatch(changeClientStatus(data._id)).then(() => {
      setLoading(false);
    });
  }
  return (
    <tr className="text-left border-t">
      <td>
        <Link
          to={`/clients-detail/${data?._id}`}
          className="flex justify-start items-center py-2 px-2 pl-3 min-w-max"
        >
          <Avatar imageRef={data?.photo} />
          <div className=" ml-2 flex justify-center items-center flex-col w-max">
            <h5 className="text-[12px] sm:text-[14px] lg:text-[16px] capitalize">
              {data.firstName + " " + data.lastName}
            </h5>
            {/* <p className="text-[14px] text-[--gray] "></p> */}
          </div>
        </Link>
      </td>
      <td
        onClick={(e) => {
          e.target.classList.toggle("whitespace-nowrap");
        }}
        className=" px-3 py-2  satoshi-500 text-sm pr-8 max-w-[180px] whitespace-nowrap text-ellipsis "
      >
        {data?.email}
      </td>
      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px]">
        {data?.totalPrograms || 0}
      </td>
      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px]">
        {moment(parseInt(data.joinedAt)).format("DD MMM,YYYY")}
      </td>
      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] pl-4 underline text-blue-400">
        <Link
          to={`/packages?_id=${data?.packageInfo?.packageId}`}
          className="capitalize"
        >
          {data?.packageInfo?.packageName || "No package"}
        </Link>
      </td>

      {/* <td className="px-2 uppercase text-white text-center">
        <p
          className={`${
            packageType.toLowerCase() === "gold"
              ? "bg-[--green]"
              : "bg-[--orange]"
          } px-3  rounded-full py-1 text-sm`}
        >
          {packageType}
        </p>
      </td> */}
      <td>
        {loading ? (
          <ButtonLoading
            customClasses={"!bg-red-700 !py-1 !px-0"}
            spinOnly={true}
          />
        ) : (
          <div className="flex justify-center items-stretch gap-3">
            <button
              onClick={handleChangeStatus}
              className="bg-red-700 text-white rounded-md py-1 px-2 satoshi-500 text-[14px]"
            >
              {data?.blockactive ? "Block" : "Unblock"}
            </button>
            <div
              onClick={onDeleteClient}
              className="cursor-pointer border border-red-700 text-red-700 rounded-md px-2 py-1 transition-all ease-in-out duration-500 hover:bg-red-700 hover:text-white"
            >
              <MdDeleteForever className="text-base md:text-lg lg:text-xl xl:text-2xl" />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ClientRow;
