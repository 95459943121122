import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import Loader from "../Loading/Loader";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { addGoalSteps, getDashboardData } from "../../Redux/Actions";

const AssignGoal = ({ open, handleClose, data }) => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    staffError: "",
  });
  const [values, setValues] = useState({
    goalId: data?.goalId,
    steps: data?.steps,
    note: data?.note,
    staff: data?.staff,
  });
  const [searchQuery, setSearchQuery] = useState("");

  function errorHandler(name, err = null) {
    setErrors((prev) => ({
      ...prev,
      [name]: err,
    }));
  }

  function handleStaff(e) {
    const staffId = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setValues((prevValues) => ({
        ...prevValues,
        staff: [...prevValues.staff, staffId],
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        staff: prevValues.staff.filter((id) => id !== staffId),
      }));
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!values.staff.length) {
      errorHandler("staffError", "Please select at least one staff member.");
    } else {
      setBtnLoading(true);
      dispatch(addGoalSteps(values)).then(() => {
        setBtnLoading(false); //
        handleClose();
      });
    }
  }

  function handleSearchInputChange(e) {
    setSearchQuery(e.target.value);
  }

  useEffect(() => {
    setLoading(true);
    if (dashboardData.isNotInvoked) {
      dispatch(getDashboardData()).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Modal open={open} onclick={handleClose} headerText="Assign Goal">
      {loading ? (
        <Loader />
      ) : (
        <>
          {dashboardData.staff.length ? (
            <>
              <p className="mb-3 satoshi-700 mt-8">
                Please select staff you want to assign the goal
              </p>
              <div className="w-full flex justify-start items-center">
                <div className="relative w-[60%] text-[--gray] bg-white border rounded-md mb-4">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button
                      type="submit"
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                      >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </button>
                  </span>
                  <input
                    type="search"
                    name="q"
                    className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
                    placeholder="Search Staff"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                </div>
              </div>
              <div className="max-h-[200px] flex justify-start w-full items-stretch flex-wrap gap-2">
                {dashboardData?.staff
                  ?.filter((staff) => {
                    const fullName = `${
                      staff?.firstName + " " + staff?.lastName
                    }`;

                    const searchQueryNormalized = searchQuery
                      .toLowerCase()
                      .replace(/\s/g, "");
                    return fullName
                      .toLowerCase()
                      .replace(/\s/g, "")
                      .includes(searchQueryNormalized);
                  })
                  ?.map((staff, index) => (
                    <div
                      key={index}
                      className="flex justify-start items-center pl-2 mb-1 min-w-[200px] border py-2 rounded-md bg-slate-50"
                    >
                      <input
                        type="checkbox"
                        value={staff._id}
                        onChange={handleStaff}
                        checked={values.staff.includes(staff._id)}
                        className="w-4 h-4 satoshi-500 cursor-pointer"
                      />
                      <label className="ml-2 text-[13px] satoshi-500 w-max cursor-pointer">
                        {`${staff.firstName} ${staff.lastName}`}
                      </label>
                    </div>
                  ))}
              </div>
              {errors.staffError && (
                <p className="text-red-600 text-[14px] mt-1 text-center">
                  {errors.staffError}
                </p>
              )}
            </>
          ) : (
            <p className="text-center text-[16px] md:text-[18px] text-red-600">
              You don't have any staff to assign task
            </p>
          )}
          <div className="flex justify-center items-center gap-4 mt-6 w-full">
            {btnLoading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  text="Cancel"
                  onClick={handleClose}
                  customClasses="!bg-white !text-black"
                />
                <ButtonSmall text="Assign" onClick={handleSubmit} />
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default AssignGoal;
