import React from "react";
import HeaderData from "../Header/HeaderData";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

const PaymentHistory = () => {
  const { data } = useSelector((state) => state.customerDetail);

  return (
    <>
      <HeaderData text="Customer Payment History" customClassess={"!my-3"} />
      {data?.transactions?.length ? (
        <div className="mt-5 rounded-md mr-2">
          <div className="w-full bg-white px-3 py-3">
            <h5 className="text-[21px] satoshi-700">
              Client Transactions History
            </h5>
          </div>
          <div className="w-full overflow-x-auto">
            <table className="w-full min-w-[770px] border-collapse !overflow-x-auto bg-white rounded-md">
              <thead className="border-t">
                <tr>
                  <th className="text-left px-2 py-2 text-[--gray] text-sm">
                    Type
                  </th>
                  <th className="text-left px-2 py-2 text-[--gray] text-sm">
                    Amount
                  </th>
                  <th className="text-left px-2 py-2 text-[--gray] text-sm">
                    Date
                  </th>
                  <th className="text-left px-2 py-2 text-[--gray] text-sm ">
                    Title
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.transactions?.map((transaction, index) => {
                  return (
                    <tr key={index} className="text-left border-t">
                      <td
                        className={`px-2 text-ellipsis w-max satoshi-500 pl-3 ${
                          transaction?.package
                            ? "text-orange-500"
                            : "text-green-500"
                        }`}
                      >
                        {transaction?.package ? "Package" : "Program"}
                      </td>

                      <td className="px-2 w-max">$ {transaction?.amount}</td>
                      <td className="px-2">
                        {moment(parseInt(transaction?.createdAt)).format(
                          "DD MMM,YYYY"
                        )}
                      </td>

                      <td
                        onClick={(e) => {
                          e.target.classList.toggle("whitespace-nowrap");
                        }}
                        className=" px-3 py-2  satoshi-500 text-sm pr-8 max-w-[190px] xl:max-w-[max] whitespace-nowrap text-ellipsis text-blue-800"
                      >
                        <Link
                          to={
                            transaction?.program
                              ? `/programs-detail/${transaction?.program}`
                              : `/packages?_id=${transaction?.package}`
                          }
                        >
                          {transaction?.package
                            ? transaction?.packageName
                            : transaction?.programName}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>This customer doesnot made any transaction yet</p>
      )}
    </>
  );
};

export default PaymentHistory;
