import React from "react";
import Avatar from "../Avatar/Avatar";
import moment from "moment";
import { Link } from "react-router-dom";

const PaymentTableRow = ({ data }) => {
  return (
    <tr className="text-left border-t">
      <td className="flex justify-start items-center py-2 pl-4 pr-2 max-w-max">
        <Avatar imageRef={data?.user?.photo} />
        <Link
          to={`/clients-detail/${data?.user?._id}`}
          className=" ml-2 flex justify-center items-center flex-col underline text-blue-800"
        >
          <h5 className="text-[16px] ">
            {data?.user?.firstName + " " + data?.user?.lastName}
          </h5>
        </Link>
      </td>
      <td
        className={`px-2 text-ellipsis w-max satoshi-500 ${
          data?.package ? "text-orange-500" : "text-green-500"
        }`}
      >
        {data?.package ? "Package" : "Program"}
      </td>
      {/* <td className="px-2 ">
        <div className="max-w-[110px] overflow-hidden text-ellipsis">
          {data?.user?.email}
        </div>
      </td> */}
      <td className="px-2 w-max">$ {data?.amount}</td>
      <td className="px-2">
        {moment(parseInt(data?.createdAt)).format("DD MMM,YYYY")}
      </td>

      <td
        onClick={(e) => {
          e.target.classList.toggle("whitespace-nowrap");
        }}
        className=" px-3 py-2  satoshi-500 text-sm pr-8 max-w-[130px] xl:max-w-[max] whitespace-nowrap text-ellipsis text-blue-800"
      >
        <Link
          to={
            data?.program
              ? `/programs-detail/${data?.program}`
              : `/packages?_id=${data?.package}`
          }
        >
          {data?.package ? data?.packageName : data?.programName}
        </Link>
      </td>
    </tr>
  );
};

export default PaymentTableRow;
