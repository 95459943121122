import React from "react";
import { Link } from "react-router-dom";

const FormFooter = ({ text, url, urltext, customClass }) => {
  return (
    <div className={`w-full flex justify-center items-center mt-1 pb-1 sm:mt-3 ${customClass}`}>
      <span>
        {text}
        <Link className="text-[--blue-light] pl-1" to={url}>
          {urltext}
        </Link>
      </span>
    </div>
  );
};

export default FormFooter;
