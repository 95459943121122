import React, { useState } from "react";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import InputRequired from "../Input/InputRequired";
import ButtonSmall from "../Buttons/ButtonSmall";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import { useFormik } from "formik";
import { eventSchema } from "../../Schema";
import { addNewEvent, updateEvent } from "../../Redux/Actions";
import DeleteEvent from "./DeleteEvent";

const AddEventModal = ({ data, open, isEdit, handleClose }) => {
  const dashboardData = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [allClientsSelected, setAllClientsSelected] = useState(false);
  const [allStaffSelected, setAllStaffSelected] = useState(false);
  const [searchClientQuery, setSearchClientQuery] = useState("");
  const [searchStaffQuery, setSearchStaffQuery] = useState("");
  const [deleteEvent, setDeleteEvent] = useState({
    modal: false,
    id: null,
  });

  const initialState = {
    title: data?.title || "",
    startDate: moment(data.startDate).format("YYYY-MM-DDTHH:mm") || Date.now(),
    endDate: moment(data.endDate).format("YYYY-MM-DDTHH:mm") || Date.now(),
    clients: data?.clients || [],
    staffs: data?.staffs || [],
    coachId: "",
    createdBy: data?.createdBy,
  };

  function handleClientSearchInputChange(e) {
    setSearchClientQuery(e.target.value);
  }

  function handleSearchStaffInput(e) {
    setSearchStaffQuery(e.target.value);
  }

  function handleStaffCheckBox(e) {
    const staffId = e.target.value;
    // Check if the checkbox is checked
    if (e.target.checked) {
      if (!values.staffs.includes(staffId)) {
        setValues((prevValues) => ({
          ...prevValues,
          staffs: [...prevValues.staffs, staffId],
        }));
      }
    } else {
      // Remove staffId from the staff array
      setValues((prevValues) => ({
        ...prevValues,
        staffs: prevValues.staffs.filter((id) => id !== staffId),
      }));
    }
  }

  function handleSelectAllStaff(e) {
    if (e.target.checked) {
      setValues((pre) => ({
        ...pre,
        staffs: dashboardData.staff.map((staff) => staff._id),
      }));

      setAllStaffSelected(true);
    } else {
      setValues((pre) => ({
        ...pre,
        staffs: [],
      }));
      setAllStaffSelected(false);
    }
  }

  function handleClientCheckBox(e) {
    const clientId = e.target.value;
    // Check if the checkbox is checked
    if (e.target.checked) {
      if (!values.clients.includes(clientId)) {
        setValues((prevValues) => ({
          ...prevValues,
          clients: [...prevValues.clients, clientId],
        }));
      }
    } else {
      // Remove staffId from the staff array
      setValues((prevValues) => ({
        ...prevValues,
        clients: prevValues.clients.filter((id) => id !== clientId),
      }));
    }
  }

  function handleSelectAllClients(e) {
    if (e.target.checked) {
      setValues((pre) => ({
        ...pre,
        clients: dashboardData.clients.map((client) => client._id),
      }));

      setAllClientsSelected(true);
    } else {
      setValues((pre) => ({
        ...pre,
        clients: [],
      }));
      setAllClientsSelected(false);
    }
  }

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialState,
    validationSchema: eventSchema,
    onSubmit: (values) => {
      setBtnLoading(true);
      if (data?.isUpdate) {
        dispatch(updateEvent(values, data?._id)).then(() => {
          setBtnLoading(false);
          handleClose();
        });
      } else {
        dispatch(addNewEvent(values)).then(() => {
          setBtnLoading(false);
          handleClose();
        });
      }
    },
  });

  function handleDeleteModal(status = "close") {
    setDeleteEvent({
      modal: status === "close" ? false : true,
      id: status === "close" ? null : data?._id,
    });
    if (status === "close") {
      handleClose();
    }
  }

  return (
    <>
      {deleteEvent.modal ? (
        <DeleteEvent
          open={deleteEvent.modal}
          data={deleteEvent}
          handleModal={() => {
            handleDeleteModal("close");
          }}
        />
      ) : (
        <Modal
          headerText={isEdit ? "Update Event" : "Add Event"}
          open={open}
          onclick={handleClose}
        >
          <InputWrapper>
            <InputRequired
              name="title"
              id="title"
              placeholder="Enter meeting title"
              onChange={handleChange}
              label="Title"
              value={values.title}
              error={errors.title}
              touch={touched.title}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <div className="w-full">
              <label
                htmlFor="start"
                className={`block mb-1 pl-[2px] font-medium text-[14px] capitalize`}
              >
                Start Date
              </label>
              <input
                type="datetime-local"
                name="startDate"
                id="start"
                value={values.startDate}
                className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block w-full p-2.5 "
                onChange={handleChange}
              />
              {errors.startDate && touched.startDate ? (
                <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
                  {errors.startDate}
                </span>
              ) : null}
            </div>
          </InputWrapper>
          <InputWrapper>
            <div className="w-full">
              <label
                htmlFor="end"
                className={`block mb-1 pl-[2px] font-medium text-[14px] capitalize`}
              >
                End Date
              </label>
              <input
                type="datetime-local"
                name="endDate"
                id="end"
                value={values.endDate}
                className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block w-full p-2.5 "
                onChange={handleChange}
              />
              {errors.endDate && touched.endDate ? (
                <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
                  {errors.endDate}
                </span>
              ) : null}
            </div>
          </InputWrapper>

          {data?.createdBy === "Coach" && dashboardData?.staff.length ? (
            <>
              <p className="mb-3 satoshi-700 mt-8">Please select Staff</p>
              <div className="w-full flex justify-start items-center">
                <div className="relative w-[60%] text-[--gray] bg-white border rounded-md mb-4">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button
                      type="submit"
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                      >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </button>
                  </span>
                  <input
                    type="search"
                    name="q"
                    className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
                    placeholder="Search Staff"
                    value={searchStaffQuery}
                    onChange={handleSearchStaffInput}
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-start items-center pl-2 mb-2 min-w-[200px] border py-2 rounded-md bg-slate-50 ">
                  <input
                    type="checkbox"
                    value={"All"}
                    checked={allStaffSelected}
                    onChange={handleSelectAllStaff}
                    className={`w-4 h-4 satoshi-500 cursor-pointer`}
                  />
                  <label
                    className={`ml-2 text-[13px] satoshi-500 w-max cursor-pointer`}
                  >
                    All Staff
                  </label>
                </div>
              </div>

              <div className="max-h-[200px] flex justify-start w-full items-stretch flex-wrap gap-2">
                {dashboardData.staff
                  .filter((staff) => {
                    const fullName = `${staff.firstName} ${staff.lastName}`
                      .toLowerCase()
                      .trim();
                    const searchQueryNormalized = searchStaffQuery
                      .toLowerCase()
                      .trim();
                    return fullName.includes(searchQueryNormalized);
                  })
                  .map((staff, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-start items-center pl-2 mb-1 min-w-[200px] border py-2 rounded-md bg-slate-50 "
                      >
                        <input
                          type="checkbox"
                          value={staff._id}
                          onChange={handleStaffCheckBox}
                          checked={values.staffs.includes(staff._id)}
                          className={`w-4 h-4 satoshi-500 cursor-pointer`}
                        />
                        <label
                          className={`ml-2 text-[13px] satoshi-500 w-max cursor-pointer`}
                        >
                          {staff.firstName + " " + staff.lastName}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : null}

          {dashboardData?.clients.length ? (
            <>
              <p className="mb-3 satoshi-700 mt-8">Please select client</p>
              <div className="w-full flex justify-start items-center">
                <div className="relative w-[60%] text-[--gray] bg-white border rounded-md mb-4">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button
                      type="submit"
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                      >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </button>
                  </span>
                  <input
                    type="search"
                    name="q"
                    className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
                    placeholder="Search Client"
                    value={searchClientQuery}
                    onChange={handleClientSearchInputChange}
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-start items-center pl-2 mb-2 min-w-[200px] border py-2 rounded-md bg-slate-50 ">
                  <input
                    type="checkbox"
                    value={"All"}
                    checked={allClientsSelected}
                    onChange={handleSelectAllClients}
                    className={`w-4 h-4 satoshi-500 cursor-pointer`}
                  />
                  <label
                    className={`ml-2 text-[13px] satoshi-500 w-max cursor-pointer`}
                  >
                    All Clients
                  </label>
                </div>
              </div>

              <div className="max-h-[200px] flex justify-start w-full items-stretch flex-wrap gap-2">
                {dashboardData.clients
                  .filter((client) => {
                    const fullName = `${client.firstName} ${client.lastName}`
                      .toLowerCase()
                      .trim();
                    const searchQueryNormalized = searchClientQuery
                      .toLowerCase()
                      .trim();
                    return fullName.includes(searchQueryNormalized);
                  })
                  .map((client, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-start items-center pl-2 mb-1 min-w-[200px] border py-2 rounded-md bg-slate-50 "
                      >
                        <input
                          type="checkbox"
                          value={client._id}
                          onChange={handleClientCheckBox}
                          checked={values.clients.includes(client._id)}
                          className={`w-4 h-4 satoshi-500 cursor-pointer`}
                        />
                        <label
                          className={`ml-2 text-[13px] satoshi-500 w-max cursor-pointer`}
                        >
                          {client.firstName + " " + client.lastName}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : null}

          <div className="flex justify-center items-center gap-4 mt-6 w-full">
            {btnLoading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  type="button"
                  text="Cancel"
                  onClick={handleClose}
                  customClasses="!bg-white !text-black"
                />
                {data?.createdBy === "Coach" && (
                  <>
                    <ButtonSmall
                      type="button"
                      text={isEdit ? "Update" : "Add"}
                      onClick={handleSubmit}
                    />
                    {isEdit && (
                      <ButtonSmall
                        customClasses={"!bg-red-600 !border-red-700"}
                        type="button"
                        text="Delete"
                        onClick={() => {
                          handleDeleteModal("open");
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddEventModal;
