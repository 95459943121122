import React, { useState } from "react";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import InputPassword from "../Input/InputPassword";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { staffSchema } from "../../Schema";
import { useFormik } from "formik";
import ButtonLoading from "../Buttons/ButtonLoading";
import { addStaff } from "../../Redux/Actions";
import InputRequired from "../Input/InputRequired";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "Staff",
  staffInfo: {
    coachId: "",
    phone: "",
  },
};

const StaffModal = ({ open, handleOpen }) => {
  const [loading, setLoading] = useState(false);

  const coachData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: staffSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        values.staffInfo.coachId = coachData.id;
        dispatch(addStaff(values))
          .then(() => {
            action.resetForm();
            setLoading(false);
            handleOpen();
          })
          .catch((err) => {
            setLoading(false);
            handleOpen();
          });
      },
    });

  return (
    <Modal headerText="Add Staff" open={open} onclick={handleOpen}>
      <InputWrapper>
        <InputRequired
          type="text"
          name="firstName"
          id="name"
          label="First Name"
          placeholder="Enter first name"
          error={errors.firstName}
          touch={touched.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>
      <InputWrapper>
        <InputRequired
          type="text"
          name="lastName"
          id="name"
          label="Last Name"
          placeholder="Enter last name"
          error={errors.lastName}
          touch={touched.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>

      <InputWrapper>
        <InputRequired
          type="email"
          name="email"
          id="email"
          label="Email"
          placeholder="Enter email address"
          error={errors.email}
          touch={touched.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>
      <InputWrapper>
        <InputRequired
          type="number"
          name="staffInfo.phone"
          id="phone"
          label="Phone"
          placeholder="Enter Phone number"
          error={errors.staffInfo && errors.staffInfo.phone}
          touch={touched.staffInfo && touched.staffInfo.phone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>
      <InputPassword
        customClasses="mt-3"
        name="password"
        id="password"
        placeholder="Enter password for staff"
        label="Password"
        error={errors.password}
        touch={touched.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div className="w-full flex justify-center items-center gap-5 mt-3">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall text="Add Staff" onClick={handleSubmit} />
            <ButtonSmall
              text="Cancel"
              onClick={handleOpen}
              customClasses={"!bg-white !text-[--primary]"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default StaffModal;
