import React from "react";

const Form = ({ headerText, description, onSubmit, children, customClass }) => {
  return (
    <div className={`w-[98%] overflow-auto pb-20 ${customClass}`}>
      <form onSubmit={onSubmit}>
        <div className="flex justify-start items-start flex-col">
          <h1 className="text-4xl satoshi-900 overflow-y-hidden">
            {headerText}
          </h1>
          <p className="font-light mt-4 text-[--gray] w-[90%]">{description}</p>
        </div>
        <div className="w-[98%] mx-auto mt-5 flex justify-start items-start flex-col gap-4">
          {children}
        </div>
      </form>
    </div>
  );
};

export default Form;
