import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../Layouts/DashboardLayout";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Inbox from "../Pages/Dashboard/Inbox";
import Programs from "../Pages/Dashboard/Programs";
import Media from "../Pages/Dashboard/Media";
import Reports from "../Pages/Dashboard/Reports";
import Clients from "../Pages/Dashboard/Clients";
import Settings from "../Pages/Dashboard/Settings";
import Payment from "../Pages/Dashboard/Payment";
import Handouts from "../Pages/Dashboard/Handouts";
import Messages from "../Pages/Dashboard/Messages";
import SignIn from "../Pages/Auth/SignIn";
import { RedirectRoute } from "./RedirectRoute";
import { PrivateRoute } from "./PrivateRoute";
import SignUp from "../Pages/Auth/SignUp";
import PackageSelection from "../Pages/Auth/PackageSelection";
import UpgradePackage from "../Pages/Dashboard/UpgradePackage";
import { SemiPrivateRoute } from "./SemiPrivateRoute";
import Tasks from "../Pages/Dashboard/Tasks";
import Staff from "../Pages/Dashboard/Staff";
import ProgramDetail from "../Pages/Dashboard/ProgramDetail";
import SuccessPayment from "../Pages/PaymentStatus/SuccessPayment";
import ErrorPayment from "../Pages/PaymentStatus/ErrorPayment";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import ClientDetail from "../Pages/Dashboard/CustomerDetails";
import StaffDetails from "../Pages/Dashboard/StaffDetails";
import MediaVideoDetail from "../Pages/Dashboard/MediaVideoDetail";
import MediaBlogDetail from "../Pages/Dashboard/MediaBlogDetail";
import UserGoals from "../Pages/Dashboard/UserGoals";
import SmartPlanDetail from "../Pages/Dashboard/SmartPlanDetail";
import PageNotFound from "../Pages/NotFound/NotFound";
import Packages from "../Pages/Dashboard/Packages";
// import VerifyEmail from "../Pages/Auth/VerifyEmail";
import OtpVeri from "../components/PasswordRecoverySteps/OtpVeri";

export default createBrowserRouter([
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/signin",
    element: (
      <RedirectRoute>
        <SignIn />
      </RedirectRoute>
    ),
  },
  {
    path: "/forget-password",
    element: (
      <RedirectRoute>
        <ForgetPassword />
      </RedirectRoute>
    ),
  },
  {
    path: "/verifyOTP",
    element: (
      <RedirectRoute>
        <OtpVeri />
      </RedirectRoute>
    ),
  },
  {
    path: "/signup",
    element: (
      <RedirectRoute>
        <SignUp />
      </RedirectRoute>
    ),
  },
  {
    path: "/select-package",
    element: (
      <SemiPrivateRoute>
        <PackageSelection />
      </SemiPrivateRoute>
    ),
  },
  {
    path: "",
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "",
        index: 1,
        element: <Dashboard />,
      },
      {
        path: "inbox",
        element: <Inbox />,
      },
      {
        path: "media",
        element: <Media />,
      },
      {
        path: "media-video-detail/:id",
        element: <MediaVideoDetail />,
      },
      {
        path: "media-blog-detail/:id",
        element: <MediaBlogDetail />,
      },
      {
        path: "handout",
        element: <Handouts />,
      },
      {
        path: "programs",
        element: <Programs />,
      },
      {
        path: "programs-detail/:id",
        element: <ProgramDetail />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "clients-detail/:userId",
        element: <ClientDetail />,
      },
      {
        path: "packages",
        element: <Packages />,
      },
      {
        path: "smart-plan-detail/:id",
        element: <SmartPlanDetail />,
      },
      {
        path: "staffs-detail/:userId",
        element: <StaffDetails />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "goals",
        element: <UserGoals />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "staffs",
        element: <Staff />,
      },
      {
        path: "setting",
        element: <Settings />,
      },
      {
        path: "messages/:to",
        element: <Messages />,
      },
      {
        path: "upgrade-package",
        element: <UpgradePackage />,
      },
    ],
  },
  {
    path: "/success/:id",
    element: <SuccessPayment />,
  },
  {
    path: "/error/:id",
    element: <ErrorPayment />,
  },
]);
