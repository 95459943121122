import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import Loader from "../Loading/Loader";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { assignTask, getDashboardData } from "../../Redux/Actions";

const AssignTaskModal = ({ open, handleClose, data }) => {
  let Once = true;

  const [laoding, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard);
  const [allSelected, setAllSelected] = useState(false);

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    staffError: "",
    endDateError: "",
  });
  const [values, setValues] = useState({
    taskId: data.taskId,
    staff: [],
    endDate: "",
  });

  function errorHandler(name, err = null) {
    setErrors((pre) => {
      return {
        ...pre,
        [name]: err,
      };
    });
  }

  function handleStaff(e) {
    const staffId = e.target.value;
    // Check if the checkbox is checked
    if (e.target.checked) {
      // Add staffId to the staff array if it's not already present
      errorHandler("staffError");
      if (!values.staff.includes(staffId)) {
        setValues((prevValues) => ({
          ...prevValues,
          staff: [...prevValues.staff, staffId],
        }));
      }
    } else {
      // Remove staffId from the staff array
      setValues((prevValues) => ({
        ...prevValues,
        staff: prevValues.staff.filter((id) => id !== staffId),
      }));
    }
  }

  function handleAllCheck(e) {
    if (e.target.checked) {
      setValues((pre) => ({
        ...pre,
        staff: dashboardData.staff.map((staff) => staff._id),
      }));

      setAllSelected(true);
    } else {
      setValues((pre) => ({
        ...pre,
        staff: [],
      }));
      setAllSelected(false);
    }
  }

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Ensure month and day are formatted with leading zeros
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day + 1}`;
  }

  function handleEndDate(e) {
    if (e.target.value) {
      errorHandler("endDateError");
      setValues((pre) => {
        return {
          ...pre,
          endDate: e.target.value,
        };
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!values.endDate) {
      errorHandler("endDateError", "Please enter deadline");
    } else if (!values.staff.length) {
      errorHandler("staffError", "Please select one staff");
    } else {
      setBtnLoading(true);
      values.endDate = JSON.stringify(new Date(values.endDate).getTime());
      dispatch(assignTask(values)).then(() => {
        setBtnLoading(false);
        handleClose();
      });
    }
  }

  function setInitialState() {
    setLoading(true);
    let associatedId = [];
    if (data?.taskDetail) {
      data?.taskDetail?.taskassociations?.forEach((item) => {
        associatedId.push(item.staff.staffid);
      });
    }
    setValues((pre) => ({
      ...pre,
      staff: associatedId,
    }));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (dashboardData.isNotInvoked) {
        setLoading(true);
        dispatch(getDashboardData()).then(() => {
          setInitialState();
          setLoading(false);
        });
      } else {
        setInitialState();
      }
    } else {
      setInitialState();
    }
  }, []);

  return (
    <Modal open={open} onclick={handleClose} headerText="Assign Task">
      {laoding ? (
        <Loader />
      ) : (
        <>
          {dashboardData?.staff?.length ? (
            <>
              <div className="flex-grow basis-48 ">
                <label
                  htmlFor="endDate"
                  className={`block mb-1 pl-[2px] font-medium text-[14px] capitalize`}
                >
                  Deadline
                </label>
                <input
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={values.endDate}
                  min={getCurrentDate()}
                  onChange={handleEndDate}
                  className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block w-full p-2.5 "
                />
                {errors.endDateError ? (
                  <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
                    {errors.endDateError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <p className="mb-3 satoshi-700 mt-8">
                Please select staff you want to assign the task
              </p>

              <div>
                <div className="flex justify-start items-center pl-2 mb-2 min-w-[200px] border py-2 rounded-md bg-slate-50 ">
                  <input
                    type="checkbox"
                    value={"All"}
                    checked={allSelected}
                    onChange={handleAllCheck}
                    className={`w-4 h-4 satoshi-500 cursor-pointer`}
                  />
                  <label
                    className={`ml-2 text-[13px] satoshi-500 w-max cursor-pointer`}
                  >
                    All Staff
                  </label>
                </div>
              </div>

              <div className="max-h-[200px] flex justify-start w-full items-stretch flex-wrap gap-2">
                {dashboardData?.staff?.map((staff, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-start items-center pl-2 mb-1 min-w-[200px] border py-2 rounded-md bg-slate-50 "
                    >
                      <input
                        type="checkbox"
                        value={staff._id}
                        onChange={handleStaff}
                        checked={values.staff.includes(staff._id)}
                        className={`w-4 h-4 satoshi-500 cursor-pointer`}
                      />
                      <label
                        className={`ml-2 text-[13px] satoshi-500 w-max cursor-pointer`}
                      >
                        {staff.email}
                      </label>
                    </div>
                  );
                })}
              </div>
              {errors.staffError ? (
                <p className="text-red-600 text-[14px] mt-1 text-center">
                  {errors.staffError}
                </p>
              ) : null}
            </>
          ) : (
            <p className="text-center text-[16px] md:text-[18px] text-red-600">
              You don't have any staff to assign task
            </p>
          )}
          <div className="flex justify-center items-center gap-4 mt-6 w-full">
            {btnLoading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  text="Cancel"
                  onClick={handleClose}
                  customClasses="!bg-white !text-black"
                />
                <ButtonSmall text="Assign" onClick={handleSubmit} />
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default AssignTaskModal;
