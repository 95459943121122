import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const SemiPrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.auth);

  if (user.auth && user.userData.role === "Coach") {
    if (user.userData.packageStatus) return <Navigate to="/" />;
    return children;
  }
  return <Navigate to="/signin" />;
};
