const initialState = {
    data: [],
  };
  
  export const transactionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "TRANSACTION_HISTORY":
        return {
          ...state,
          data: payload.payments,
        };
      default:
        return state;
    }
  };
  