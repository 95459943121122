import React from "react";
import { Link } from "react-router-dom";

const UnavailableService = ({ serviceName = null }) => {
  return (
    <p>
      Your package doesn't include {serviceName || "this"} service. If you want
      to unable this service you can upgrade your package from settings->Upgrade
      Package or{" "}
      <Link
        to="/upgrade-package"
        className="text-blue-500 underline satoshi-500"
      >
        {" "}
        click here to upgrade
      </Link>
    </p>
  );
};

export default UnavailableService;
