import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { deleteMedia } from "../../Redux/Actions";

const DeleteMediaModal = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  function handleDelete() {
    setLoading(true);
    dispatch(deleteMedia(data)).then(() => {
      setLoading(false);
      handleClose();
    });
  }
  return (
    <Modal open={open} onclick={handleClose} headerText="Delete Media">
      <p className="text-[18px] text-center">
        {data.mediaType === "video"
          ? "Do you want to delete this media"
          : "Do you want to delete this blog"}
      </p>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleClose}
            />
            <ButtonSmall
              onClick={handleDelete}
              type="button"
              text="Delete"
              customClasses={"!w-1/2 !bg-red-800 !text-white !border-red-600"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteMediaModal;
