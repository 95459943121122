const initialState = {
  staffCount: 0,
  clientsCount: 0,
  totalPrograms: 0,
  totalEarnings: 0,
  clients: [],
  staff: [],
  isNotInvoked: true,
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_DATA":
      return {
        ...state,
        isNotInvoked: false,
        clientsCount: payload.clientsCount,
        staffCount: payload.staffCount,
        totalPrograms: payload.totalPrograms,
        totalEarnings: payload.totalEarnings,
        clients: payload?.clients?.sort((a, b) => b?.joinedAt - a?.joinedAt),
        staff: payload?.staff?.sort((a, b) => b?.joinedAt - a?.joinedAt),
      };
    case "ADD_STAFF":
      return {
        ...state,
        staff: [...state.staff, payload.user],
        clientsCount: state.clientsCount + 1,
      };
    case "ADD_CLIENT":
      return {
        ...state,
        clients: [...state.clients, payload.user],
        clientsCount: state.clientsCount + 1,
      };
    case "ADD_PROGRAMCOUNT":
      return {
        ...state,
        totalPrograms: state.totalPrograms + 1,
      };
    case "CHANGE_CLIENT_STATUS":
      return {
        ...state,
        clients: state.clients.map((client) =>
          client._id === payload
            ? { ...client, blockactive: !client?.blockactive }
            : client
        ),
      };
    case "DELETE_CLIENT":
      return {
        ...state,
        clients: state.clients.filter((client) => client._id !== payload._id),
        clientsCount: state.clientsCount - 1,
      };
    case "CHANGE_STAFF_STATUS":
      return {
        ...state,
        staff: state.staff.map((staff) =>
          staff._id === payload
            ? { ...staff, blockactive: !staff?.blockactive }
            : staff
        ),
      };
    case "DELETE_STAFF":
      return {
        ...state,
        staff: state.staff.filter((staff) => staff._id !== payload._id),
        staffCount: state.staffCount - 1,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
