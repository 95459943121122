import React, { useState } from "react";
import MediaContainer from "../Wrapper/MediaContainer";
import InputWrapper from "../Wrapper/InputWrapper";
import InputRequired from "../Input/InputRequired";
import FileUpload from "../Input/FileUplaod";
import { FaImage } from "react-icons/fa";
import { IoVideocamSharp } from "react-icons/io5";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { mediaSchema } from "../../Schema";
// import RadioInput from "../Input/RadioInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { addMedia, uploadPhoto } from "../../Redux/Actions";
import { errorToast } from "../../Utils/Toast";

const AddMediaForm = ({ handleClose }) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [description, setDescription] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const [mediatype, setMediaType] = useState("blog");
  const initialState = {
    title: "",
    description: "",
    type: "media",
    showToUsers: false,
  };

  const [error, setError] = useState({
    imageError: "",
    videoError: "",
    thumbnailError: "",
    descriptionError: "",
  });

  const onDropThumbnail = (files) => {
    setError((pre) => {
      return {
        ...pre,
        thumbnailError: "",
      };
    });
    // Check if the dropped file has an allowed extension
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setSelectedThumbnail(files[0]);
    } else {
      // Display an error message or handle the case where the file is not allowed
      setError((pre) => {
        return {
          ...pre,
          thumbnailError:
            "Invalid file type. Please upload a PNG ,JPG or JPEG file.",
        };
      });
    }
  };

  const onDropImage = (files) => {
    setError((pre) => {
      return {
        ...pre,
        imageError: "",
      };
    });
    // Check if the dropped file has an allowed extension
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setSelectedFiles(files[0]);
    } else {
      // Display an error message or handle the case where the file is not allowed
      setError((pre) => {
        return {
          ...pre,
          imageError:
            "Invalid file type. Please upload a PNG ,JPG or JPEG file.",
        };
      });
    }
  };

  const onDropVideo = (files) => {
    setError((pre) => {
      return {
        ...pre,
        videoError: "",
      };
    });
    // Check if the dropped file has an allowed extension
    const allowedExtensions = ["mp4", "mkv", "flv"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setSelectedVideo(files[0]);
    } else {
      // Display an error message or handle the case where the file is not allowed
      setError((pre) => {
        return {
          ...pre,
          videoError:
            "Invalid file type. Please upload an MP4, MKV, or FLV video file.",
        };
      });
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: mediaSchema,
      onSubmit: (values, action) => {
        if (!description) {
          setError((pre) => {
            return {
              ...pre,
              descriptionError: "Description is required",
            };
          });
        } else if (!selectedFiles && !selectedVideo) {
          errorToast("Please upload image or video");
        } else {
          setBtnLoading(true);
          if (selectedThumbnail && mediatype === "video") {
            uploadPhoto({ photo: selectedThumbnail })
              .then((res) => {
                values.thumbnail = res.data.photos[0];
              })
              .catch((err) => {
                console.error(err);
                errorToast("Error uploading thumbnail");
              });
          }
          uploadPhoto({ photo: selectedFiles || selectedVideo })
            .then((res) => {
              if (selectedFiles) {
                values.photo = res.data.photos[0];
              } else {
                values.video = res.data.photos[0];
              }
              values.description = description;
              console.log("Thumbnail ===>", values?.thumbnail);
              dispatch(
                addMedia({
                  data: values,
                  mediaType: selectedVideo ? "video" : "blog",
                })
              ).then(() => {
                setBtnLoading(false);
                setDescription("");
                setSelectedVideo(null);
                setSelectedFiles(null);
                action.resetForm();
                handleClose();
              });
            })
            .catch((err) => {
              setBtnLoading(false);
            });
        }
      },
    });

  return (
    <section className="w-full flex justify-between items-start gap-4">
      <div className="w-[73%]">
        <MediaContainer text={"Please fill the data"} />
        <div className="bg-white rounded-md px-6 py-4">
          <InputWrapper customClasses={"!mt-6"}>
            <InputRequired
              label="Title"
              placeholder="Enter title"
              name="title"
              id="title"
              value={values.title}
              error={errors.title}
              touch={touched.title}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWrapper>
          <InputWrapper>
            <div className="w-full">
              <label
                htmlFor="description"
                className="block pl-[2px] font-medium text-[14px] capitalize"
              >
                Description
              </label>
              <ReactQuill
                theme="snow"
                id="description"
                value={description}
                className="w-full py-3 rounded-md"
                onChange={setDescription}
                placeholder="Write description here ..."
              />
              <p className="text-red-600 mb-6">{error.descriptionError}</p>
            </div>
          </InputWrapper>
          <div className="flex-grow basis-48">
            <label
              htmlFor="Category"
              className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
            >
              Choose Media Type (Blog or Video)
            </label>
            <select
              className="w-full rounded-md border outline-none py-2 px-2"
              name="mediatype"
              id="mediatype"
              value={mediatype}
              onChange={(e) => {
                setMediaType(e.target.value);
                setSelectedFiles(null);
                setSelectedVideo(null);
              }}
            >
              <option className="text-black" value="blog">
                Blog
              </option>
              <option className="text-black" value="video">
                Video
              </option>
            </select>
          </div>

          {mediatype && (
            <InputWrapper customClasses={"!items-stretch"}>
              {mediatype === "blog" && (
                <FileUpload
                  icon={<FaImage />}
                  onDrop={onDropImage}
                  error={error.imageError}
                  selectedFiles={selectedFiles}
                  headerText="Drop your image here, or"
                  description=".jpg .png .jpeg files supported"
                  type="image"
                />
              )}

              {mediatype === "video" && (
                <InputWrapper customClasses={"!items-stretch"}>
                  <FileUpload
                    icon={<FaImage />}
                    onDrop={onDropThumbnail}
                    error={error.thumbnailError}
                    selectedFiles={selectedThumbnail}
                    headerText="Drop your thumbnail here, or"
                    description=".jpg .png .jpeg files supported"
                    type="image"
                  />
                  <FileUpload
                    icon={<IoVideocamSharp />}
                    onDrop={onDropVideo}
                    error={error.videoError}
                    type="video"
                    selectedFiles={selectedVideo}
                    headerText="Drop your video here, or"
                    description=".MP4 .MKV .FLV files supported"
                  />
                </InputWrapper>
              )}
            </InputWrapper>
          )}

          {/* <InputWrapper>
            <RadioInput
              type="checkbox"
              inputClasses={"!w-[15px] mt-2 !h-[15px]"}
              labelClasses={"satoshi-500 mt-2 !text-[16px]"}
              handleChange={(e) => {
                if (e.target.checked) {
                  values.showToUsers = true;
                } else {
                  values.showToUsers = false;
                }
              }}
              option={{
                name: "public",
                id: "public",
                label: "Display to Users ",
                value: "showToUsers",
              }}
            />
          </InputWrapper> */}
          <div className="flex justify-start items-center gap-4 mt-3">
            {btnLoading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  text="Cancel"
                  onClick={handleClose}
                  customClasses="!bg-white !text-black"
                />
                <ButtonSmall text="Create Content" onClick={handleSubmit} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddMediaForm;
