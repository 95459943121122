import React from "react";
import HeaderData from "../Header/HeaderData";
import TableHeader from "../Table/TableHeader";
import { useSelector } from "react-redux";
import moment from "moment";

const Meetings = () => {
  const { data } = useSelector((state) => state.staffDetail);

  return (
    <>
      <HeaderData text="Staff Events & Meetings" customClassess="!my-2" />
      {data?.meetings.length ? (
        <>
          <div className="bg-white rounded-md mt-3">
            <div className="px-3 w-full">
              <TableHeader text="Task Assigned" />
            </div>
          </div>
          <div className="dashboardTableWrapper bg-white">
            <table className="bg-white w-full min-w-[700px] border-collapse !overflow-x-auto rounded-md ">
              <thead>
                <tr>
                  <th className="px-3 py-2 text-[--gray] satoshi-500 text-left text-sm ">
                    Event Description
                  </th>
                  <th className="pr-2 pl-8 py-2 text-[--gray] satoshi-500 text-sm text-left">
                    Start Date
                  </th>
                  <th className="px-2 py-2 text-[--gray] satoshi-500 text-sm text-left">
                    End Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.meetings?.map((item, index) => {
                  return (
                    <tr key={index} className="border-t">
                      <td
                        onClick={(e) => {
                          e.target.classList.toggle("whitespace-nowrap");
                        }}
                        className=" px-3 py-2  satoshi-500 text-sm pr-8 max-w-[180px] whitespace-nowrap text-ellipsis "
                      >
                        {item?.title}
                      </td>
                      <td className=" pr-2 pl-8 py-2 satoshi-500 text-sm">
                        {moment(item?.startDate).format("YYYY-MM-DD")}
                      </td>
                      <td className=" px-2 py-2 satoshi-500 text-sm ">
                        {moment(item?.endDate).format("YYYY-MM-DD")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p>There are currently no scheduled events or meetings.</p>
      )}
    </>
  );
};

export default Meetings;
