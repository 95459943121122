import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import HandoutCard from "../../components/Card/HandoutCard";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getHandsOutData } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import HandoutModal from "../../components/Modal/HandoutModal";
let Once = true;

const Handouts = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handoutData = useSelector((state) => state.handouts.data);

  function handleOpen() {
    setOpen(!open);
  }

  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  useEffect(() => {
    if (isEmpty(handoutData) && Once) {
      setLoading(true);
      Once = false;
      dispatch(getHandsOutData()).then(() => {
        setLoading(false);
      });
    }
  });
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderData text="Employee handouts">
            <ButtonIcon
              icon={<IoMdAdd />}
              onclick={handleOpen}
              text="Add Hangout"
            />
          </HeaderData>
          {handoutData.length ? (
            <CardWrapper extraClasses={"!justify-start"}>
              {handoutData.map((handout, index) => {
                return (
                  <HandoutCard
                    key={index}
                    file={handout.file}
                    type={() => {
                      getFileExtension(handout.file);
                    }}
                  />
                );
              })}
            </CardWrapper>
          ) : (
            <p>No handout found. Please add handout</p>
          )}
          <HandoutModal open={open} handleOpen={handleOpen} />
        </>
      )}
    </>
  );
};

export default Handouts;
