import React, { useEffect, useState } from "react";
import Loader from "../../components/Loading/Loader";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../Redux/Actions";
import StaffModal from "../../components/Modal/StaffModal";
import StaffTableRow from "../../components/Table/StaffTableRow";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import DeleteUser from "../../components/Modal/DeleteUser";
import TextModal from "../../components/Modal/TextModal";
import useHasPermission from "../../Utils/permissionutil";

const Staff = () => {
  const hasPermissionToAdd = useHasPermission("Staffs");
  const hasPermissionToDelete = useHasPermission("Account /manage and delete");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const dashboardData = useSelector((state) => state.dashboard);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteUser, setDeleteUser] = useState({
    userId: null,
    status: false,
  });
  const [textModal, setTextModal] = useState(false);

  const staffsPerPage = 5;

  function handleOpen() {
    if (!open) {
      if (hasPermissionToAdd) {
        setOpen(true);
      } else {
        setTextModal(true);
      }
    } else {
      setOpen(false);
    }
  }

  const handleSearchInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = dashboardData.staff.filter(
      (staff) =>
        staff.firstName.toLowerCase().includes(query) ||
        staff.lastName.toLowerCase().includes(query) ||
        staff.email.toLowerCase().includes(query)
    );
    setFilteredStaff(filtered);
    setPageNumber(1);
  };

  const indexOfLastStaff = pageNumber * staffsPerPage;
  const indexOfFirstStaff = indexOfLastStaff - staffsPerPage;

  const totalPages = Math.ceil(filteredStaff.length / staffsPerPage);

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePrevPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  function handleDeleteModal(userId = null, status = false) {
    if (status === false) {
      setDeleteUser({
        status: false,
        userId: userId,
        role: "staff",
      });
    } else {
      if (hasPermissionToDelete) {
        setDeleteUser({
          status: status,
          userId: userId,
          role: "staff",
        });
      } else {
        setTextModal(true);
      }
    }
  }

  useEffect(() => {
    setFilteredStaff(dashboardData.staff);
  }, [dashboardData.staff]);

  useEffect(() => {
    if (dashboardData.isNotInvoked) {
      setLoading(true);
      dispatch(getDashboardData()).then(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <>
      {textModal && (
        <TextModal
          open={textModal}
          handleClose={() => {
            setTextModal(false);
          }}
          isPermission={true}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          {open ? <StaffModal open={open} handleOpen={handleOpen} /> : null}
          {deleteUser.status && (
            <DeleteUser
              open={deleteUser.status}
              data={deleteUser}
              handleModal={handleDeleteModal}
            />
          )}

          <HeaderData text="Staff">
            <ButtonIcon onclick={handleOpen} icon={<IoMdAdd />} text="Staff" />
          </HeaderData>
          <div className="flex mt-3 justify-between items-center">
            <div className="relative w-[45%] md:w-[40%] lg:w-[35%] text-[--gray] bg-white border rounded-full">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button
                  type="submit"
                  className="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
              <input
                type="search"
                name="q"
                className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit rounded-md pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>

          {filteredStaff.length ? (
            <>
              <div className="dashboardTableWrapper">
                <table className="bg-white w-full min-w-[730px] border-collapse !overflow-x-auto mt-3">
                  <thead>
                    <tr className="border-b border-black">
                      <th className="text-left px-2 pl-4 py-4 text-[--gray] text-sm">
                        Name
                      </th>
                      <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm">
                        Email
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Joined Date
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Phone
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Detail
                      </th>
                      <th className="text-right px-2 py-4 text-[--gray] text-sm"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredStaff
                      .slice(indexOfFirstStaff, indexOfLastStaff)
                      .map((ele, index) => {
                        return (
                          <StaffTableRow
                            key={index}
                            staff={ele}
                            onDeleteStaff={() => {
                              handleDeleteModal(ele?._id, true);
                            }}
                          />
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-center mt-1">
                <div>
                  <h1 className="satoshi-500 text-sm">
                    Total Pages : {totalPages}
                  </h1>
                </div>
                <div>
                  {pageNumber === 1 ? null : (
                    <ButtonSmall text={"Previous"} onClick={handlePrevPage} />
                  )}
                  {pageNumber === totalPages ? null : (
                    <ButtonSmall
                      text={"Next"}
                      onClick={handleNextPage}
                      customClasses={"ml-2"}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <p>You don't have any staff yet</p>
          )}
        </>
      )}
    </>
  );
};

export default Staff;
