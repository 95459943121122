import React, { useState } from "react";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import InputPassword from "../Input/InputPassword";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { addClientSchema } from "../../Schema";
import { useFormik } from "formik";
import ButtonLoading from "../Buttons/ButtonLoading";
import { addNewClient } from "../../Redux/Actions";
import InputRequired from "../Input/InputRequired";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  clientInfo: {
    phone: "",
    // dob: "",
    coachId: "",
    // occupation: "",
    // address: "",
  },
};

const AddClientModal = ({ open, handleOpen }) => {
  const [loading, setLoading] = useState(false);
  const coachData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: addClientSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        values.clientInfo.coachId = coachData.id;
        values.clientInfo.phone = JSON.stringify(values.clientInfo.phone);
        dispatch(addNewClient(values)).then(() => {
          action.resetForm();
          setLoading(false);
          handleOpen();
        });
      },
    });

  return (
    <Modal headerText="Add Client" open={open} onclick={handleOpen}>
      <InputWrapper>
        <InputRequired
          type="text"
          name="firstName"
          id="name"
          label="First Name"
          placeholder="Enter first name"
          value={values.firstName}
          error={errors.firstName}
          touch={touched.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>
      <InputWrapper>
        <InputRequired
          type="text"
          name="lastName"
          id="name"
          label="Last Name"
          placeholder="Enter last name"
          error={errors.lastName}
          touch={touched.lastName}
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>

      <InputWrapper>
        <InputRequired
          type="email"
          name="email"
          id="email"
          label="Email"
          placeholder="Enter email address"
          error={errors.email}
          touch={touched.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>
      <InputWrapper>
        <InputRequired
          type="number"
          name="clientInfo.phone"
          id="phone"
          label="Phone"
          placeholder="Enter Phone number"
          error={errors.clientInfo && errors.clientInfo.phone}
          touch={touched.clientInfo && touched.clientInfo.phone}
          value={values.clientInfo.phone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>
      {/* <InputWrapper>
        <InputRequired
          type="date"
          name="clientInfo.dob"
          id="dob"
          label="Date Of Birth"
          placeholder="Enter Phone number"
          error={errors.clientInfo && errors.clientInfo.dob}
          touch={touched.clientInfo && touched.clientInfo.dob}
          value={values.clientInfo.dob}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper> */}
      {/* <InputWrapper>
        <InputRequired
          type="text"
          name="clientInfo.occupation"
          id="phone"
          label="Occupation"
          placeholder="Occupation"
          error={errors.clientInfo && errors.clientInfo.occupation}
          touch={touched.clientInfo && touched.clientInfo.occupation}
          value={values.clientInfo.occupation}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper>
      <InputWrapper>
        <InputRequired
          type="text"
          name="clientInfo.address"
          id="phone"
          label="Address"
          placeholder="Enter address"
          error={errors.clientInfo && errors.clientInfo.address}
          touch={touched.clientInfo && touched.clientInfo.address}
          value={values.clientInfo.address}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputWrapper> */}
      <InputPassword
        customClasses="mt-3"
        name="password"
        id="password"
        placeholder="Enter password for staff"
        label="Password"
        value={values.password}
        error={errors.password}
        touch={touched.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div className="w-full flex justify-center items-center gap-5 mt-3">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              text="Cancel"
              onClick={handleOpen}
              customClasses={"!bg-white !text-[--primary]"}
            />
            <ButtonSmall text="Add Client" onClick={handleSubmit} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddClientModal;
