import React from "react";
import Dropzone from "react-dropzone";

const FileUpload = ({
  selectedFiles,
  headerText,
  description,
  type,
  icon,
  error,
  onDrop,
}) => {
  return (
    <>
    <div className="flex-grow basis-48">
      <Dropzone onDrop={onDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps({
                className: "dropzone",
              })}
            >
              <input {...getInputProps()} />
              {error && (
                <div className="text-center text-red-500">
                  <p>{error}</p>
                </div>
              )}
              {selectedFiles ? (
                <div className="mx-auto">
                  {type === "video" ? (
                    <video className="h-32 w-52" controls>
                      <source
                        src={URL.createObjectURL(selectedFiles)}
                        type={selectedFiles.type}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      className="h-24 w-24"
                      src={URL.createObjectURL(selectedFiles)}
                      alt={`Selected file preview`}
                    />
                  )}
                </div>
              ) : (
                <div className="mx-auto text-7xl">{icon}</div>
              )}
              <h3 className="satoshi-500 mt-2 text-[12px] text-center  md:text-[14px] xl:text-[16px]">
                {headerText}{" "}
                <span className="text-blue-700 satoshi-500">browse</span>
              </h3>
              <p className="text-[--gray] text-[14px]">{description}</p>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
    </>
  );
};

export default FileUpload;
