import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import FluidCard from "../../components/Card/FluidCard";
import TablesWrapper from "../../components/Wrapper/TablesWrapper";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import ListRow from "../../components/Table/ListRow";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loading/Loader";
import { coachReport, getDashboardData } from "../../Redux/Actions";
import StaffModal from "../../components/Modal/StaffModal";
import MyCalender from "../../components/calender/MyCalender";
import useHasPermission from "../../Utils/permissionutil";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
let Once = true;

const Dashboard = () => {
  const hasPermissionToView = useHasPermission("Meeting");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard);
  const [open, setOpen] = useState(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState(true);
  function handleOpen() {
    setOpen(!open);
  }

  const generatePdf = (data, option) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = data;

    // Append the container to the body (it will be removed after PDF generation)
    document.body.appendChild(tempContainer);
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    html2canvas(tempContainer, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.75);
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
        }

        if (option === "view") {
          const blob = pdf.output("blob");
          const url = URL.createObjectURL(blob);
          const newWindow = window.open(url, "_blank");
          if (newWindow) {
            newWindow.focus();
          } else {
            alert("Please allow popups for this website");
          }
        } else {
          pdf.save("admin_report.pdf");
        }
      })
      .catch((error) => {
        console.error("Error generating PDF", error);
      })
      .finally(() => {
        document.body.removeChild(tempContainer);
      });
  };

  async function handleDownloadOrView(option) {
    setLoading(true);
    try {
      const response = await coachReport();
      generatePdf(response.data, option);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (dashboardData.isNotInvoked) {
        setLoading(true);
        dispatch(getDashboardData()).then(() => {
          setIsServiceAvailable(hasPermissionToView);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        });
      }
    }
  }, []);

  return (
    <>
      <HeaderData text="Dashboard">
        <button
          onClick={() => {
            handleDownloadOrView("download");
          }}
          className="satoshi-500 px-2 sm:px-4 md:px-7 py-1 md:py-2 text-[12px] sm:text-[14px] flex justify-center items-center border rounded-lg gap-1 sm:gap-3 text-white bg-[--primary]"
        >
          Download Report
        </button>
        <button
          onClick={() => {
            handleDownloadOrView("view");
          }}
          className="satoshi-500 px-2 sm:px-4 md:px-7 py-1 md:py-2 text-[12px] sm:text-[14px] flex justify-center items-center border rounded-lg gap-1 sm:gap-3 text-white bg-[--primary]"
        >
          View Report
        </button>
      </HeaderData>
      {loading ? (
        <Loader />
      ) : (
        <>
          {open ? <StaffModal open={open} handleOpen={handleOpen} /> : null}

          <CardWrapper extraClasses={"!items-stretch"}>
            <FluidCard
              url={"/"}
              withGraph={true}
              text="Money made from clients"
              value={`$ ${dashboardData?.totalEarnings}`}
            />
            <FluidCard
              url={"/staffs"}
              withGraph={true}
              text="No. of staffs"
              value={dashboardData?.staffCount}
            />
            <FluidCard
              url={"/clients"}
              withGraph={true}
              text="No. of clients"
              value={dashboardData?.clientsCount}
            />
            <FluidCard
              url={"/programs"}
              withGraph={true}
              text="No. of programs"
              value={dashboardData?.totalPrograms}
            />
          </CardWrapper>
          <div className="mt-3 bg-white px-4 py-3 rounded-md">
            <MyCalender serviceAvailable={isServiceAvailable} />
          </div>
          <TablesWrapper customClasses={"lg:flex-row"}>
            {dashboardData?.clients.length ? (
              <div className="w-full lg:w-[75.5%] px-3 bg-white rounded-lg">
                <TableHeader text="Latest Clients" withSelect={false} />
                <div className="dashboardTableWrapper">
                  <table className="w-full min-w-[600px] border-collapse !overflow-x-auto">
                    <thead className="border-t border-b text-sm">
                      <tr>
                        <th style={thStyle} className="pl-2">
                          Name
                        </th>
                        <th style={thStyle}>Email</th>
                        <th style={thStyle}>Joining Date</th>
                        {/* <th style={thStyle} className="text-sm w-[60px]">
                          Package
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData.clients.map((client, index) => {
                        return <TableRow key={index} clientData={client} />;
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <p>No clients found</p>
            )}

            <div className="w-full lg:w-[24.5%] bg-white rounded-lg pl-2">
              <TableHeader text="Staff" customClasses="!pl-3" />
              {/* <div className="border-t w-full px-3">
                <div className="w-full rounded-md my-3 flex justify-between items-center gap-2 bg-[--gray-light] py-2 px-3">
                  {["coaches", "staff"].map((item, index) => {
                    return (
                      <ButtonModal
                        text={item}
                        key={index}
                        isActive={
                          item.toLocaleLowerCase() ===
                          activeBtn.toLocaleLowerCase()
                        }
                        onClick={() => {
                          setActiveBtn(item);
                        }}
                      />
                    );
                  })}
                </div>
              </div> */}

              <div className="w-full px-3">
                {dashboardData.staff.length ? (
                  dashboardData.staff.map((staff, index) => {
                    return <ListRow data={staff} key={index} />;
                  })
                ) : (
                  <p>No staff found</p>
                )}
              </div>
            </div>
          </TablesWrapper>
        </>
      )}
    </>
  );
};

export default Dashboard;

const thStyle = {
  textAlign: "left",
  padding: "5px 3px",
  color: "var(--gray)",
};
