import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import MediaCard from "../../components/Card/MediaCard";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getProgramData } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import AddProgram from "../../components/Form/AddProgram";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import UnavailableService from "../../components/Message/UnavailableService";
import TextModal from "../../components/Modal/TextModal";
import useHasPermission from "../../Utils/permissionutil";
let Once = true;

const Programs = () => {
  const hasPermission = useHasPermission("Programs");
  const hasPermissionToAdd = useHasPermission("Create Programs");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [programForm, setProgramForm] = useState(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState(
    useSelector((state) => state.services.programService)
  );
  const programData = useSelector((state) => state.program.data);
  const [textModal, setTextModal] = useState(false);

  function handleProgramForm() {
    if (!programForm) {
      if (hasPermissionToAdd) {
        setProgramForm(true);
      } else {
        setTextModal(true);
      }
    } else {
      setProgramForm(false);
    }
  }

  useEffect(() => {
    if (Once) {
      if (hasPermission) {
        Once = false;
        if (isEmpty(programData)) {
          setLoading(true);
          dispatch(getProgramData())
            .then((res) => {
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      } else {
        setIsServiceAvailable(false);
      }
    }
  });
  return (
    <>
      {textModal && (
        <TextModal
          isPermission={true}
          open={textModal}
          handleClose={() => {
            setTextModal(false);
          }}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderData text="Courses">
            {isServiceAvailable ? (
              <ButtonIcon
                onclick={handleProgramForm}
                icon={<IoMdAdd />}
                text="Add a course"
              />
            ) : null}
          </HeaderData>
          {programForm ? (
            <AddProgram toggleProgramForm={handleProgramForm} />
          ) : isServiceAvailable ? (
            <>
              {programData.length ? (
                <CardWrapper extraClasses="!justify-start !items-stretch !gap-3">
                  {programData.map((program, index) => {
                    return (
                      <MediaCard key={index}>
                        <div className="w-full">
                          <img
                            className="w-full h-[170px] rounded-t-md"
                            src={
                              program?.photo?.startsWith("https://") ? program?.photo : program?.photo !== "" ? process.env.REACT_APP_IMAGE_URL.concat(program?.photo) : null
                            }
                            alt="card banner"
                          />
                        </div>
                        <div className="flex justify-between items-center px-2 mt-2 relative">
                          <h4 className="satoshi-900 text-lg capitalize">
                            {program.title}
                          </h4>
                          {/* <div className="text-[20px] flex justify-center items-center gap-2">
                            <BiEdit
                              onClick={onEdit}
                              className=" text-green-600 cursor-pointer"
                            />
                            <MdDelete
                              onClick={() => {
                                setDeleteProgram({
                                  modal: true,
                                  programId: program._id,
                                });
                              }}
                              className=" text-red-600 cursor-pointer"
                            />
                          </div> */}
                        </div>
                        <div className="px-2">
                          <div className="w-full pl-[2px] min-h-[110px] max-h-[110px] text-ellipsis overflow-y-hidden text-[10px] sm:text-[12px] md:text-[14px] text-[--gray-dark] pb-3">
                            {parse(parse(program.description))}
                          </div>
                        </div>
                        <div className="w-full text-right pb-2 pt-1 pr-4">
                          <Link
                            to={`/programs-detail/${program._id}`}
                            className="text-[--primary] text-[13px] border px-6 py-1 transition-all ease-in-out duration-500 hover:text-white hover:bg-[--primary] border-[--primary] satoshi-500"
                          >
                            View Detail
                          </Link>
                        </div>
                      </MediaCard>
                    );
                  })}
                </CardWrapper>
              ) : (
                <p>
                  You don't have added any program yet. Please add a program.
                </p>
              )}
            </>
          ) : (
            <UnavailableService />
          )}
        </>
      )}
    </>
  );
};

export default Programs;
