import React, { useEffect, useRef, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import DashboardFormWrapper from "../../components/Wrapper/DashboardFormWrapper";
import InputWrapper from "../../components/Wrapper/InputWrapper";
import InputSmall from "../../components/Input/InputSmall";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import { FiCamera } from "react-icons/fi";
import { updateSetting, uploadPhoto } from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import InputCopy from "../../components/Input/InputCopy";
import StripeCard from "../../components/Card/StripeCard";
import defaultimg from "../../Assets/default-profile.png";
import useHasPermission from "../../Utils/permissionutil";
import UnavailableService from "../../components/Message/UnavailableService";
import ChangePasswordModal from "../../components/Modal/changePasswordModal";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import ReactCrop, { centerCrop, convertToPercentCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from "react-modal";
import cropImageNow from "../../Utils/cropProfileImage";
import cropBrandImage from "../../Utils/cropBrandImage";
import cropProfileImage from "../../Utils/cropProfileImage";
// import UpdateEmail from "../../components/Modal/UpdateEmail";


const ASPECT_RATIO = 1;
const MIN_DIMENSION = 250;
const Settings = () => {
  const imgRef = useRef(null);
  const PrevCanvasRef = useRef(null);
  const hasPermission = useHasPermission("Profile");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customError, setCustomError] = useState({
    firstName: "",
    lastName: "",
  });
  const [error, setError] = useState({
    profileImage: "",
    brandImage: ""
  });
  const [image, setImage] = useState({
    profileImage: "",
    brandImage: ""
  });
  const [src, setSrc] = useState({
    profileImage: "",
    brandImage: ""
  });
  const [output, setOutput] = useState({
    profileImage: "",
    brandImage: ""
  }); // Cropped image result
  const [isModalOpen, setIsModalOpen] = useState({
    profileImage: "",
    brandImage: ""
  });
  const [crop, setCrop] = useState({
    profileImage: "",
    brandImage: ""
  });
  
  const [passwordModal, setPasswordModal] = useState(false);
  // const [updateEmailModal, setUpdateEmailModal] = useState(false);
  // const [userCoachId, setUserCoachId] = useState(null);
  const [data, setData] = useState(useSelector((state) => state.auth.userData));
  const dispatch = useDispatch();

  function handleInputs(e) {
    const { name, value } = e.target;
    setCustomError((pre) => {
      return {
        ...pre,
        [name]: "",
      };
    });
    setData((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    if (!data.firstName || !data.lastName) {
      setCustomError({
        firstName: !data.firstName ? "Field is required" : "",
        lastName: !data.lastName ? "Field is required" : "",
      });
      return;
    }

    setLoading(true);

    if (output.profileImage) {
      uploadPhoto({ photo: dataURLtoFile(output.profileImage, 'profileImage.png') })
        .then((responseData) => {
          data.photo = responseData.data.photos[0];
          updateData();
        })
        .catch(() => setLoading(false));
    }

    if (output.brandImage) {
      uploadPhoto({ photo: dataURLtoFile(output.brandImage, 'brandImage.png') })
        .then((responseData) => {
          data.coachInfo.BrandLogo = responseData.data.photos[0];
          updateData();
        })
        .catch(() => setLoading(false));
    }

    // If no images need updating
    if (!output.profileImage && !output.brandImage) {
      updateData();
    }
  }

  function updateData() {
    const body = {
      firstName: data.firstName,
      lastName: data.lastName,
      photo: data.photo,
      coachInfo: { BrandLogo: data.coachInfo.BrandLogo },
    };

    dispatch(updateSetting(body)).then(() => {
      setLoading(false);
    });
  }


  function handlePasswordModal() {
    setPasswordModal(!passwordModal);
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract the file type from the base64 URL
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime }); // Use extracted mime type
  };

  // profile
  function handleProfileChange(e) {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
      imageElement.addEventListener('load', (e) => {
        if (error) setError({
          profileImage: '',
          brandImage: ''
        });
        const { naturalWidth, naturalHeight } = e.currentTarget;
        // if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
        //   setError('Image is too small, please upload a larger image');
        //   setSrc('');
        //   return;
        // }
      })
      setSrc({ ...src, profileImage: imageUrl });
      setIsModalOpen({
        ...isModalOpen,
        profileImage: true
      });
    });
    reader.readAsDataURL(selectedFile);
  }

  // brandUrl
  function handleBrandurlChange(e) {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
      imageElement.addEventListener('load', (e) => {
        if (error) setError({
          profileImage: '',
          brandImage: ''
        });
        const { naturalWidth, naturalHeight } = e.currentTarget;
        // if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
        //   setError('Image is too small, please upload a larger image');
        //   setSrc('');
        //   return;
        // }
      })
      // setSrc({ ...src, profile: imageUrl });
      setSrc({ ...src, brandImage: imageUrl });
      setIsModalOpen({
        ...isModalOpen,
        brandImage: true
      });
    });
    reader.readAsDataURL(selectedFile);
  }

  const onProfileImageLoaded = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop({
      ...crop,
      profileImage: centeredCrop
    })
  }

  const onBrandImageLoaded = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop({
      ...crop,
      brandImage: centeredCrop
    })
  }


  // function handleEmailModal() {
  //   setUserCoachId(data?._id)
  //   setUpdateEmailModal(!updateEmailModal);
  // }


  return (
    <>
      <HeaderData text="Profile Setting">
        <ButtonSmall
          text="Upgrade Package"
          onClick={() => {
            navigate("/upgrade-package");
          }}
        />
      </HeaderData>
      <StripeCard />
      {hasPermission ? (
        <div className="w-full">
          <DashboardFormWrapper customClasses="!bg-white !mt-2">
            <div className="flex justify-center items-center  p-3">
              <label htmlFor="profile" className="cursor-pointer relative py-3">
                <p className="block mb-1 pl-[2px] font-medium text-[14px] capitalize text-center">
                  Profile Picture
                </p>
                <div className="w-28 h-28 rounded-full border">
                  {
                    output.profileImage ? (
                      <img
                        className="w-full h-full"
                        src={output.profileImage}
                        alt="profile "
                        loading="lazy"
                      />
                    ) : (
                      <img
                        className="w-full h-full"
                        src={
                          data?.photo?.startsWith("https://")
                            ? data?.photo : data?.photo !== "" ? process.env.REACT_APP_IMAGE_URL.concat(data?.photo)
                            : defaultimg
                        }
                        alt="profile "
                        loading="lazy"
                      />
                    )
                  }

                </div>
                <input
                  onChange={handleProfileChange}
                  type="file"
                  id="profile"
                  hidden
                  accept="image/*"
                />
                <div className="w-12 h-12 text-2xl flex justify-center items-center rounded-full absolute top-[70%] right-[0%] bg-[#2F80ED] text-white">
                  <FiCamera />
                </div>
              </label>

              <label
                htmlFor="logo"
                className="cursor-pointer relative py-3 ml-10  sm:ml-14 md:ml-18 xl:ml-20"
              >
                <p className="block mb-1 pl-[2px] font-medium text-[14px] capitalize text-center">
                  Brand Logo
                </p>
                <div className="w-28 h-28 rounded-full border">
                  {
                    output.brandImage ? (
                      <img
                        className="w-full h-full"
                        src={output.brandImage}
                        alt="Logo Here "
                        loading="lazy"
                      />
                    ) : (
                      <img
                        className="w-full h-full"
                        src={
                          data?.coachInfo?.BrandLogo?.startsWith("https://")
                            ? data?.coachInfo?.BrandLogo : data?.coachInfo?.BrandLogo !== "" ?  process.env.REACT_APP_IMAGE_URL.concat(data?.coachInfo?.BrandLogo)
                            : defaultimg
                        }
                        alt="Logo Here "
                        loading="lazy"
                      />
                    )
                  }

                </div>
                <input
                  onChange={handleBrandurlChange}
                  type="file"
                  id="logo"
                  hidden
                  accept="image/png"
                />
                <div className="w-12 h-12 text-2xl flex justify-center items-center rounded-full absolute top-[70%] right-[0%] bg-[#2F80ED] text-white">
                  <FiCamera />
                </div>
              </label>
            </div>
            <InputWrapper>
              <InputSmall
                id="firstName"
                name="firstName"
                value={data?.firstName}
                onChange={handleInputs}
                type="text"
                label="First Name"
                placeholder="John"
                touch={true}
                error={customError.firstName}
              />
              <InputSmall
                id="lastName"
                name="lastName"
                value={data?.lastName}
                onChange={handleInputs}
                type="text"
                label="Last Name"
                placeholder="Doe"
                touch={true}
                error={customError.lastName}
              />
            </InputWrapper>
            <InputWrapper>
              <InputSmall
                id="email"
                name="email"
                value={data?.email}
                type="email"
                label="Email"
                placeholder="johndoe@gmail.com"
                disable={true}
              />
            </InputWrapper>
            <InputWrapper>
              <InputCopy
                value={`https://www.client.smartfbp.com/signup/${data.coachInfo.endpoint}`}
              />
            </InputWrapper>
            <div className="mt-4 w-full pt-4 pb-6 flex justify-center items-center gap-3">
              {loading ? (
                <ButtonLoading />
              ) : (
                <>
                  {/* <ButtonSmall
                    onClick={handleEmailModal}
                    text="Update Email"
                  /> */}
                  <ButtonSmall
                    onClick={handlePasswordModal}
                    text="Change Password"
                  />
                  <ButtonSmall
                    text="Update"
                    onClick={handleFormSubmit} />
                </>
              )}
            </div>
          </DashboardFormWrapper>
        </div>
      ) : (
        <UnavailableService serviceName="View Profile" />
      )}

      {passwordModal && (
        <ChangePasswordModal
          open={passwordModal}
          handleModal={handlePasswordModal}
        />
      )}
      {/* {updateEmailModal && (
        <UpdateEmail
          userCoachId={userCoachId}
          open={updateEmailModal}
          handleModal={handleEmailModal}
        />
      )} */}

      {/*profile Crop Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen.profileImage}
        onRequestClose={() => setIsModalOpen({
          ...isModalOpen, profileImage: false,
        })}
        className={`w-[80%] sm:w-[70%] md:w-[50%] mx-auto rounded-md mt-20 bg-white shadow-lg flex flex-col justify-center items-center py-5`}
      >
        <h2 className="text-lg font-bold">Crop Image</h2>
        <div className="py-3">
          <button
            className="mr-4 bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => {
              cropProfileImage(
                imgRef.current,
                PrevCanvasRef.current,
                convertToPixelCrop(
                  crop.profileImage,
                  imgRef.current.width,
                  imgRef.current.height
                )
              );
              if (crop.width && crop.height) {
                const dataUrl = PrevCanvasRef.current.toDataURL();
                setOutput({ ...output, profileImage: dataUrl });
                // uploadPhoto({ photo: output.profileImage }).then((responseData) => {
                //   data.profileImage = responseData.data.photos[0];
                //   // data.coachInfo.BrandLogo = responseData.data.photos[0];
                //   setLoading(false);
                // });
              }
              setIsModalOpen({
                ...isModalOpen,
                profileImage: false
              })
            }}
          >
            Crop and Save
          </button>
          <button
            className="bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => setIsModalOpen({
              ...isModalOpen,
              profileImage: false
            })}
          >
            Cancel
          </button>
        </div>
        {
          error?.profileImage && <p className="text-red-600">{error.profileImage}</p>
        }
        {src && (
          <ReactCrop
            src={src}
            onImageLoaded={(img) => setImage(prev => ({ ...prev, profileImage: img }))}
            crop={crop.profileImage}
            // onChange={
            //   (pixelCrop, percentCrop) => setCrop({
            //     ...crop,
            //     profileImage: percentCrop
            //   })
            // }
            onChange={(pixelCrop, percentCrop) => setCrop(prev => ({ ...prev, profileImage: percentCrop }))}
            keepSelection={true}
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={src.profileImage}
              alt="imageUpload"
              className=""
              onLoad={onProfileImageLoaded}
            />

          </ReactCrop>
        )}

        {crop && (
          <canvas
            ref={PrevCanvasRef}
            className="mt-4"
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 150,
              height: 150,
            }}
          />
        )}

      </Modal >
      {/*brand Url Crop Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen.brandImage}
        onRequestClose={() => setIsModalOpen({
          ...isModalOpen, brandImage: false
        })}
        className={`w-[80%] sm:w-[70%] md:w-[50%] mx-auto rounded-md mt-20 bg-white shadow-lg flex flex-col justify-center items-center py-5`}
      >
        <h2 className="text-lg font-bold">Crop Image</h2>
        <div className="py-3">
          <button
            className="mr-4 bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => {
              cropBrandImage(
                imgRef.current,
                PrevCanvasRef.current,
                convertToPixelCrop(
                  crop.brandImage,
                  imgRef.current.width,
                  imgRef.current.height
                )
              );
              // const dataUrl = PrevCanvasRef.current.toDataURL();
              // setOutput({
              //   ...output,
              //   brandImage: dataURLtoFile(dataUrl, 'brandImage.png')
              // });
              if (crop.width && crop.height) {
                const dataUrl = PrevCanvasRef.current.toDataURL();
                setOutput({ ...output, brandImage: dataUrl });
              }

              setIsModalOpen({
                ...isModalOpen,
                brandImage: false
              })
            }}
          >
            Crop and Save
          </button>
          <button
            className="bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => setIsModalOpen({
              ...isModalOpen,
              brandImage: false
            })}
          >
            Cancel
          </button>
        </div>
        {
          error?.brandImage && <p className="text-red-600">{error.brandImage}</p>
        }
        {src && (
          <ReactCrop
            src={src}
            onImageLoaded={(img) => setImage(prev => ({ ...prev, brandImage: img }))}
            crop={crop.brandImage}
            // onChange={
            //   (pixelCrop, percentCrop) => setCrop({
            //     ...crop,
            //     brandImage: percentCrop
            //   })
            // }
            onChange={(pixelCrop, percentCrop) => setCrop(prev => ({ ...prev, brandImage: percentCrop }))}
            // circularCrop
            keepSelection={true}
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={src.brandImage}
              alt="imageUpload"
              className=""
              onLoad={onBrandImageLoaded}
            />

          </ReactCrop>
        )}

        {crop && (
          <canvas
            ref={PrevCanvasRef}
            className="mt-4"
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 150,
              height: 150,
            }}
          />
        )}

      </Modal >
    </>
  );
};

export default Settings;

