import React from "react";

const HeaderData = ({ text, children, customClassess }) => {
  return (
    <div
      className={`flex justify-between items-center gap-2 ${customClassess}`}
    >
      <h1 className="text-sm sm:text-xl satoshi-900 text-black capitalize">
        {text}
      </h1>
      <div className="flex justify-between items-center gap-3">{children}</div>
    </div>
  );
};

export default HeaderData;
