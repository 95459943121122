import moment from "moment";
import React from "react";

const TaskAssociationDetail = ({ data, handleReOpen }) => {
  const { staff, taskId } = data;
  return (
    <div className="py-3 bg-white rounded-lg flex-grow basis-72 mediaCardShadow">
      <div className="border-b">
        <h1 className="satoshi-700 capitalize text-[16px] pl-2 pb-2">
          {staff?.staffPersonalInfo.firstName +
            " " +
            staff?.staffPersonalInfo.lastName}
        </h1>
      </div>
      {staff?.status?.length
        ? staff.status.map((item, index) => {
            return (
              <div className="p-3">
                <div
                  key={index}
                  className="w-full flex justify-between items-center pt-1"
                >
                  <p className="satoshi-500 text-[14px]">Status</p>
                  <p className="capitalize text-[14px]">
                    {item.taskStatus.toUpperCase()}
                  </p>
                </div>
                <div className="w-full flex justify-between items-center">
                  <p className="satoshi-500 text-[14px]">Date </p>
                  <p className="text-[14px]">
                    {moment(parseInt(item.date)).format("DD MMM, YYYY")}
                  </p>
                </div>
                {item.note ? (
                  <div className="w-full flex flex-col">
                    <p className="satoshi-500 text-[14px]">Note</p>
                    <p className="capitalize text-[14px]">{item.note}</p>
                  </div>
                ) : null}
                {index === staff?.status?.length - 1 &&
                  item?.taskStatus.toLowerCase() === "completed" && (
                    <div className="w-full flex justify-end items-center mt-2">
                      <button
                        onClick={() => {
                          handleReOpen(taskId, staff?.staffid, "single");
                        }}
                        className="px-3 py-1 border border-red-500 bg-red-500 text-white rounded-md satoshi-700 text-[14px] transition-all ease-in-out duration-500 hover:bg-white hover:text-red-600"
                      >
                        Re-Open Task
                      </button>
                    </div>
                  )}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default TaskAssociationDetail;
