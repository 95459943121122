import { MdOutlineDashboard } from "react-icons/md";
import { FiMail, FiUser, FiDollarSign } from "react-icons/fi";
import { AiTwotoneSetting } from "react-icons/ai";
import { PiNoteDuotone } from "react-icons/pi";
import { LuFileVideo } from "react-icons/lu";
import { TfiStatsUp } from "react-icons/tfi";
// import { MdOutlinePictureAsPdf } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { TiClipboard } from "react-icons/ti";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { PiPackageLight } from "react-icons/pi";

export const dashboardNavItems = [
  {
    label: "Dashboard",
    url: "/",
    icon: <MdOutlineDashboard />,
  },
  {
    label: "Inbox",
    url: "/inbox",
    icon: <FiMail />,
  },
  {
    label: "Media",
    url: "/media",
    icon: <LuFileVideo />,
  },
  // {
  //   label: "Handouts",
  //   url: "/handout",
  //   icon: <MdOutlinePictureAsPdf />,
  // },

  {
    label: "Courses",
    url: "/programs",
    icon: <PiNoteDuotone />,
  },
  {
    label: "Clients",
    url: "/clients",
    icon: <FiUser />,
  },
  {
    label: "Packages",
    url: "/packages",
    icon: <PiPackageLight />,
  },
  {
    label: "Reports",
    url: "/reports",
    icon: <TfiStatsUp />,
  },
  {
    label: "User Goals",
    url: "/goals",
    icon: <HiOutlineClipboardCheck />,
  },
  {
    label: "Tasks",
    url: "/tasks",
    icon: <TiClipboard />,
  },
  {
    label: "Staff",
    url: "/staffs",
    icon: <FiUsers />,
  },
  {
    label: "Payments",
    url: "/payment",
    icon: <FiDollarSign />,
  },
  {
    label: "settings",
    url: "/setting",
    icon: <AiTwotoneSetting />,
  },
];

export const PlanLevels = [
  {
    header: "Plan Level",
    description:
      "This is for content specific to a plan and will only be shown to participants of the particular plan",
  },
  {
    header: "Participant Level",
    description:
      "Participant level content is content for participants such as handouts. If 'display to user' is selected this content is available to all participants regardless of plan.",
  },
  {
    header: "Advisor Level",
    description:
      "This is a classification distinction but works the same as participant level content if 'display to users' is selected. Select this for content about your firm.",
  },
  {
    header: "Display to Users",
    description:
      "If checked then this content will be available for users under the 'Library' menu link as long as it is also compliance approved.",
  },
];

export const PackageCheckBoxes = [
  {
    label: "Test 1",
    name: "test1",
    id: "test1",
    value: "test1",
  },
  {
    label: "Test 2",
    name: "test2",
    id: "test2",
    value: "test2",
  },
  {
    label: "Test 3",
    name: "test3",
    id: "test3",
    value: "test3",
  },
  {
    label: "Test 4",
    name: "test4",
    id: "test4",
    value: "test4",
  },
  {
    label: "Test 5",
    name: "test5",
    id: "test5",
    value: "test5",
  },
  {
    label: "Test 6",
    name: "test6",
    id: "test6",
    value: "test6",
  },
  {
    label: "Test 7",
    name: "test7",
    id: "test7",
    value: "test7",
  },
  {
    label: "Test 8",
    name: "test8",
    id: "test8",
    value: "test8",
  },
  {
    label: "Test 9",
    name: "test9",
    id: "test9",
    value: "test9",
  },
  {
    label: "Test 10",
    name: "test10",
    id: "test10",
    value: "test10",
  },
  {
    label: "Test 11",
    name: "test11",
    id: "test11",
    value: "test11",
  },
  {
    label: "Test 12",
    name: "test12",
    id: "test12",
    value: "test12",
  },
];
