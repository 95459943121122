import React from "react";
import tick from "../../Assets/tick.png";
import ButtonLarge from "../Buttons/ButtonLarge";
import { LuCheck } from "react-icons/lu";

const PackageCard = ({
  packageName,
  description,
  price,
  subscriptionTime,
  isActive,
  susbcriptionDetail,
  self,
  onEdit,
  onDelete,
  packageStatus,
  onHandleSubscribe,
  onAssign,
  active,
}) => {
  return (
    <div
      id="packageCard"
      className={`px-8 py-4 rounded-md flex-grow basis-[270px] max-w-[400px] ${
        active ? "bg-green-100" : "bg-white"
      }`}
    >
      <div className="w-full py-3 text-5xl ">
        <img src={tick} className="w-[33px] h-[24px]" alt="" />
      </div>
      <div className="mt-2">
        <h5 className="satoshi-500 uppercase">{packageName}</h5>
      </div>
      <div className="mt-1text-xs text-[--gray] satoshi-500">
        <p>{description}</p>
      </div>
      <div className="mt-2">
        <h5 className="satoshi-900 text-2xl">{price}</h5>
      </div>
      <div className="mt-1text-xs text-[--gray] satoshi-500">
        <p>{subscriptionTime}</p>
      </div>
      <div className="w-full my-6 gap-2">
        {self ? (
          <>
            <div className="flex justify-between items-center gap-2">
              <ButtonLarge
                text="Edit"
                onClick={onEdit}
                customClasses="!border-green-800 !bg-green-800 transition-all ease-in-out duration-300 hover:!text-green-700 hover:!bg-white !py-2"
              />
              <ButtonLarge
                text="Delete"
                onClick={onDelete}
                customClasses="!border-red-800 !bg-red-800 transition-all ease-in-out duration-300 hover:!text-red-700 hover:!bg-white !py-2"
              />
            </div>
            <div className="w-full mt-3">
              <ButtonLarge text="Assign Package" onClick={onAssign} />
            </div>
          </>
        ) : isActive ? (
          <ButtonLarge
            text="Current"
            customClasses="!bg-white !text-[--primary]"
          />
        ) : (
          <ButtonLarge
            onClick={onHandleSubscribe}
            text={packageStatus ? "Upgrade" : "Subscribe"}
          />
        )}
      </div>
      {susbcriptionDetail?.map((item, index) => {
        return (
          <div
            key={index}
            className="w-full flex justify-start items-center gap-4 my-2"
          >
            <LuCheck className="text-[#16A5B6] text-2xl" />
            <p className="satoshi-500 capitalize">{item}</p>
          </div>
        );
      })}
    </div>
  );
};

export default PackageCard;
