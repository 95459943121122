const initialState = {
  data: [],
};

export const taskReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_TASKS":
      return {
        ...state,
        data: payload.data,
      };
    case "ADD_TASKS":
      return {
        ...state,
        data: [...state.data, payload],
      };
    case "UPDATE_TASK":
      return {
        ...state,
        data: state.data.map((task) =>
          task.id === payload.id ? { ...task, ...payload } : task
        ),
      };

    case "DELETE_TASK":
      const updatedList = state.data.filter((task) => task.id !== payload.id);
      return {
        ...state,
        data: updatedList,
      };
    default:
      return state;
  }
};
