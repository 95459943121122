import axios from "axios";
import { successToast, errorToast } from "../../Utils/Toast";
import api from "./axiosInstance";
import { removeToken, setToken } from "../../Utils/tokenutil";
import { formatDateArray, formatDateSingle } from "../../Utils/DataGenerator";
// import authReducer from "../reducers/authReducer";
let coachId = localStorage.getItem("coachId") || "";

// export const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   withCredentials: true,
//   headers: {
//     "Content-type": "application/json",
//     Accept: "application/json",
//   },
// });

export const login = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/login", payload);
    coachId = response.data.data._id;
    setToken(response.data);
    dispatch({ type: "LOGIN", payload: response.data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = () => async (dispatch) => {
  try {
    const response = await api.post("/logout");
    removeToken();
    // dispatch({ type: "LOGOUT" });
    return response;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const signup = async (payload) => {
  try {
    const response = await api.post("/signup", payload);
    // successToast("Coach registered successfully");
    return response;
  } catch (error) {
    errorToast(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
};

export const emailVerification = async (payload) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/forgotPassword",
      payload
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode === 401) {
      errorToast("Invalid email address");
      throw error;
    } else {
      console.log(error)
      throw error;
    }
  }
};

export const verifyOtp = async (userId, payload) => {
  // try {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + `/verifyCode/${userId}`,
    payload
  );
  // console.log("Response ===>", response.data);
  return response.data;
  // } catch (error) {
  //   if (error?.response?.data?.statusCode === 400) {
  //     errorToast("Incorrect OTP");
  //     throw error;
  //   } else {
  //     console.log(error)
  //     throw error;
  //   }
  // }
};

export const changePasswordVerify = async (payload) => {
  try {
    const response = await api.patch("/changePasswordVerify", {
      userId: payload.userId,
      password: payload.password,
    });
    successToast("Password updated successfully");
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const updateSetting = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/updateMe", payload);
    dispatch({ type: "UPDATE_USER", payload: payload });
    successToast("Setting updated successfully");
    return;
  } catch (error) {
    console.log(error)
  }
};

export const getClientDetail = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/ClientAllInfo?userId=${payload}`);
    dispatch({ type: "GET_CLIENT_DETAIL", payload: response.data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStaffDetail = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/StaffAllInfo?staffId=${payload}`);
    dispatch({ type: "GET_STAFF_DETAIL", payload: response.data });

    return response.data;
  } catch (error) {
    console.log("Error =>", error);
    console.log(error)
    throw error;
  }
};

export const updateUserSettings = (payload, userId) => async (dispatch) => {
  try {
    const response = await api.patch(`/updateUser/${userId}`, payload);
    successToast("Client settings updated successfully");
    dispatch(getDashboardData());
  } catch (error) {
    console.log("Error updating client profile", error);
    console.log(error)
  }
};

//  ===============================================
//              dashboard actions
//  ===============================================
export const getDashboardData = () => async (dispatch) => {
  try {
    const response = await api.get(`/coachDashboard/${coachId}`);
    dispatch(getAllEvents());
    dispatch({ type: "FETCH_DATA", payload: response.data.data });
    return;
  } catch (error) {
    console.log(error);
  }
};

export const getAllEvents = () => async (dispatch) => {
  try {
    const response = await api.get(`/getSchedule/Coach/${coachId}`);
    dispatch({
      type: "FETCH_EVENTS",
      payload: formatDateArray(response?.data?.data),
    });
    return;
  } catch (error) {
    throw error;
  }
};

export const addNewEvent = (payload) => async (dispatch) => {
  try {
    payload.coachId = coachId;
    const response = await api.post("/addSchedule", payload);
    successToast("Event added successfully");
    dispatch(getAllEvents());
    // dispatch({
    //   type: "ADD_EVENT",
    //   payload: formatDateSingle(response?.data?.data),
    // });
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const updateEvent = (payload, id) => async (dispatch) => {
  try {
    payload.coachId = coachId;
    const response = await api.patch(`/Schedule?id=${id}`, payload);
    successToast("Event updated successfully");

    dispatch(getAllEvents());
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const response = await api.delete(`/Schedule?id=${id}`);
    successToast("Event deleted successfully");
    dispatch(getAllEvents());
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};
//  ===============================================
//              Media actions
//  ===============================================

export const getMediaData = () => async (dispatch) => {
  try {
    const response = await api.get(`/getMedia/${coachId}`);
    if (response.data.data.length) {
      dispatch({
        type: "FETCH_MEDIA",
        payload: {
          videos: response.data?.data[0].data.videos,
          blogs: response.data?.data[0].data.blogs,
        },
      });
    }

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch({
        type: "UPDATE_SERVICE",
        payload: { name: "mediaService", value: false },
      });
      errorToast("Upgrade package for this service");
      throw new Error("Please upgrade package for this service");
    } else {
      console.log(error);
      console.log(error)
      throw new Error("Something went wrong");
    }
  }
};

export const addMedia = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/Media", payload.data);
    if (payload.mediaType === "video") {
      dispatch({ type: "INSERT_VIDEO", payload: response.data.media });
    } else {
      dispatch({ type: "INSERT_BLOG", payload: response.data.media });
    }
    successToast("Media added successfully");

    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

export const deleteMedia = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/deleteMedia/${payload.mediaId}`);
    if (payload.mediaType === "video") {
      dispatch({ type: "DELETE_VIDEO", payload: payload.mediaId });
      successToast("Video deleted successfully");
      return response;
    } else {
      dispatch({ type: "DELETE_BLOG", payload: payload.mediaId });
      successToast("Blog deleted successfully");
      return response;
    }
  } catch (error) {
    console.log(error)
  }
};

export const updateMediaBlog = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Media", payload);
    dispatch(getMediaData());
    successToast("Blog updated successfully");
    return;
  } catch (error) {
    console.log("Error blog ====>", error);
    console.log(error)
  }
};

export const updateMediaVideo = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Media", payload);
    dispatch(getMediaData());
    successToast("Media updated successfully");
    return;
  } catch (error) {
    console.log("Error blog ====>", error);
    console.log(error)
  }
};

//  ===============================================
//              Programs actions
//  ===============================================

export const getProgramData = () => async (dispatch) => {
  try {
    const response = await api.get(`/getProgram/${coachId}`);
    dispatch({ type: "FETCH_PROGRAMS", payload: response.data });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch({
        type: "UPDATE_SERVICE",
        payload: { name: "programService", value: false },
      });
      throw new Error("Please upgrade package for this service");
    } else {
      console.log(error)
      throw new Error("Something went wrong");
    }
  }
};

export const getProgramClients = async (id) => {
  try {
    const response = await api.get(`/getProgramClients/${id}`);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const addProgram = (payload) => async (dispatch) => {
  try {
    payload.coachId = coachId;
    const response = await api.post("/Program", payload);
    dispatch(getProgramData());
    dispatch({ type: "ADD_PROGRAMCOUNT" });
    successToast("Program added successfully");
    return response;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const editProgram = (payload) => async (dispatch) => {
  try {
    payload.coachId = coachId;
    const response = await api.patch("/Program", payload);
    dispatch(getProgramData());
    dispatch({ type: "ADD_PROGRAMCOUNT" });
    successToast("Program updated successfully");
    return response;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const getCategory = () => async (dispatch) => {
  try {
    const response = await api.get("/programCategory");
    dispatch({ type: "FETCH_CATEGORIES", payload: response.data });
    return;
  } catch (error) {
    console.log("Error while fetching program categories");
  }
};

export const deleteProgram = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/Program?id=${payload.id}`);
    dispatch({ type: "DELETE_PROGRAM", payload: payload });
    successToast("Program deleted successfully");

    return;
  } catch (error) {
    console.log("Error ", error);
  }
};

export const assignProgram = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/AssignProgram", payload);
    // dispatch({ type: "UPDATE_TASK", payload: response.data.data });
    successToast("Program assigned successfully");
    return;
  } catch (error) {
    console.log("Error ===>", error);
    console.log(error)
  }
};

//  ===============================================
//              Clients actions
//  ===============================================

export const getClientsData = () => async (dispatch) => {
  try {
    const response = await api.get(`/getAllClients/${coachId}`);
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const changeClientStatus = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/blockuser/${payload}`);
    dispatch({ type: "CHANGE_CLIENT_STATUS", payload: payload });
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const addNewClient = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/addClient`, payload);
    dispatch({ type: "ADD_CLIENT", payload: response.data });
    successToast("Client added successfully");
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

export const addStaff = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/addStaff`, payload);
    dispatch({ type: "ADD_STAFF", payload: response.data });
    successToast("Staff added successfully");
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

export const changeStaffStatus = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/blockuser/${payload}`);
    dispatch({ type: "CHANGE_STAFF_STATUS", payload: payload });
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const deleteUser = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/deleteUser/${payload.userId}`);
    if (payload.role === "client") {
      dispatch({ type: "DELETE_CLIENT", payload: { _id: payload.userId } });
    } else {
      dispatch({
        type: "DELETE_STAFF",
        payload: { _id: payload.userId },
      });
    }
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

//  ===============================================
//              Reports actions
//  ===============================================

export const getReportsData = () => async (dispatch) => {
  try {
    const response = await api.get(`/getReports/${coachId}`);
    dispatch({ type: "FETCH_REPORTS", payload: response.data });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch({
        type: "UPDATE_SERVICE",
        payload: { name: "reportService", value: false },
      });
      throw new Error("Please upgrade package for this service");
    } else {
      console.log(error)
      throw new Error("Something went wrong");
    }
  }
};

export const getPDFText = () => async (dispatch) => {
  try {
    const response = await api.get(`/coachReport?id=${coachId}`);
    dispatch({ type: "WELCOME_TEXT", payload: response?.data?.data?.welcome });

    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const addPDFText = (payload) => async (dispatch) => {
  try {
    const body = {
      coachId: coachId,
      welcome: payload,
    };
    const response = await api.post("/coachReport", body);
    dispatch({ type: "WELCOME_TEXT", payload: response?.data?.data?.welcome });
    successToast("Welcome text updated successfully");
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

//  ===============================================
//              Handouts actions
//  ===============================================
export const getHandsOutData = () => async (dispatch) => {
  try {
    const response = await api.get(`/getHandouts/${coachId}`);
    dispatch({ type: "FETCH_HANDOUTS", payload: response.data });
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

export const addHandout = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/addHandout", payload);
    dispatch({ type: "ADD_HANDOUTS", payload: response.data });
    successToast("Handout added successfully");
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

//  ===============================================
//              Package actions
//  ===============================================

export const fetchSelfPackages = () => async (dispatch) => {
  try {
    const response = await api.get(`/CoachPackage/${coachId}`);
    dispatch({ type: "FETCH_SELF_PACKAGES", payload: response.data });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch({
        type: "UPDATE_SERVICE",
        payload: { name: "packageService", value: false },
      });
      errorToast("Upgrade package for this service");
      throw new Error("Please upgrade package for this service");
    } else {
      console.log(error)
      throw new Error("Something went wrong");
    }
  }
};

export const fetchAdminPackages = () => async (dispatch) => {
  try {
    const response = await api.get("/package");
    dispatch({ type: "FETCH_ADMIN_PACKAGES", payload: response.data });
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

export const addSelfPackages = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/CoachPackage/${coachId}`, payload);
    dispatch({ type: "ADD_SELF_PACKAGE", payload: response.data });
    successToast("Package added successfully");
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

export const updateSelfPackages = (payload, id) => async (dispatch) => {
  try {
    const response = await api.patch(`/CoachPackage/${id}`, payload);
    dispatch(fetchSelfPackages());
    successToast("Package updated successfully");
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

export const deletePackage = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/deleteCoachPackage/${payload._id}`);
    dispatch({ type: "DELETE_SELF_PACKAGE", payload: payload });
    successToast("Package deleted successfully");
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const subscribePackage = async (payload) => {
  try {
    const response = await api.post(`/addPackage/${coachId}`, payload);
    localStorage.removeItem("sessionId");
    successToast("Package added successfully");
    return;
  } catch (error) {
    console.error(error);
  }
};

export const assignPackage = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/assignPackageToClient", payload);
    successToast("Package assigned successfully");
    dispatch(fetchSelfPackages());
    return;
  } catch (error) {
    console.error(error);
    console.log(error)
  }
};

//  ===============================================
//              Task actions
//  ===============================================
export const fetchTasks = () => async (dispatch) => {
  try {
    const response = await api.get(`/getTasks/${coachId}`);
    dispatch({ type: "FETCH_TASKS", payload: response.data });
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errorToast("Upgrade package for this service");
    } else {
      console.log(error)
    }
  }
};

export const addTask = (payload) => async (dispatch) => {
  try {
    payload.coachId = coachId;
    const response = await api.post("/Task", payload);
    dispatch(fetchTasks());
    return;
  } catch (error) {
    console.log("Error ===>", error);
    console.log(error)
  }
};

export const updateTask = (payload) => async (dispatch) => {
  try {
    payload.coachId = coachId;
    const response = await api.patch("/Task", payload);
    dispatch(fetchTasks());
    successToast("Task updated successfully");
    return;
  } catch (error) {
    console.log("Error ===>", error);
    console.log(error)
  }
};

export const deleteTask = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/deleteTask/${payload.id}`);
    dispatch({ type: "DELETE_TASK", payload: payload });
    successToast("Task deleted successfully");
    return;
  } catch (error) {
    console.log("Error ===>", error);
    console.log(error)
  }
};

export const assignTask = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/associateTask", payload);
    successToast("Task assigned successfully");
    dispatch(fetchTasks);
    return;
  } catch (error) {
    console.log("Error ===>", error);
    console.log(error)
  }
};

export const reOpenStaffTask = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/changeTaskStatus", payload);
    successToast("Task Reopened successfully");
    dispatch(fetchTasks);
    return;
  } catch (error) {
    console.log("Error ===>", error);
    console.log(error)
  }
};

//  ===============================================
//              Goal actions
//  ===============================================

export const getClientsGoals = () => async (dispatch) => {
  try {
    console.log("coachId----", coachId);
    const response = await api.get(`/getGoals/Coach/${coachId}`);
    dispatch({ type: "FETCH_GOALS", payload: response.data });
    return;
  } catch (error) {
    console.log("Error get goals ===>", error);
    console.log(error)
  }
};

export const createGoal = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/Goal", payload);
    successToast("Goal created successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const updateGoal = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Goal", payload);
    successToast("Goal updated successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const updateGoalStepsReopen = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Goal", payload);
    return dispatch(reOpenGoal(payload.goalId));
  } catch (error) {
    console.log("Error add goals ===>", error);
    console.log(error)
  }
};

export const deleteGoal = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/Goal?id=${payload}`);
    successToast("Goal deleted successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log("Error add goals ===>", error);
    console.log(error)
  }
};

export const addGoalSteps = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Goal", payload);
    successToast("Goal updated successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log("Error add goals ===>", error);
    console.log(error)
  }
};

export const reOpenGoal = (payload) => async (dispatch) => {
  try {
    await api.post(`/ReOpenGoal/${payload}`);
    successToast("Goal reopened successfully");
    dispatch(getClientsGoals());
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const addStripeKey = (userId, payload) => async (dispatch) => {
  try {
    const response = await api.post(`/StripeKey/${userId}`, payload);
    const replacedText = payload.secretKey.slice(-4);
    dispatch({
      type: "ADD_STRIPE_KEY",
      payload: {
        data: {
          publicKey: payload.publicKey,
          secretKey: `${"*".repeat(20)}${replacedText}`,
        },
      },
    });
    successToast("Stripe keys added successfully");
    return;
  } catch (error) {
    console.log(error);
    console.log(error)
  }
};

export const getStripeKey = (userId) => async (dispatch) => {
  try {
    const response = await api.get(`/StripeKey/${userId}`);
    if (response?.data) {
      if (response?.data?.data?.secretKey) {
        dispatch({
          type: "ADD_STRIPE_KEY",
          payload: {
            data: {
              publicKey: response.data.data.publicKey,
              secretKey: `${"*".repeat(20)}${response.data.data.secretKey}`,
            },
          },
        });
      }
    }
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const transactionHistory = () => async (dispatch) => {
  try {
    const response = await api.get(`/getTransactions/coach/${coachId}`);
    dispatch({ type: "TRANSACTION_HISTORY", payload: response.data });
    return;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch({
        type: "UPDATE_SERVICE",
        payload: { name: "transactionService", value: false },
      });
      throw new Error("Please upgrade package for this service");
    } else {
      console.log(error)
      throw new Error("Something went wrong");
    }
  }
};

export const viewOrDownloadClientReport = async (payload) => {
  try {
    const response = await api.get(`/clientReportNew/${payload}`);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const allowAccessToClient = (payload) => async (dispatch) => {
  try {
    payload.coachId = coachId;
    const response = await api.post("/AllowAccessToReport", payload);
    dispatch({ type: "SMART_PLAN_ACCESS_GRANTED" });
  } catch (error) {
    console.log(error)
    throw error;
  }
};

// api.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     if (
//       error.response.status === 401 &&
//       originalRequest &&
//       !originalRequest._isRetry
//     ) {
//       originalRequest._isRetry = true;
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/refreshToken`,
//           {
//             withCredentials: true,
//           }
//         );
//         coachId = response.data.data._id;
//         localStorage.setItem("coachId", response.data.data._id);

//         authReducer({ type: "LOGIN", payload: response.data });
//         return api.request(originalRequest);
//       } catch (error) {
//         authReducer({ type: "LOGOUT" });
//       }
//     }
//     throw error;
//   }
// );

export const checkLogin = () => async (dispatch) => {
  const refreshToken = localStorage.getItem("ca_refresh");
  try {
    if (refreshToken) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/refreshToken`,
        {
          refreshToken: JSON.parse(refreshToken),
        }
      );
      coachId = response.data.data._id;
      setToken(response.data);
      localStorage.setItem("coachId", response.data.data._id);
      dispatch({ type: "LOGIN", payload: response.data });
      return response.data;
    }
    throw new Error("Logout");
  } catch (error) {
    throw error;
  }
};

export const uploadPhoto = async (payload) => {
  try {
    const formData = new FormData();
    formData.append("photo", payload.photo);
    const response = await api.post("/photo", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    errorToast("Something went wrong while uploading photo");
    throw error;
  }
};

export const getAdminPublicKey = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `/ToPayment/${coachId}/Coach`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  ===============================================
//              Chat Api
//  ===============================================
export const getAllUsers = () => async (dispatch) => {
  try {
    const response = await api.get(`/getUsersToChat/Coach/${coachId}`);
    dispatch({ type: "CHAT_ALL_USER", payload: response.data });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const getListedChat = () => async (dispatch) => {
  try {
    const response = await api.get(`/getMessages/${coachId}`);
    dispatch({ type: "CHAT_LISTING", payload: response.data });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const sendMessage = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/sendMessage`, payload);
    dispatch({
      type: "ADD_SEND_MSG",
      payload: response.data,
    });
    return response;
  } catch (error) {
    console.error(error);
    errorToast("Message sending failed");
    throw error;
  }
};

export const readMessage = async (payload) => {
  try {
    const response = await api.post(`/readMessage`, payload);
    return;
  } catch (error) {
    console.error(error);
  }
};

export const deleteChat = (messages, targetChat) => async (dispatch) => {
  try {
    const response = await api.post("/deleteMessage", {
      messageId: messages,
      type: "chat",
    });
    dispatch({
      type: "DELETE_CHAT",
      payload: { id: targetChat },
    });
    successToast("Chat deleted successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while deleting chat");
  }
};

export const deleteSingleMessage = (paylaod) => async (dispatch) => {
  try {
    const response = await api.post("/deleteMessage", {
      messageId: paylaod.message,
      type: "message",
    });

    dispatch({
      type: "DELETE_MESSAGE",
      payload: { senderId: paylaod.targetChat, messageId: paylaod.message[0] },
    });
    successToast("Message deleted successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while deleting message");
  }
};

export const getUserUnreadMessages = async () => {
  try {
    const response = await api.get(`getUnreadCount/${coachId}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const coachReport = async () => {
  try {
    const response = await api.get("/getCoachReport");
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const changeSelfPassword = async (payload) => {
  try {
    await api.patch("/changePassword", payload);
    successToast("Password changed successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast(error?.response?.data?.message || "Something went wrong");
  }
};

export const changeStaffClientPassword = async (payload) => {
  try {
    await api.patch(`/updateUser/${payload.id}`, {
      password: payload.password,
    });
    successToast("Password changed successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast(error?.response?.data?.message || "Something went wrong");
  }
};

// coach email update
export const updateEmail = (payload) => async (dispatch) => {
  try {
    const res = await api.patch(`/updateUser/${payload.userId}`, {
      email: payload.newEmail,
    })
    return res;
  } catch (error) {
    console.log('Error in updateEmail', error);
  }
}
