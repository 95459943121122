const initialState = {
  data: [],
};

const handoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_HANDOUTS":
      return {
        ...state,
        data: payload.data,
      };
    case "ADD_HANDOUTS":
      return {
        ...state,
        data: [...state.data, payload.media],
      };
    default:
      return state;
  }
};

export default handoutReducer;
