import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import StaffInfo from "../../components/StaffTabs/StaffInfo";
import SelfTask from "../../components/StaffTabs/SelfTask";
import Meetings from "../../components/StaffTabs/Meetings";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getStaffDetail } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";

const userdtails = [
  {
    label: "Profile Setting",
  },
  {
    label: "Self Tasks",
  },
  {
    label: "meetings",
  },
];

const StaffDetails = () => {
  let Once = true;
  const [activeTab, setActiveTab] = useState("Profile Setting");
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (Once) {
      Once = false;
      setLoading(true);
      dispatch(getStaffDetail(params?.userId))
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          navigate("/detail-not-found");
        });
    }
  }, []);
  // console.log(activeTab)
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bg-white mt-3">
            <ul className="flex  items-center  pr-2  px-2 py-1 ">
              {userdtails.map((elem, index) => (
                <li
                  onClick={() => setActiveTab(elem.label)}
                  className={`${
                    activeTab === elem.label ? "bg-[--primary] text-white " : ""
                  } hover:bg-[--primary] hover:text-white py-2 mr-1 rounded-md cursor-pointer px-3 satoshi-700 transition-all duration-300 flex-grow text-center capitalize`}
                  key={index}
                >
                  {elem.label}
                </li>
              ))}
            </ul>
          </div>
          <div>
            {activeTab === "Profile Setting" && <StaffInfo />}
            {activeTab === "Self Tasks" && <SelfTask />}
            {activeTab === "meetings" && <Meetings />}
          </div>
        </>
      )}
    </>
  );
};

export default StaffDetails;
