const initialState = {
  data: [],
  categories: [],
};

const programReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PROGRAMS":
      return {
        ...state,
        data: payload.data,
      };
    case "FETCH_CATEGORIES":
      return {
        ...state,
        categories: payload.data,
      };
    case "ADD_PROGRAM":
      return {
        ...state,
        data: [...state.data, payload.data],
      };
    case "DELETE_PROGRAM":
      const updatedList = state.data.filter(
        (program) => program._id !== payload.id
      );
      return {
        ...state,
        data: updatedList,
      };
    default:
      return state;
  }
};

export default programReducer;
