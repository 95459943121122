const initialState = {
  data: null,
};

const CustomerDetailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_CLIENT_DETAIL":
      return {
        ...state,
        data: payload.data,
      };
    case "SMART_PLAN_ACCESS_GRANTED":
      return {
        ...state,
        data: {
          ...state.data,
          smartPlan: state.data.smartPlan.map((plan, index) =>
            index === 0 ? { ...plan, clientHave: true } : plan
          ),
        },
      };
    default:
      return state;
  }
};

export default CustomerDetailReducer;
