import React, { useEffect, useState } from "react";
import MediaContainer from "../Wrapper/MediaContainer";
import InputWrapper from "../Wrapper/InputWrapper";
import InputRequired from "../Input/InputRequired";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaImage } from "react-icons/fa";
import FileUpload from "../Input/FileUplaod";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addProgram,
  getCategory,
  getMediaData,
  getStripeKey,
  uploadPhoto,
} from "../../Redux/Actions";
import parse from "html-react-parser";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useFormik } from "formik";
import { programSchema } from "../../Schema";
import Loader from "../Loading/Loader";
import { errorToast } from "../../Utils/Toast";
import { Link } from "react-router-dom";

let Once = true;

const AddProgram = ({ toggleProgramForm, progData }) => {
  const userData = useSelector((state) => state.auth.userData);
  const { data, isInvoked } = useSelector((state) => state.stripe);

  const initialState = {
    title: progData ? progData.title : "",
    photo: "",
    description: "",
    category: progData ? progData.category : "",
    media: [],
    coachId: "",
    price: null,
    programType: "Free",
  };

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [mediaIncluded, setMediaIncluded] = useState([]);
  const [isServiceAvailable, setServiceAvailable] = useState(false);

  const [error, setError] = useState({
    imageError: "",
    descriptionError: "",
  });
  const dispatch = useDispatch();
  const videosData = useSelector((state) => state.media.videos);
  const blogsData = useSelector((state) => state.media.blogs);
  const categoryData = useSelector((state) => state.program.categories);

  const onDropImage = (files) => {
    setError((pre) => {
      return {
        ...pre,
        imageError: "",
      };
    });
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = files[0].name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setSelectedFiles(files[0]);
    } else {
      setError((pre) => {
        return {
          ...pre,
          imageError:
            "Invalid file type. Please upload a PNG ,JPG or JPEG file.",
        };
      });
    }
  };

  function handleMediaIncluded(id) {
    const isIdIncluded = mediaIncluded.includes(id);
    if (!isIdIncluded) {
      setMediaIncluded((prevMediaIncluded) => [...prevMediaIncluded, id]);
    } else {
      setMediaIncluded((prevMediaIncluded) =>
        prevMediaIncluded.filter((mediaId) => mediaId !== id)
      );
    }
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialState,
    validationSchema: programSchema,
    onSubmit: async (values, action) => {
      if (!description) {
        setError((pre) => {
          return {
            ...pre,
            descriptionError: "Description is required",
          };
        });
        return;
      }

      // if (mediaIncluded.length === 0) {
      //   errorToast("Please add at least one media");
      //   return;
      // }

      try {
        setBtnLoading(true);
        let photoUrl = "";

        if (selectedFiles) {
          const uploadResponse = await uploadPhoto({ photo: selectedFiles });
          photoUrl = uploadResponse.data.photos[0];
        }

        const programData = {
          ...values,
          photo: photoUrl,
          description,
          media: mediaIncluded,
        };

        await dispatch(addProgram(programData));
        setBtnLoading(false);
        setDescription("");
        setSelectedFiles(null);
        action.resetForm();
        toggleProgramForm();
      } catch (err) {
        setBtnLoading(false);
        errorToast("Something went wrong while adding the program");
      }
    },
  });

  function getCategoryInfo() {
    if (isEmpty(categoryData)) {
      setLoading(true);
      dispatch(getCategory()).then(() => {
        setLoading(false);
      });
    }
  }

  function getMedia() {
    if (isEmpty(videosData) && isEmpty(blogsData)) {
      setLoading(true);
      dispatch(getMediaData())
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.message === "Something went wrong") {
            setServiceAvailable(true);
          } else {
            setServiceAvailable(false);
          }
        });
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setLoading(true);
        dispatch(getStripeKey(userData.id))
          .then(() => {
            getCategoryInfo();
            getMedia();
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      } else {
        getCategoryInfo();
        getMedia();
      }
    }
  }, [dispatch, userData.id, isInvoked, categoryData, videosData, blogsData]);

  return loading ? (
    <Loader />
  ) : (
    <>
      {data ? (
        <section className="w-full flex justify-between items-start gap-4">
          {categoryData?.length ? (
            <div className="w-full">
              <MediaContainer text={"Please fill the data"} />
              <div className="bg-white rounded-md px-6 py-4">
                <InputWrapper customClasses={"!mt-6 !items-stretch"}>
                  <InputRequired
                    label="Title"
                    placeholder="Enter title"
                    name="title"
                    id="title"
                    value={values.title}
                    error={errors.title}
                    touch={touched.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />

                  <div className="flex-grow basis-48">
                    <label
                      htmlFor="Category"
                      className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
                    >
                      Category
                    </label>
                    <select
                      className="w-full rounded-md border outline-none py-2 px-2"
                      name="category"
                      id="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option disabled value="">
                        Choose Category
                      </option>
                      {categoryData?.map((cat, index) => (
                        <option className="text-black" key={index} value={cat._id}>
                          {cat.name}
                        </option>
                      ))}
                    </select>
                    {errors.category && touched.category ? (
                      <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
                        {errors.category}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </InputWrapper>
                <InputWrapper>
                  <div className="flex-grow basis-48">
                    <label
                      htmlFor="Category"
                      className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
                    >
                      Program Type
                    </label>
                    <select
                      className="w-full rounded-md  border outline-none py-2 px-2"
                      name="programType"
                      id="programType"
                      value={values.programType}
                      onChange={(e) => {
                        if (e.target.value === "Free") {
                          setFieldValue("price", 0);
                        }
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option className="text-black" value="Free">
                        Free (No payment required for the program)
                      </option>
                      <option className="text-black" value="Sell">
                        Sell (Payment required for the program)
                      </option>
                    </select>
                    {errors.programType && touched.programType ? (
                      <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
                        {errors.programType}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  {values.programType === "Sell" && (
                    <InputRequired
                      label="Program Price"
                      placeholder="Enter price of program"
                      name="price"
                      id="price"
                      type="number"
                      value={values.price}
                      error={errors.price}
                      touch={touched.price}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  )}
                </InputWrapper>
                <InputWrapper>
                  <div className="w-full">
                    <label
                      htmlFor="description"
                      className="block pl-[2px] font-medium text-[14px] capitalize"
                    >
                      Description
                    </label>
                    <ReactQuill
                      theme="snow"
                      id="description"
                      value={description}
                      className="w-full py-3 rounded-md"
                      onChange={setDescription}
                      placeholder="Write description here ..."
                    />
                    <p className="text-red-600 mb-6">
                      {error.descriptionError}
                    </p>
                  </div>
                </InputWrapper>
                <InputWrapper customClasses={"!items-stretch"}>
                  <FileUpload
                    icon={<FaImage />}
                    onDrop={onDropImage}
                    error={error.imageError}
                    selectedFiles={selectedFiles}
                    headerText="Drop your image here (optional), or"
                    description=".jpg .png .jpeg files supported"
                    type="image"
                  />
                </InputWrapper>
                {videosData.length ? (
                  <div className="flex justify-start items-stretch flex-wrap gap-2 py-2">
                    {videosData.map((media, index) => (
                      <div
                        key={index}
                        className="mediaCardShadow rounded-lg w-[200px]"
                      >
                        <div className="flex justify-end items-center py-2">
                          <input
                            type="checkbox"
                            className="h-[15px] w-full cursor-pointer"
                            onChange={() => {
                              handleMediaIncluded(media._id);
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <div className="flex justify-center items-center">
                            <video className="h-32 w-full" controls>
                              <source
                                src={process.env.REACT_APP_IMAGE_URL + media.video}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                          <div className="w-full pt-2">
                            <h4 className="satoshi-700 text-[16px] pl-2">
                              {media.title}
                            </h4>
                          </div>
                          <div className="pb-1 px-2 text-[14px] max-h-[45px] overflow-hidden text-ellipsis">
                            {parse(parse(media.description))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

                {blogsData.length ? (
                  <div className="flex justify-start items-stretch flex-wrap gap-4 py-2 mt-3">
                    {blogsData.map((blog, index) => (
                      <div
                        key={index}
                        className="mediaCardShadow rounded-lg w-[200px]"
                      >
                        <div className="flex justify-end items-center py-2">
                          <input
                            type="checkbox"
                            onChange={() => {
                              handleMediaIncluded(blog._id);
                            }}
                            className="h-[15px] w-full cursor-pointer"
                          />
                        </div>
                        <div className="w-full">
                          <div className="flex justify-center items-center">
                            <img
                              className="h-32 w-full"
                              src={process.env.REACT_APP_IMAGE_URL + blog?.photo}
                              alt="Blog cover"
                            />
                          </div>
                          <div className="w-full pt-2">
                            <h4 className="satoshi-700 text-[16px] pl-2">
                              {blog.title}
                            </h4>
                          </div>
                          <div className="pb-1 px-2 text-[14px] max-h-[45px] overflow-hidden text-ellipsis">
                            {parse(parse(blog.description))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

                <div className="flex justify-start items-center gap-4 mt-3">
                  {btnLoading ? (
                    <ButtonLoading />
                  ) : (
                    <>
                      <ButtonSmall
                        text="Cancel"
                        onClick={toggleProgramForm}
                        customClasses="!bg-white !text-black"
                      />
                      <ButtonSmall
                        text="Create Content"
                        onClick={handleSubmit}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <p className="text-[16px] md:text-[18px] text-red-600 mt-4">
              Thank you for your interest! At the moment, program categories
              have not been added by the admin. Please either contact the admin
              for further assistance or patiently wait until the categories are
              added. Once the categories are available, you'll be able to add
              your programs seamlessly. We apologize for any inconvenience
              caused by this delay.
            </p>
          )}
        </section>
      ) : (
        <>
          <p className="text-[16px] md:text-[18px] text-red-600 mt-4">
            Welcome to our platform! To seamlessly add packages, kindly navigate
            to your account settings or{" "}
            <Link to="/setting" className="text-blue-500 underline satoshi-500">
              Click here to redirect
            </Link>{" "}
            where you can upload your Stripe public and secret keys. These keys
            are essential for secure payment processing. Once you've uploaded
            them, you'll be able to add packages hassle-free. If you encounter
            any issues or need assistance, please don't hesitate to reach out to
            our support team.
            <h1 className="text-center">Thank you !</h1>
          </p>
        </>
      )}
    </>
  );
};

export default AddProgram;


// import React, { useEffect, useState } from "react";
// import MediaContainer from "../Wrapper/MediaContainer";
// import InputWrapper from "../Wrapper/InputWrapper";
// import InputRequired from "../Input/InputRequired";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import { FaImage } from "react-icons/fa";
// import FileUpload from "../Input/FileUplaod";
// import { isEmpty } from "lodash";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addProgram,
//   getCategory,
//   getMediaData,
//   getStripeKey,
//   uploadPhoto,
// } from "../../Redux/Actions";
// import parse from "html-react-parser";
// import ButtonLoading from "../Buttons/ButtonLoading";
// import ButtonSmall from "../Buttons/ButtonSmall";
// import { useFormik } from "formik";
// import { programSchema } from "../../Schema";
// import Loader from "../Loading/Loader";
// import { errorToast } from "../../Utils/Toast";
// import TextModal from "../Modal/TextModal";
// import { Link } from "react-router-dom";

// let Once = true;

// const AddProgram = ({ toggleProgramForm, progData }) => {
//   const userData = useSelector((state) => state.auth.userData);
//   const { data, isInvoked } = useSelector((state) => state.stripe);

//   const initialState = {
//     title: progData ? progData.title : "",
//     photo: "",
//     description: "",
//     category: progData ? progData.category : "",
//     media: [],
//     coachId: "",
//     price: null,
//     programType: "Free",
//   };
//   const [loading, setLoading] = useState(false);
//   const [description, setDescription] = useState("");
//   const [selectedFiles, setSelectedFiles] = useState(null);
//   const [isServiceAvailable, setServiceAvailable] = useState(false);
//   const [btnLoading, setBtnLoading] = useState(false);
//   const [mediaIncluded, setMediaIncluded] = useState([]);
//   const [error, setError] = useState({
//     imageError: "",
//     descriptionError: "",
//   });
//   const dispatch = useDispatch();
//   const videosData = useSelector((state) => state.media.videos);
//   const blogsData = useSelector((state) => state.media.blogs);
//   const categoryData = useSelector((state) => state.program.categories);

//   const onDropImage = (files) => {
//     setError((pre) => {
//       return {
//         ...pre,
//         imageError: "",
//       };
//     });
//     // Check if the dropped file has an allowed extension
//     const allowedExtensions = ["png", "jpg", "jpeg"];
//     const fileExtension = files[0].name.split(".").pop().toLowerCase();

//     if (allowedExtensions.includes(fileExtension)) {
//       setSelectedFiles(files[0]);
//     } else {
//       // Display an error message or handle the case where the file is not allowed
//       setError((pre) => {
//         return {
//           ...pre,
//           imageError:
//             "Invalid file type. Please upload a PNG ,JPG or JPEG file.",
//         };
//       });
//     }
//   };

//   function handleMediaIncluded(id) {
//     // Check if the ID is already in the state
//     const isIdIncluded = mediaIncluded.includes(id);

//     // If the ID is checked and not in the state, add it
//     if (!isIdIncluded) {
//       setMediaIncluded((prevMediaIncluded) => [...prevMediaIncluded, id]);
//     } else {
//       // If the ID is unchecked and in the state, remove it
//       setMediaIncluded((prevMediaIncluded) =>
//         prevMediaIncluded.filter((mediaId) => mediaId !== id)
//       );
//     }
//   }

//   const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
//     useFormik({
//       initialValues: initialState,
//       validationSchema: programSchema,
//       onSubmit: (values, action) => {
//         if (!description) {
//           setError((pre) => {
//             return {
//               ...pre,
//               descriptionError: "Description is required",
//             };
//           });
//         } else if (!selectedFiles) {
//           setError((pre) => {
//             return {
//               ...pre,
//               imageError: "Program Image is required",
//             };
//           });
//         } else {
//           if (mediaIncluded.length > 0) {
//             setBtnLoading(true);
//             uploadPhoto({ photo: selectedFiles })
//               .then((res) => {
//                 values.photo = res.data.photos[0];
//                 values.description = description;
//                 values.media = mediaIncluded;
//                 dispatch(addProgram(values)).then(() => {
//                   setBtnLoading(false);
//                   setDescription("");
//                   setSelectedFiles(null);
//                   action.resetForm();
//                   toggleProgramForm();
//                 });
//               })
//               .catch((err) => {
//                 setBtnLoading(false);
//               });
//           } else {
//             errorToast("Please add at least one media");
//           }
//         }
//       },
//     });

//   function getCategoryInfo() {
//     if (isEmpty(categoryData)) {
//       setLoading(true);
//       dispatch(getCategory()).then(() => {
//         setLoading(false);
//       });
//     }
//   }

//   function getMedia() {
//     if (isEmpty(videosData) && isEmpty(blogsData)) {
//       setLoading(true);
//       dispatch(getMediaData())
//         .then(() => {
//           setLoading(false);
//         })
//         .catch((err) => {
//           setLoading(false);
//           if (err.message === "Something went wrong") {
//             setServiceAvailable(true);
//           } else {
//             setServiceAvailable(false);
//           }
//         });
//     }
//   }

//   useEffect(() => {
//     if (Once) {
//       Once = false;
//       if (!isInvoked) {
//         setLoading(true);
//         dispatch(getStripeKey(userData.id))
//           .then((res) => {
//             getCategoryInfo();
//             getMedia();
//             setTimeout(() => {
//               setLoading(false);
//             }, 1000);
//           })
//           .catch((err) => {
//             setLoading(false);
//             console.error(err);
//           });
//       } else {
//         getCategoryInfo();
//         getMedia();
//       }
//     }
//   }, []);

//   return loading ? (
//     <Loader />
//   ) : (
//     <>
//       {data ? (
//         <section className="w-full flex justify-between items-start gap-4">
//           {categoryData?.length ? (
//             <div className="w-full">
//               <MediaContainer text={"Please fill the data"} />
//               <div className="bg-white rounded-md px-6 py-4">
//                 <InputWrapper customClasses={"!mt-6 !items-stretch"}>
//                   <InputRequired
//                     label="Title"
//                     placeholder="Enter title"
//                     name="title"
//                     id="title"
//                     value={values.title}
//                     error={errors.title}
//                     touch={touched.title}
//                     onBlur={handleBlur}
//                     onChange={handleChange}
//                   />

//                   <div className="flex-grow basis-48">
//                     <label
//                       htmlFor="Category"
//                       className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
//                     >
//                       Category
//                     </label>
//                     <select
//                       className="w-full rounded-md border outline-none py-2 px-2"
//                       name="category"
//                       id="category"
//                       value={values.category}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                     >
//                       <option disabled value="">
//                         Choose Category
//                       </option>
//                       {categoryData?.map((cat, index) => {
//                         return (
//                           <option
//                             className="text-black"
//                             key={index}
//                             value={cat._id}
//                           >
//                             {cat.name}
//                           </option>
//                         );
//                       })}
//                     </select>
//                     {errors.category && touched.category ? (
//                       <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
//                         {errors.category}
//                       </span>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </InputWrapper>
//                 <InputWrapper>
//                   <div className="flex-grow basis-48">
//                     <label
//                       htmlFor="Category"
//                       className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
//                     >
//                       Program Type
//                     </label>
//                     <select
//                       className="w-full rounded-md  border outline-none py-2 px-2"
//                       name="programType"
//                       id="programType"
//                       value={values.programType}
//                       onChange={(e) => {
//                         values.price = 0;
//                         handleChange(e);
//                       }}
//                       onBlur={handleBlur}
//                     >
//                       <option className="text-black" value="Free">
//                         Free (No payment required for the program)
//                       </option>
//                       <option className="text-black" value="Sell">
//                         Sell (Payment required for the program)
//                       </option>
//                     </select>
//                     {errors.programType && touched.programType ? (
//                       <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
//                         {errors.programType}
//                       </span>
//                     ) : (
//                       ""
//                     )}
//                   </div>

//                   {values.programType === "Sell" && (
//                     <InputRequired
//                       label="Program Price"
//                       placeholder="Enter price of program"
//                       name="price"
//                       id="price"
//                       type="number"
//                       value={values.price}
//                       error={errors.price}
//                       touch={touched.price}
//                       onBlur={handleBlur}
//                       onChange={handleChange}
//                     />
//                   )}
//                 </InputWrapper>
//                 <InputWrapper>
//                   <div className="w-full">
//                     <label
//                       htmlFor="description"
//                       className="block pl-[2px] font-medium text-[14px] capitalize"
//                     >
//                       Description
//                     </label>
//                     <ReactQuill
//                       theme="snow"
//                       id="description"
//                       value={description}
//                       className="w-full py-3 rounded-md"
//                       onChange={setDescription}
//                       placeholder="Write description here ..."
//                     />
//                     <p className="text-red-600 mb-6">
//                       {error.descriptionError}
//                     </p>
//                   </div>
//                 </InputWrapper>
//                 <InputWrapper customClasses={"!items-stretch"}>
//                   <FileUpload
//                     icon={<FaImage />}
//                     onDrop={onDropImage}
//                     error={error.imageError}
//                     selectedFiles={selectedFiles}
//                     headerText="Drop your image here, or"
//                     description=".jpg .png .jpeg files supported"
//                     type="image"
//                   />
//                 </InputWrapper>
//                 {videosData.length ? (
//                   <div className="flex justify-start items-stretch flex-wrap gap-2 py-2">
//                     {videosData.map((media, index) => {
//                       return (
//                         <div
//                           key={index}
//                           className="mediaCardShadow rounded-lg w-[200px]"
//                         >
//                           <div className="flex justify-end items-center py-2">
//                             <input
//                               type="checkbox"
//                               className="h-[15px] w-full cursor-pointer"
//                               onChange={() => {
//                                 handleMediaIncluded(media._id);
//                               }}
//                             />
//                           </div>
//                           <div className="w-full">
//                             <div className="flex justify-center items-center">
//                               <video className="h-32 w-full" controls>
//                                 <source
//                                   src={
//                                     process.env.REACT_APP_IMAGE_URL +
//                                     media.video
//                                   }
//                                   type="video/mp4"
//                                 />
//                                 Your browser does not support the video tag.
//                               </video>
//                             </div>
//                             <div className="w-full pt-2">
//                               <h4 className="satoshi-700 text-[16px] pl-2">
//                                 {media.title}
//                               </h4>
//                             </div>
//                             <div className="pb-1 px-2 text-[14px] max-h-[45px] overflow-hidden text-ellipsis">
//                               {parse(parse(media.description))}
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 ) : null}

//                 {blogsData.length ? (
//                   <div className="flex justify-start items-stretch flex-wrap gap-4 py-2 mt-3">
//                     {blogsData.map((blog, index) => {
//                       return (
//                         <div
//                           key={index}
//                           className="mediaCardShadow rounded-lg w-[200px]"
//                         >
//                           <div className="flex justify-end items-center py-2">
//                             <input
//                               type="checkbox"
//                               onChange={() => {
//                                 handleMediaIncluded(blog._id);
//                               }}
//                               className="h-[15px] w-full cursor-pointer"
//                             />
//                           </div>
//                           <div className="w-full">
//                             <div className="flex justify-center items-center">
//                               <img
//                                 className="h-32 w-full"
//                                 src={
//                                   process.env.REACT_APP_IMAGE_URL + blog?.photo
//                                 }
//                                 alt="Blog cover"
//                               />
//                             </div>
//                             <div className="w-full pt-2">
//                               <h4 className="satoshi-700 text-[16px] pl-2">
//                                 Hello Test
//                               </h4>
//                             </div>
//                             <div className="pb-1 px-2 text-[14px] max-h-[45px] overflow-hidden text-ellipsis">
//                               {parse(parse(blog.description))}
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 ) : null}

//                 <div className="flex justify-start items-center gap-4 mt-3">
//                   {btnLoading ? (
//                     <ButtonLoading />
//                   ) : (
//                     <>
//                       <ButtonSmall
//                         text="Cancel"
//                         onClick={toggleProgramForm}
//                         customClasses="!bg-white !text-black"
//                       />
//                       <ButtonSmall
//                         text="Create Content"
//                         onClick={handleSubmit}
//                       />
//                     </>
//                   )}
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <p className="text-[16px] md:text-[18px] text-red-600 mt-4">
//               Thank you for your interest! At the moment, program categories
//               have not been added by the admin. Please either contact the admin
//               for further assistance or patiently wait until the categories are
//               added. Once the categories are available, you'll be able to add
//               your programs seamlessly. We apologize for any inconvenience
//               caused by this delay.
//             </p>
//           )}
//         </section>
//       ) : (
//         <>
//           <p className="text-[16px] md:text-[18px] text-red-600 mt-4">
//             Welcome to our platform! To seamlessly add packages, kindly navigate
//             to your account settings or{" "}
//             <Link to="/setting" className="text-blue-500 underline satoshi-500">
//               Click here to redirect
//             </Link>{" "}
//             where you can upload your Stripe public and secret keys. These keys
//             are essential for secure payment processing. Once you've uploaded
//             them, you'll be able to add packages hassle-free. If you encounter
//             any issues or need assistance, please don't hesitate to reach out to
//             our support team.
//             <h1 className="text-center">Thank you !</h1>
//           </p>
//         </>
//       )}
//     </>
//   );
// };

// export default AddProgram;

