const initialState = {
  selfPackages: [],
  adminPackages: [],
};

const packageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_SELF_PACKAGES":
      return {
        ...state,
        selfPackages: payload.data,
      };
    case "FETCH_ADMIN_PACKAGES":
      return {
        ...state,
        adminPackages: payload.packages,
      };
    case "ADD_SELF_PACKAGE":
      return {
        ...state,
        selfPackages: [...state.selfPackages, payload.package],
      };
    case "DELETE_SELF_PACKAGE":
      const filteredSelfPackages = state.selfPackages.filter(
        (packageInfo) => packageInfo._id !== payload._id
      );
      return {
        ...state,
        selfPackages: filteredSelfPackages,
      };
    default:
      return state;
  }
};

export default packageReducer;
