import React, { useEffect, useState } from "react";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import InputRequired from "../Input/InputRequired";
import ReactQuill from "react-quill";
import { MdDeleteForever } from "react-icons/md";
// import InputSmall from "../Input/InputSmall";
import parse from "html-react-parser";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoIosAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { createGoal, getDashboardData } from "../../Redux/Actions";
import { useFormik } from "formik";
import { goalSchema } from "../../Schema";
import Loader from "../Loading/Loader";
import { FaEdit } from "react-icons/fa";
const labelClassess = "satoshi-500 !mt-3 !text-black !text-[14px]";

let Once = true;

const CreateAssignGoal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard);

  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(null);
  const [stepTitle, setStepTitle] = useState("");
  const [stepDescription, setStepDescription] = useState("");
  const [staffSearchQuery, setStaffSearchQuery] = useState("");
  const [allClients, setAllClients] = useState(false);
  const [allStaff, setAllStaff] = useState(false);
  const [clientSearchQuery, setClientSearchQuery] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [clientFieldError, setClientFieldError] = useState("");
  const [isStepUpdate, setStepUpdate] = useState({
    status: false,
    targetIndex: 0,
  });

  const initialState = {
    goalType: "",
    amount: 0,
    name: "",
    duration: 1,
    durationType: "month",
    programs: [],
    steps: [],
    note: null,
    staff: [],
    clientId: [],
  };

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialState,
    validationSchema: goalSchema,
    onSubmit: (values) => {
      values.note = note;
      if (!values.clientId.length) {
        setClientFieldError("Please select at least one client");
        return;
      }
      setBtnLoading(true);
      dispatch(createGoal(values)).then(() => {
        setBtnLoading(false);
        handleClose();
      });
    },
  });

  function handleSelectAllStaff(e) {
    if (e.target.checked) {
      setValues((pre) => {
        return {
          ...pre,
          staff: dashboardData?.staff?.map((item) => item._id),
        };
      });
      setAllStaff(true);
    } else {
      setValues((pre) => {
        return { ...pre, staff: [] };
      });
      setAllStaff(false);
    }
  }

  function handleSelectClients(e) {
    if (e.target.checked) {
      setValues((pre) => {
        return {
          ...pre,
          clientId: dashboardData?.clients?.map((item) => item._id),
        };
      });
      setAllClients(true);
    } else {
      setValues((pre) => {
        return { ...pre, clientId: [] };
      });
      setAllClients(false);
    }
  }

  function handleDeleteStep(stpIndex) {
    setValues((prevValues) => ({
      ...prevValues,
      steps: prevValues.steps.filter((_, index) => index !== stpIndex),
    }));
  }

  function handleSelectUpdateStep(stepIndex) {
    const targetValue = values.steps[stepIndex];
    setStepTitle(targetValue.step.title);
    setStepDescription(targetValue.step.description);
    setStepUpdate({ status: true, targetIndex: stepIndex });
  }

  function handleAddStep(e) {
    e.preventDefault();
    e.stopPropagation();

    // Check if we are updating an existing step
    if (isStepUpdate.status) {
      // Map through the steps and update the target step
      const updatedStep = values.steps.map((item, index) => {
        if (index === isStepUpdate.targetIndex) {
          // Update the step with new title and description
          return {
            ...item,
            step: {
              title: stepTitle,
              description: stepDescription,
            },
          };
        } else {
          return item;
        }
      });

      // Update the state with the updated steps
      setValues((prevValues) => ({
        ...prevValues,
        steps: updatedStep,
      }));
    } else {
      // Add a new step to the steps array
      setValues((prevValues) => ({
        ...prevValues,
        steps: [
          ...prevValues.steps,
          {
            step: { title: stepTitle, description: stepDescription },
            status: false,
          },
        ],
      }));
    }

    // Clear the input fields
    setStepTitle("");
    setStepDescription("");

    // Reset the step update state
    setStepUpdate({ status: false, targetIndex: 0 });
  }

  function handleStaff(e) {
    const staffId = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setValues((prevValues) => ({
        ...prevValues,
        staff: [...prevValues.staff, staffId],
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        staff: prevValues.staff.filter((id) => id !== staffId),
      }));
    }
  }

  function handleClients(e) {
    if (e.target.checked) {
      setValues((prevValues) => ({
        ...prevValues,
        clientId: [...prevValues.clientId, e.target.value],
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        clientId: prevValues.clientId.filter((id) => id !== e.target.value),
      }));
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      setLoading(true);
      if (dashboardData.isNotInvoked) {
        dispatch(getDashboardData()).finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, []);
  return (
    <Modal headerText="Create Goal" open={open} onclick={handleClose}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-full flex justify-start items-start flex-col ">
            {errors?.steps}
            <div className="w-full">
              <label
                htmlFor="Category"
                className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
              >
                Goal Type
              </label>
              <select
                className="w-full rounded-md  border outline-none py-2 px-2"
                name="goalType"
                id="programType"
                onBlur={handleBlur}
                value={values.goalType}
                onChange={(e) => {
                  setValues((pre) => {
                    return {
                      ...pre,
                      amount: 0,
                      steps: [],
                      [e.target.name]: e.target.value,
                    };
                  });
                }}
              >
                <option value="">Select Goal Type</option>
                <option className="text-black" value="Financial">
                  Financial
                </option>
                <option className="text-black" value="Nonfinancial">
                  Non Financial
                </option>
              </select>
            </div>
            {touched?.goalType && errors?.goalType && (
              <p className="text-red-600 text-[14px] mt-1 text-center">
                {errors?.goalType}
              </p>
            )}
          </div>
          <InputRequired
            name="name"
            label="Title"
            placeholder="Goal Title "
            type="text"
            labelStyle={labelClassess}
            value={values.name}
            touch={touched.name}
            error={errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {values?.goalType === "Financial" && (
            <InputRequired
              name="amount"
              label="Goal Amount"
              placeholder="Goal Amount"
              type="number"
              labelStyle={labelClassess}
              value={values.amount}
              touch={touched.amount}
              error={errors.amount}
              onChange={handleChange}
            />
          )}

          <div className="flex justify-start items-start flex-col">
            <div className="mt-3">
              <label
                htmlFor="duration"
                className={`mb-1 pl-[2px] capitalize ${labelClassess}`}
              >
                Duration
              </label>
            </div>
            <div className="flex justify-start items-center">
              <input
                name="duration"
                value={values.duration}
                onBlur={handleBlur}
                onChange={handleChange}
                className="border min-w-max w-[20%] border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block p-2"
                type="number"
                placeholder="Duration Value"
              />
              <select
                className="ml-3 border min-w-[120px] w-[20%] border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block p-2"
                name="durationType"
                value={values.durationType}
                onChange={handleChange}
              >
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
            </div>

            {touched?.duration && errors?.duration && (
              <p className="text-red-600 text-[14px] mt-1 text-center">
                {errors?.duration}
              </p>
            )}
          </div>

          <InputWrapper>
            <div className="w-full">
              <label
                htmlFor="description"
                className="block pl-[2px] font-medium text-[14px] capitalize"
              >
                Note
              </label>
              <ReactQuill
                theme="snow"
                id="description"
                value={note}
                className="w-full py-1 rounded-md"
                onChange={setNote}
                placeholder="Write description here ..."
              />
            </div>
          </InputWrapper>

          {values?.steps?.length ? (
            <div className="py-3">
              {values?.steps?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-center items-start gap-3"
                  >
                    <div
                      key={index}
                      className="flex w-full justify-start items-start gap-3 bg-slate-50 border rounded-md px-3 py-1 my-2"
                    >
                      <input
                        disabled
                        type="checkbox"
                        className="min-w-[15px] min-h-[15px] mt-1"
                        checked={item?.status}
                      />
                      <div>
                        <div className="mb-3">
                          <h3 className="border-b border-black satoshi-700">
                            {item?.step?.title}
                          </h3>
                        </div>
                        <div className="text-sm satoshi-500 description">
                          {parse(item?.step?.description)}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-start items-start flex-col pr-1 min-w-max">
                      <MdDeleteForever
                        onClick={() => handleDeleteStep(index)}
                        className="text-2xl mt-2 text-red-700 cursor-pointer hover:text-red-400"
                      />

                      <FaEdit
                        className="text-xl text-green-600 mt-3 cursor-pointer hover:text-green-400"
                        onClick={() => {
                          handleSelectUpdateStep(index);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {values?.goalType === "Nonfinancial" && (
            <form onSubmit={handleAddStep}>
              <InputWrapper>
                <InputRequired
                  name="stepTitle"
                  value={stepTitle}
                  label="Step Title"
                  placeholder="Enter step title"
                  onChange={(e) => {
                    setStepTitle(e.target.value);
                  }}
                  required={true}
                />
              </InputWrapper>
              <InputWrapper>
                <div className="w-full">
                  <label
                    htmlFor="step"
                    className="block pl-[2px] font-medium text-[14px] capitalize"
                  >
                    Step
                  </label>
                  <ReactQuill
                    theme="snow"
                    id="step"
                    value={stepDescription}
                    className="w-full py-1 rounded-md"
                    onChange={setStepDescription}
                    placeholder="Write step description here ..."
                  />
                </div>
              </InputWrapper>
              <div className="flex justify-center items-center my-3">
                <ButtonIcon
                  type="submit"
                  customClasses="!py-2 !px-3"
                  text="Add Step"
                  icon={<IoIosAdd />}
                />
              </div>
            </form>
          )}

          {dashboardData?.staff?.length ? (
            <>
              <div className="flex justify-between items-center">
                <div>
                  <p className="mb-3 satoshi-700 mt-8 text-[14px] md:text-[16px]">
                    Please select Staff
                  </p>
                </div>
                <div className="flex justify-start items-center pl-2 mb-1 min-w-[100px] border py-1 rounded-md bg-slate-50">
                  <input
                    type="checkbox"
                    value={allStaff}
                    onChange={handleSelectAllStaff}
                    checked={
                      dashboardData?.staff?.length === values?.staff.length
                    }
                    className="w-3 h-3 satoshi-500 cursor-pointer"
                  />
                  <label className="ml-2 text-[12px] satoshi-500 w-max cursor-pointer">
                    Select All
                  </label>
                </div>
              </div>
              <div className="w-full flex justify-start items-center">
                <div className="relative w-[60%] text-[--gray] bg-white border rounded-md mb-4">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button
                      type="submit"
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                      >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </button>
                  </span>
                  <input
                    type="search"
                    name="q"
                    className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
                    placeholder="Search Staff"
                    value={staffSearchQuery}
                    onChange={(e) => setStaffSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="max-h-[200px] flex justify-start w-full items-stretch flex-wrap gap-2">
                {dashboardData?.staff
                  ?.filter((staff) => {
                    const fullName = `${
                      staff?.firstName + " " + staff?.lastName
                    }`;

                    const searchQueryNormalized = staffSearchQuery
                      .toLowerCase()
                      .replace(/\s/g, "");
                    return fullName
                      .toLowerCase()
                      .replace(/\s/g, "")
                      .includes(searchQueryNormalized);
                  })
                  ?.map((staff, index) => (
                    <div
                      key={index}
                      className="flex justify-start items-center pl-2 mb-1 min-w-[200px] border py-2 rounded-md bg-slate-50"
                    >
                      <input
                        type="checkbox"
                        value={staff._id}
                        onChange={handleStaff}
                        checked={values.staff.includes(staff._id)}
                        className="w-4 h-4 satoshi-500 cursor-pointer"
                      />
                      <label className="ml-2 text-[13px] satoshi-500 w-max cursor-pointer">
                        {`${staff.firstName} ${staff.lastName}`}
                      </label>
                    </div>
                  ))}
              </div>
            </>
          ) : null}

          {dashboardData?.clients?.length ? (
            <>
              <div className="flex justify-between items-center">
                <div>
                  <p className="mb-3 satoshi-700 mt-8 text-[14px] md:text-[16px]">
                    Please select Clients
                  </p>
                </div>
                <div className="flex justify-start items-center pl-2 mb-1 min-w-[100px] border py-1 rounded-md bg-slate-50">
                  <input
                    type="checkbox"
                    value={allClients}
                    onChange={handleSelectClients}
                    checked={
                      dashboardData?.clients?.length === values?.clientId.length
                    }
                    className="w-3 h-3 satoshi-500 cursor-pointer"
                  />
                  <label className="ml-2 text-[12px] satoshi-500 w-max cursor-pointer">
                    Select All
                  </label>
                </div>
              </div>
              <div className="w-full flex justify-start items-center">
                <div className="relative w-[60%] text-[--gray] bg-white border rounded-md mb-4">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button
                      type="submit"
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                      >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </button>
                  </span>
                  <input
                    type="search"
                    name="q"
                    className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
                    placeholder="Search Client"
                    value={clientSearchQuery}
                    onChange={(e) => setClientSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="max-h-[200px] flex justify-start w-full items-stretch flex-wrap gap-2">
                {dashboardData?.clients
                  ?.filter((client) => {
                    const fullName = `${
                      client?.firstName + " " + client?.lastName
                    }`;

                    const searchQueryNormalized = clientSearchQuery
                      .toLowerCase()
                      .replace(/\s/g, "");
                    return fullName
                      .toLowerCase()
                      .replace(/\s/g, "")
                      .includes(searchQueryNormalized);
                  })
                  ?.map((client, index) => (
                    <div
                      key={index}
                      className="flex justify-start items-center pl-2 mb-1 min-w-[200px] border py-2 rounded-md bg-slate-50"
                    >
                      <input
                        type="checkbox"
                        value={client._id}
                        onChange={(e) => {
                          setClientFieldError("");
                          handleClients(e);
                        }}
                        checked={values.clientId.includes(client._id)}
                        className="w-4 h-4 satoshi-500 cursor-pointer"
                      />
                      <label className="ml-2 text-[13px] satoshi-500 w-max cursor-pointer">
                        {`${client.firstName} ${client.lastName}`}
                      </label>
                    </div>
                  ))}
              </div>
              <p className="text-red-600 text-[14px] mt-1">
                {clientFieldError}
              </p>
            </>
          ) : (
            <p className="text-center text-[16px] md:text-[18px] text-red-600">
              You don't have any Client to assign task
            </p>
          )}

          <div className="w-full flex justify-center items-center gap-5 mt-3">
            {btnLoading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  text="Cancel"
                  onClick={handleClose}
                  customClasses={"!bg-white !text-[--primary]"}
                />
                <ButtonSmall text="Create Goal" onClick={handleSubmit} />
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default CreateAssignGoal;
