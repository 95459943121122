import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import Loader from "../Loading/Loader";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch, useSelector } from "react-redux";
import {
  assignProgram,
  getDashboardData,
  getProgramClients,
} from "../../Redux/Actions";

const AssignProgramModal = ({ open, handleClose, data }) => {
  let Once = true;

  const [laoding, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard);
  const userData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const [allSelected, setAllSelected] = useState(false);
  const [clientError, setClientError] = useState("");
  const [values, setValues] = useState({
    programId: data.programId,
    coachId: userData?.id,
    clientId: [],
  });

  function handleClients(e) {
    const clientId = e.target.value;
    // Check if the checkbox is checked
    if (e.target.checked) {
      // Add staffId to the staff array if it's not already present
      setClientError("");
      if (!values.clientId.includes(clientId)) {
        setValues((prevValues) => ({
          ...prevValues,
          clientId: [...prevValues.clientId, clientId],
        }));
      }
    } else {
      // Remove staffId from the staff array
      setValues((prevValues) => ({
        ...prevValues,
        clientId: prevValues.clientId.filter((id) => id !== clientId),
      }));
    }
  }

  function handleAllCheck(e) {
    if (e.target.checked) {
      setValues((pre) => ({
        ...pre,
        clientId: dashboardData.clients.map((client) => client._id),
      }));

      setAllSelected(true);
    } else {
      setValues((pre) => ({
        ...pre,
        clientId: [],
      }));
      setAllSelected(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!values.clientId.length) {
      setClientError("Please select at least one client");
    } else {
      setBtnLoading(true);
      dispatch(assignProgram(values)).then(() => {
        setBtnLoading(false);
        handleClose();
      });
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (dashboardData.isNotInvoked) {
        setLoading(true);
        dispatch(getDashboardData()).then(() => {
          getProgramClients(data.programId)
            .then((res) => {
              if (res.data.length) {
                setValues((pre) => ({
                  ...pre,
                  clientId: res.data,
                }));
              }
              setLoading(false);
            })
            .catch((err) => {
              console.error(err);
            });
        });
      } else {
        setLoading(true);
        getProgramClients(data.programId)
          .then((res) => {
            if (res.data.length) {
              setValues((pre) => ({
                ...pre,
                clientId: res.data,
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  return (
    <Modal open={open} onclick={handleClose} headerText="Assign Course">
      {laoding ? (
        <Loader />
      ) : (
        <>
          {dashboardData.clients.length ? (
            <>
              <p className="mb-3 satoshi-700 mt-8">
                Please select Clients you want to assign the Program
              </p>
              <div>
                <div className="flex justify-start items-center pl-2 mb-1 min-w-[200px] border py-2 rounded-md bg-slate-50 ">
                  <input
                    type="checkbox"
                    value={"All"}
                    checked={allSelected}
                    onChange={handleAllCheck}
                    className={`w-4 h-4 satoshi-500 cursor-pointer`}
                  />
                  <label
                    className={`ml-2 text-[13px] satoshi-500 w-max cursor-pointer`}
                  >
                    All Clients
                  </label>
                </div>
              </div>
              <div className="flex justify-start w-full items-stretch flex-wrap gap-4 mt-3">
                {dashboardData.clients.map((client, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-start items-center pl-2 mb-1 min-w-[200px] border py-2 rounded-md bg-slate-50 "
                    >
                      <input
                        type="checkbox"
                        value={client._id}
                        onChange={handleClients}
                        checked={values.clientId.includes(client._id)}
                        className={`w-4 h-4 satoshi-500 cursor-pointer`}
                      />
                      <label
                        className={`ml-2 text-[13px] satoshi-500 w-max cursor-pointer`}
                      >
                        {client.firstName + " " + client.lastName}
                      </label>
                    </div>
                  );
                })}
              </div>
              {clientError ? (
                <p className="text-red-600 text-[14px] mt-1 text-center">
                  {clientError}
                </p>
              ) : null}
            </>
          ) : (
            <p>You don't have any client yet</p>
          )}
          <div className="flex justify-center items-center gap-4 mt-6 w-full">
            {btnLoading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  text="Cancel"
                  onClick={handleClose}
                  customClasses="!bg-white !text-black"
                />
                {dashboardData.clients.length ? (
                  <ButtonSmall text="Assign" onClick={handleSubmit} />
                ) : null}
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default AssignProgramModal;
