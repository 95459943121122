import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ClientRow from "../../components/Table/ClientRow";
import { getDashboardData } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import { useDispatch, useSelector } from "react-redux";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import AddClientModal from "../../components/Modal/AddClientModal";
import DeleteUser from "../../components/Modal/DeleteUser";
import useHasPermission from "../../Utils/permissionutil";
import TextModal from "../../components/Modal/TextModal";

const Clients = () => {
  let Once = true;
  const hasPermissionToAdd = useHasPermission("Add Clients");
  const hasPermissionToDelete = useHasPermission("Account /manage and delete");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dashboardData = useSelector((state) => state.dashboard);
  const [filteredClients, setFilteredClients] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const clientsPerPage = 5;
  const [addClient, setAddClient] = useState(false);
  const [deleteUser, setDeleteUser] = useState({
    userId: null,
    status: false,
  });
  const [textModal, setTextModal] = useState(false);

  function handleModal() {
    if (!addClient) {
      if (hasPermissionToAdd) {
        setAddClient(true);
      } else {
        setTextModal(true);
      }
    } else {
      setAddClient(false);
    }
  }

  const handleSearchInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = dashboardData.clients.filter(
      (client) =>
        client.firstName.toLowerCase().includes(query) ||
        client.lastName.toLowerCase().includes(query) ||
        client.email.toLowerCase().includes(query)
    );
    setFilteredClients(filtered);
    setPageNumber(1); // Reset page number when search query changes
  };

  const indexOfLastClient = pageNumber * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;

  const totalPages = Math.ceil(filteredClients.length / clientsPerPage);

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePrevPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  function handleDeleteModal(userId = null, status = false) {
    if (status === false) {
      setDeleteUser({
        status: false,
        userId: userId,
        role: "client",
      });
    } else {
      if (hasPermissionToDelete) {
        setDeleteUser({
          status: true,
          userId: userId,
          role: "client",
        });
      } else {
        setTextModal();
      }
    }
  }

  useEffect(() => {
    setFilteredClients(dashboardData.clients);
  }, [dashboardData.clients]);

  useEffect(() => {
    if (Once) {
      Once = false;
      if (dashboardData.isNotInvoked) {
        setLoading(true);
        dispatch(getDashboardData()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      {textModal && (
        <TextModal
          open={textModal}
          handleClose={() => {
            setTextModal(false);
          }}
          isPermission={true}
        />
      )}
      {deleteUser.status && (
        <DeleteUser
          open={deleteUser.status}
          data={deleteUser}
          handleModal={handleDeleteModal}
        />
      )}
      {addClient ? (
        <AddClientModal handleOpen={handleModal} open={addClient} />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderData text="Clients">
            <ButtonIcon
              onclick={handleModal}
              icon={<IoMdAdd />}
              text="Client"
            />
          </HeaderData>
          <div className="relative w-[45%] mt-4 md:w-[40%] lg:w-[35%] text-[--gray] bg-white border rounded-full">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <button
                type="submit"
                className="p-1 focus:outline-none focus:shadow-outline"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </span>
            <input
              type="search"
              name="q"
              className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit rounded-md pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
          {filteredClients?.length ? (
            <>
              <div className="dashboardTableWrapper">
                <table className="bg-white w-full min-w-[750px] border-collapse !overflow-x-auto mt-3">
                  <thead>
                    <tr>
                      <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm">
                        Name
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Email
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        No. of programs
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Joining Date
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Package
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredClients
                      .slice(indexOfFirstClient, indexOfLastClient)
                      .map((ele, index) => {
                        return (
                          <ClientRow
                            key={index}
                            data={ele}
                            onDeleteClient={() => {
                              handleDeleteModal(ele?._id, true);
                            }}
                          />
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-center mt-1">
                <div>
                  <h1 className="font-semibold text-sm">
                    Total Pages : {totalPages}
                  </h1>
                </div>
                <div>
                  {pageNumber === 1 ? null : (
                    <ButtonSmall text={"Previous"} onClick={handlePrevPage} />
                  )}
                  {pageNumber === totalPages ? null : (
                    <ButtonSmall
                      text={"Next"}
                      onClick={handleNextPage}
                      customClasses={"ml-2"}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <p className="mt-5">No client found. Add clients please</p>
          )}
        </>
      )}
    </>
  );
};

export default Clients;
