import React, { useState } from "react";
import Modal from "./Modal";
import { IoDocumentTextOutline } from "react-icons/io5";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { addHandout, uploadPhoto } from "../../Redux/Actions";

const initialState = {
  file: "",
  type: "handout",
};

const HandoutModal = ({ open, handleOpen }) => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");

  const { handleSubmit } = useFormik({
    initialValues: initialState,
    onSubmit: (values, action) => {
      if (!file) {
        setError("Please provide a file");
      } else {
        setBtnLoading(true);
        uploadPhoto({ photo: file })
          .then((res) => {
            values.file = res.data.photos[0];
            dispatch(addHandout(values)).then(() => {
              setBtnLoading(false);
              action.resetForm();
              setFile("");
              handleOpen();
            });
          })
          .catch((err) => {
            setBtnLoading(false);
            console.log("errr ----->", err);
          });
      }
    },
  });

  return (
    <Modal open={open} onclick={handleOpen} headerText="Add Handout">
      <div className="w-full flex justify-center items-center flex-col">
        <p className="text-red-600 py-2">{error}</p>
        <label
          htmlFor="file"
          className="w-full flex justify-center items-center cursor-pointer"
        >
          <IoDocumentTextOutline className="text-[60px] text-blue-500 mb-4" />
        </label>
        <input
          type="file"
          name="file"
          id="file"
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
          hidden
          className="w-full h-full"
        />
        <p className="satoshi-500 text-[16px]">
          {file ? file.name : "Upload File"}
        </p>
        <div className="flex justify-center items-center gap-4 mt-6 w-full">
          {btnLoading ? (
            <ButtonLoading />
          ) : (
            <>
              <ButtonSmall text="Add" onClick={handleSubmit} />
              <ButtonSmall
                text="Cancel"
                onClick={handleOpen}
                customClasses="!bg-white !text-black"
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default HandoutModal;
