import React from "react";
import { HiOutlineUsers } from "react-icons/hi";
import wave from "../../Assets/wave.png";
import SelectInput from "../Input/SelectInput";
import { Link } from "react-router-dom";

const FluidCard = ({ withDropdown, withGraph, text, value, url }) => {
  return (
    <Link to={url} className="p-3 bg-white rounded-lg flex-grow basis-44">
      <div className="flex w-full justify-between items-center">
        <div className="w-[45px] h-[45px] bg-[--gray-light] flex justify-center items-center rounded-full text-xl">
          <HiOutlineUsers />
        </div>
        {withDropdown ? <SelectInput /> : ""}
      </div>
      <div className="mt-4 mb-1">
        <p className="text-[18px] text-[--gray]">{text}</p>
      </div>
      <div className="mt-1 mb-1">
        <h1 className="text-[27px] satoshi-900 font-[900]">{value}</h1>
      </div>
      {withGraph ? (
        <div className="w-full h-14 mt-3">
          <img src={wave} className="w-full h-full" alt="expense" />
        </div>
      ) : (
        ""
      )}
    </Link>
  );
};

export default FluidCard;
