const initialState = {
  welcomeText:"",
  isInvoked:false
};

const reportFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
      case "WELCOME_TEXT":
        return {
         ...state,
         welcomeText:payload || "",
          isInvoked:true
        };  
    default:
      return state;
  }
};

export default reportFormReducer;
