import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
// import RowWrapper from "../../components/Wrapper/RowWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import moment from "moment";
// import AssignProgramModal from "../../components/Modal/AssignProgramModal";
// import ButtonSmall from "../../components/Buttons/ButtonSmall";
// import DeleteProgramModal from "../../components/Modal/DeleteProgramModal";
import MediaDetailCard from "../../components/Card/MediaDetailCard";

const MediaBlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const blogData = useSelector((state) => state.media.blogs);
  // const [assignProgram, setAssignProgram] = useState({
  //   modal: false,
  //   programId: null,
  // });

  // const [deleteProgram, setDeleteProgram] = useState({
  //   modal: false,
  //   programId: null,
  // });

  // function closeAssignModal() {
  //   setAssignProgram({
  //     modal: false,
  //     programId: null,
  //   });
  // }

  // function closeDeleteModal() {
  //   setDeleteProgram({
  //     modal: false,
  //     programId: null,
  //   });
  // }

  useEffect(() => {
    if (!blogData.length) {
      navigate("/media");
    } else {
      setLoading(true);
      const targetProgram = blogData.find((item) => item._id === id);
      setSelectedBlog(targetProgram);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);

  return (
    <>
      <HeaderData text="Blog Detail" />
      {loading ? (
        <Loader />
      ) : selectedBlog ? (
        <MediaDetailCard
          image={selectedBlog?.photo}
          title={selectedBlog?.title}
          description={selectedBlog?.description}
          creationDate={selectedBlog?.createdAt}
        />
      ) : (
        <p>The media you are seraching for is not found</p>
      )}

      {/* {deleteProgram.modal ? (
        <DeleteProgramModal
          open={selectedBlog.modal}
          handleClose={closeDeleteModal}
          data={deleteProgram.programId}
        />
      ) : null} */}
    </>
  );
};

export default MediaBlogDetail;
