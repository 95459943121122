import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
import Avatar from "../../components/Avatar/Avatar";
import StepsModal from "../../components/Modal/StepsModal";
import { useDispatch, useSelector } from "react-redux";
import { getClientsGoals } from "../../Redux/Actions";
import { isEmpty } from "lodash";
import moment from "moment";
import AssignGoal from "../../components/Modal/AssignGoal";
import { Link } from "react-router-dom";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import CreateAssignGoal from "../../components/Modal/CreateAssignGoal";
import UpdateAssignGoal from "../../components/Modal/UpdateAssignGoal";
import DeleteGoal from "../../components/Modal/DeleteGoal";
import { MdDeleteForever } from "react-icons/md";
import useHasPermission from "../../Utils/permissionutil";
import UnavailableService from "../../components/Message/UnavailableService";
import TextModal from "../../components/Modal/TextModal";
import ReopenGoalModal from "../../components/Modal/ReopenGoalModal";


let Once = true;

const UserGoals = () => {
  const hasPermission = useHasPermission("Goals");
  const hasPermissionToCreate = useHasPermission("Create new Goals");
  const hasPermissionToManage = useHasPermission("Manage Goals");
  const [loading, setLoading] = useState(false);

  const goalData = useSelector((state) => state.goals.data);
  const [stepModal, setStepModal] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState(true);

  const [modalData, setModalData] = useState({
    goalId: "",
    title: "",
    steps: [],
    note: "",
    staff: [],
    goalType: "",
  });
  const [createGoalModal, setCreateGoalModal] = useState(false);
  const [updateCoachGoal, setUpdateCoachGoal] = useState({
    modal: false,
    data: null,
  });
  const [goalDelete, setGoalDelete] = useState({
    status: false,
    id: null,
  });
  const [textModal, setTextModal] = useState(false);
  const [reopenGoal, setReopenModal] = useState({
    status: false,
    data: null,
  });

  const dispatch = useDispatch();

  function handleModal(data, modalName = "steps") {
    setModalData({
      goalType: data?.goalType,
      goalId: data._id,
      title: data?.name,
      steps: data?.steps,
      note: data?.note || "",
      staff: data?.staff || [],
    });
    if (modalName === "staff") {
      setStaffModal(true);
    } else {
      setStepModal(true);
    }
  }

  function handleCloseModal() {
    setModalData({
      goalId: "",
      title: "",
      steps: [],
      note: "",
      staff: [],
    });
    setStaffModal(false);
    setStepModal(false);
  }

  function handleCreateGoalModal() {
    if (!createGoalModal) {
      if (hasPermissionToCreate) {
        setCreateGoalModal(true);
      } else {
        setTextModal(true);
      }
    } else {
      setCreateGoalModal(false);
    }
  }

  function handleUpdateCoachModal(goalData = null, status = false) {
    if (status === false) {
      setUpdateCoachGoal({
        modal: false,
        data: null,
      });
    } else {
      if (hasPermissionToManage) {
        setUpdateCoachGoal({
          modal: true,
          data: goalData,
        });
      } else {
        setTextModal(true);
      }
    }
  }

  function handleDeleteGoal(status = false, goalId = null) {
    if (hasPermissionToManage) {
      setGoalDelete({
        status: status,
        id: goalId,
      });
    } else {
      setTextModal(true);
    }
  }

  function handleReopenGoal(goalData) {
    setReopenModal({ status: !reopenGoal.status, data: goalData });
  }

  useEffect(() => {
      if (hasPermission) {
        // if (isEmpty(goalData)) {
          setLoading(true);
          dispatch(getClientsGoals()).then(() => {
            setLoading(false);
          });
        // }
      } else {
        setIsServiceAvailable(false);
      }
  }, []);

  return (
    <>
      {reopenGoal.status && (
        <ReopenGoalModal
          open={reopenGoal.status}
          handleClose={() => {
            handleReopenGoal(null);
          }}
          data={reopenGoal.data}
        />
      )}
      {textModal && (
        <TextModal
          isPermission={true}
          open={textModal}
          handleClose={() => {
            setTextModal(false);
          }}
        />
      )}
      <HeaderData text="Customer Goals">
        <ButtonIcon
          onclick={handleCreateGoalModal}
          icon={<IoMdAdd />}
          text="Create Goal"
        />
      </HeaderData>
      {loading ? (
        <Loader />
      ) : isServiceAvailable ? (
        <>
          {goalData?.length ? (
            <div className="dashboardTableWrapper">
              <table className="bg-white w-full min-w-[1100px] border-collapse !overflow-x-auto mt-3">
                <thead>
                  <tr className="border-b border-black">
                    <th className="text-left px-2 pl-4 py-4 text-[--gray] text-sm ">
                      Name
                    </th>
                    <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm ">
                      Title
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm ">
                      Type
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm ">
                      Completed
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm ">
                      Duration
                    </th>
                    <th className="text-right px-2 py-4 text-[--gray] text-sm  w-[200px]"></th>
                  </tr>
                </thead>
                <tbody>
                  {goalData
                    ?.sort(
                      (a, b) => parseInt(b.createdAt) - parseInt(a.createdAt)
                    )
                    .map((goal, index) => {
                      return (
                        <tr key={index} className="border-b">
                          <td className="flex justify-start items-center py-2 pl-4 min-w-max">
                            <Link
                              className="flex justify-start items-center"
                              to={`/clients-detail/${goal.clientId._id}`}
                            >
                              <Avatar imageRef={goal?.clientId?.photo} />
                              <div className=" ml-2 flex justify-center items-start flex-col">
                                <h5 className="text-[14px]">
                                  {goal?.clientId?.firstName +
                                    " " +
                                    goal?.clientId?.lastName}
                                </h5>
                              </div>
                            </Link>
                          </td>
                          <td
                            onClick={(e) =>
                              e.target.classList.toggle("!max-w-max")
                            }
                            className="text-[14px] text-left px-2 whitespace-nowrap text-ellipsis max-w-[100px]"
                          >
                            {goal?.name}
                          </td>
                          <td
                            onClick={(e) =>
                              e.target.classList.toggle("!max-w-max")
                            }
                            className="text-[14px] text-left px-2 whitespace-nowrap text-ellipsis max-w-[100px]"
                          >
                            {goal?.goalType === "Financial"
                              ? `Financial ($ ${goal?.amount})`
                              : "Non Financial"}
                          </td>
                          <td
                            onClick={(e) =>
                              e.target.classList.toggle("!max-w-max")
                            }
                            className="text-[14px] px-2 text-center whitespace-nowrap text-ellipsis max-w-[100px] text-lime-600"
                          >
                            {goal?.completed ? (
                              goal.goalType === "Financial" ? (
                                <span className="text-sm satoshi-500 bg-green-50 text-green-600 px-2 py-1">
                                  Completed
                                </span>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleReopenGoal(goal);
                                  }}
                                  className="text-sm satoshi-500 rounded-md bg-red-800 text-white px-2 py-1 cursor-pointer"
                                >
                                  Re-Open
                                </button>
                              )
                            ) : (
                              <span className="text-sm satoshi-500 bg-red-50 text-red-500 px-2 py-1">
                                Incomplete
                              </span>
                            )}
                          </td>
                          <td
                            onClick={(e) =>
                              e.target.classList.toggle("!max-w-max")
                            }
                            className="text-[14px] text-left px-2 whitespace-nowrap text-ellipsis max-w-[150px]"
                          >
                            {moment(parseInt(goal?.createdAt)).format(
                              "DD MMM, YYYY"
                            ) +
                              " - " +
                              moment(parseInt(goal?.endDate)).format(
                                "DD MMM, YYYY"
                              )}
                          </td>
                          <td className="text-center px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] pl-2">
                            {goal?.createdBy === "Coach" ||
                              goal?.createdBy === "Staff" ? (
                              <div className="flex justify-center items-stretch gap-3">
                                <button
                                  onClick={() => {
                                    handleUpdateCoachModal(goal, true);
                                  }}
                                  className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500"
                                >
                                  Update Goal
                                </button>

                                <div
                                  onClick={() => {
                                    handleDeleteGoal(true, goal._id);
                                  }}
                                  className="cursor-pointer border border-red-700 bg-red-700 text-white rounded-md px-2 py-1 transition-all ease-in-out duration-500 hover:text-red-700 hover:bg-white"
                                >
                                  <MdDeleteForever className="text-base md:text-lg lg:text-xl xl:text-2xl" />
                                </div>
                              </div>
                            ) : !goal?.createdByCoach && goal?.staff?.length ? (
                              <div className="flex justify-center items-stretch gap-1">
                                {goal.goalType === "Nonfinancial" && (
                                  <button
                                    onClick={() => {
                                      handleModal(goal);
                                    }}
                                    className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500 min-w-max"
                                  >
                                    Update Steps
                                  </button>
                                )}

                                <button
                                  onClick={() => {
                                    handleModal(goal, "staff");
                                  }}
                                  className="ml-3 bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500 min-w-max"
                                >
                                  Update Staff
                                </button>
                              </div>
                            ) : !goal?.createdByCoach && goal?.steps?.length ? (
                              <div className="flex justify-center items-stretch gap-1">
                                {goal.goalType === "Nonfinancial" && (
                                  <button
                                    onClick={() => {
                                      handleModal(goal);
                                    }}
                                    className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500 min-w-max"
                                  >
                                    Update Steps
                                  </button>
                                )}

                                <button
                                  onClick={() => {
                                    handleModal(goal, "staff");
                                  }}
                                  className="ml-3 bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500 min-w-max"
                                >
                                  Update Staff
                                </button>
                              </div>
                            ) : (
                              <>
                                {goal.goalType === "Nonfinancial" && (
                                  <button
                                    onClick={() => {
                                      handleModal(goal);
                                    }}
                                    className="bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500"
                                  >
                                    Add Steps
                                  </button>
                                )}

                                <button
                                  onClick={() => {
                                    handleModal(goal, "staff");
                                  }}
                                  className="ml-3 bg-[--primary] rounded-md text-white py-1 px-2 sm:text-[12px] md:text-sm satoshi-500"
                                >
                                  Assign Staff
                                </button>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <p>Your customer has not added any goal yet</p>
          )}
        </>
      ) : (
        <UnavailableService />
      )}

      {goalDelete.status && (
        <DeleteGoal
          open={goalDelete.status}
          data={goalDelete}
          handleModal={() => {
            handleDeleteGoal();
          }}
        />
      )}

      {stepModal ? (
        <StepsModal
          open={stepModal}
          handleClose={handleCloseModal}
          data={modalData}
        />
      ) : null}

      {staffModal ? (
        <AssignGoal
          open={staffModal}
          handleClose={handleCloseModal}
          data={modalData}
        />
      ) : null}
      {createGoalModal ? (
        <CreateAssignGoal
          open={createGoalModal}
          handleClose={handleCreateGoalModal}
        />
      ) : null}

      {updateCoachGoal.modal && (
        <UpdateAssignGoal
          open={updateCoachGoal.modal}
          data={updateCoachGoal.data}
          handleClose={handleUpdateCoachModal}
        />
      )}
    </>
  );
};

export default UserGoals;
