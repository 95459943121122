import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import MediaCard from "../../components/Card/MediaCard";
import MediaCardHeader from "../../components/Card/MediaCardHeader";
import MediaContainer from "../../components/Wrapper/MediaContainer";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import { isEmpty } from "lodash";
import { getMediaData } from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loading/Loader";
import parse from "html-react-parser";
import DeleteMediaModal from "../../components/Modal/DeleteMediaModal";
import AddMediaForm from "../../components/Form/AddMediaForm";
import EditMedia from "../../components/Form/EditMedia";
import UnavailableService from "../../components/Message/UnavailableService";
import { Link } from "react-router-dom";
import useHasPermission from "../../Utils/permissionutil";
let Once = true;

const Media = () => {
  const hasPermission = useHasPermission("Media");

  const [mediaForm, setMediaForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isServiceAvailable, setServiceAvailable] = useState(
    useSelector((state) => state.services.mediaService)
  );
  const videosData = useSelector((state) => state.media.videos);
  const blogsData = useSelector((state) => state.media.blogs);
  const [mediaType, setMediaType] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editMediaData, setEditMediaData] = useState(null);
  const [isDeletedModal, setIsDeletedModal] = useState(false);
  const [deletedMedia, setDeletedMedia] = useState({
    mediaId: "",
    mediaType: "",
  });

  function toggleMediaForm() {
    setMediaForm(!mediaForm);
  }

  function onEditMedia(data, type) {
    setEditMediaData(data);
    setMediaType(type);
    setIsEdit(true);
  }

  function onCloseEdit() {
    setEditMediaData(null);
    setMediaType(null);
    setIsEdit(false);
  }

  function onCloseDeleteModal() {
    setDeletedMedia({
      mediaId: "",
      mediaType: "",
    });
    setIsDeletedModal(false);
  }

  useEffect(() => {
    if (Once) {
      if (hasPermission) {
        Once = false;
        if (isEmpty(videosData) && isEmpty(blogsData)) {
          setLoading(true);
          dispatch(getMediaData())
            .then((res) => {
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      } else {
        setServiceAvailable(false);
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : isServiceAvailable ? (
        <>
          <HeaderData text="Media Library">
            {!mediaForm ? (
              <ButtonIcon
                icon={<IoMdAdd />}
                onclick={toggleMediaForm}
                text="Add Media"
              />
            ) : null}
          </HeaderData>
          {isDeletedModal ? (
            <DeleteMediaModal
              open={isDeletedModal}
              handleClose={onCloseDeleteModal}
              data={deletedMedia}
            />
          ) : null}

          {mediaForm ? (
            <AddMediaForm handleClose={toggleMediaForm} />
          ) : isEdit ? (
            <EditMedia
              handleClose={onCloseEdit}
              data={editMediaData}
              mediaType={mediaType}
            />
          ) : videosData?.length || blogsData?.length ? (
            <>
              {videosData.length ? (
                <MediaContainer text="Videos Content">
                  <CardWrapper extraClasses="!justify-start !items-stretch !gap-4 !py-1 !pb-8">
                    {videosData.map((media, index) => {
                      return (
                        <MediaCard key={index} customClasses="!w-[320px]">
                          <MediaCardHeader
                            thumbnailUrl={
                              media?.thumbnail &&
                              process.env.REACT_APP_IMAGE_URL + media.thumbnail
                            }
                            videoUrl={
                              process.env.REACT_APP_IMAGE_URL + media.video
                            }
                            text={media.title}
                            data={media}
                            onEdit={() => {
                              onEditMedia(media, "video");
                            }}
                            onDelete={() => {
                              setDeletedMedia({
                                mediaId: media._id,
                                mediaType: "video",
                              });
                              setIsDeletedModal(true);
                            }}
                          />
                          <div className="px-2 text-[13px] max-h-[40px] text-ellipsis overflow-y-hidden">
                            <div className="w-full satoshi-500 description  overflow-hidden">
                              {parse(parse(media.description))}
                            </div>
                          </div>
                          <div className="w-full text-right pb-2 pr-4 pt-3">
                            <Link
                              to={`/media-video-detail/${media._id}`}
                              className="text-blue-600 text-[13px] border px-6 py-1 hover:text-white hover:bg-blue-700 border-blue-600 satoshi-500"
                            >
                              View Detail
                            </Link>
                          </div>
                        </MediaCard>
                      );
                    })}
                  </CardWrapper>
                </MediaContainer>
              ) : null}

              {blogsData.length ? (
                <MediaContainer text="Blogs">
                  <CardWrapper extraClasses="!justify-start !items-stretch !gap-4 !py-1 !pb-8">
                    {blogsData.map((blog, index) => {
                      return (
                        <MediaCard key={index} customClasses="!w-[320px]">
                          <MediaCardHeader
                            image={process.env.REACT_APP_IMAGE_URL + blog.photo}
                            text={blog.title}
                            onEdit={() => {
                              onEditMedia(blog, "blog");
                            }}
                            onDelete={() => {
                              setDeletedMedia({
                                mediaId: blog._id,
                                mediaType: "blog",
                              });
                              setIsDeletedModal(true);
                            }}
                          />
                          <div className="px-2 text-[13px] max-h-[40px] overflow-hidden">
                            <div className="w-full satoshi-500 description text-ellipsis overflow-hidden">
                              {parse(parse(blog.description))}
                            </div>
                          </div>
                          <div className="w-full text-right pb-2 pr-4 pt-3">
                            <Link
                              to={`/media-blog-detail/${blog._id}`}
                              className="text-blue-600 text-[13px] border px-6 py-1 hover:text-white hover:bg-blue-700 border-blue-600 satoshi-500"
                            >
                              View Detail
                            </Link>
                          </div>
                        </MediaCard>
                      );
                    })}
                  </CardWrapper>
                </MediaContainer>
              ) : null}

              {/* <MediaContainer text="Plans">
                <CardWrapper extraClasses="!py-2">
                  {dummyData.map((ele, index) => {
                    return (
                      <PlanCard
                        key={index}
                        headerText={ele.name}
                        properties={ele.properties}
                      />
                    );
                  })}
                </CardWrapper>
              </MediaContainer> */}
            </>
          ) : (
            <p>No media Found</p>
          )}
        </>
      ) : (
        <UnavailableService />
      )}
    </>
  );
};

export default Media;
