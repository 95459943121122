import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ReportRow from "../../components/Table/ReportRow";
import { useDispatch, useSelector } from "react-redux";
import { getReportsData } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import ReportForm from "../../components/Form/ReportForm";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import UnavailableService from "../../components/Message/UnavailableService";
import useHasPermission from "../../Utils/permissionutil";

const Reports = () => {
  let Once = true;
  const hasPermission = useHasPermission("Reports");
  const [reportForm, setReportForm] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { reportsData, isInvoked } = useSelector((state) => state.reports);
  const [serviceAvailable, setServiceAvailable] = useState(true);

  function closePDFForm() {
    setReportForm(false);
  }
  useEffect(() => {
    if (Once) {
      Once = false;
      if (hasPermission) {
        if (!isInvoked) {
          setLoading(true);
          dispatch(getReportsData())
            .then((res) => {
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      } else {
        setServiceAvailable(false);
      }
    }
  }, []);
  return reportForm ? (
    <ReportForm onClose={closePDFForm} />
  ) : (
    <>
      <HeaderData text="Reports">
        {serviceAvailable ? (
          <ButtonIcon
            icon={<IoMdAdd />}
            onclick={() => {
              setReportForm(true);
            }}
            text="Add PDF Text"
          />
        ) : null}
      </HeaderData>
      {loading ? (
        <Loader />
      ) : serviceAvailable ? (
        <>
          {reportsData?.length ? (
            <div className="w-full mt-3 bg-white rounded-lg dashboardTableWrapper">
              <table className="w-full min-w-[650px] border-collapse !overflow-x-auto">
                <thead className="border-b text-sm">
                  <th style={thStyle} className="pl-2 w-[35%] lg:w-[30%]">
                    Reports
                  </th>
                  <th style={thStyle} className="w-[15%] lg:w-[20%]">
                    Owner
                  </th>
                  <th style={thStyle} className="w-[15%]">
                    Coach
                  </th>
                  <th style={thStyle} className="w-[30%]">
                    Last Modified
                  </th>
                </thead>
                <tbody>
                  <ReportRow />
                  <ReportRow />
                  <ReportRow />
                  <ReportRow />
                </tbody>
              </table>
            </div>
          ) : (
            <p className="mt-2">No reports found. Please add reports</p>
          )}
        </>
      ) : (
        <UnavailableService />
      )}
    </>
  );
};

export default Reports;

const thStyle = {
  textAlign: "start",
  paddingTop: "24px",
  paddingBottom: "10px",
  color: "var(--gray)",
};
