import React from "react";
import pdf from "../../Assets/pdf.png";
import excel from "../../Assets/excel.png";
import ppt from "../../Assets/ppt.png";

const HandoutCard = ({ file }) => {
  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  let type = getFileExtension(file);
  return (
    <div className=" bg-white rounded-lg w-[270px]">
      <div className="w-full h-[180px] bg-[--gray-light] flex justify-center items-center">
        {type === "ppt" || type === "pptx" ? (
          <img src={ppt} className="w-[40%] h-[60%]" alt="Handout File" />
        ) : type === "pdf" ? (
          <img src={pdf} className="w-[40%] h-[60%]" alt="Handout File" />
        ) : (
          <img src={excel} className="w-[40%] h-[60%]" alt="File type" />
        )}
      </div>
      <div className="px-3 py-2">
        <h5 className="satoshi-700">Dummy Text for now</h5>
      </div>
    </div>
  );
};

export default HandoutCard;
